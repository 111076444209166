import { Avatar, Link, Stack, Typography } from "@mui/material";

import { DataStore } from "@aws-amplify/datastore";
import PersonIcon from '@mui/icons-material/Person';
import React from "react";
import { Customers, ReservationsCustomers } from "../../models";

interface Props {
    reservationsID: any;
}

export default function BookingCustomerCard(props: Props) {
    
    const [reservationsCustomers, setreservationsCustomers] = React.useState<Customers>()

    
    React.useEffect(
        () => {
        async function FetchCustomers() {
            const customers = (await DataStore.query(ReservationsCustomers)).filter(c => c.reservations.id === props.reservationsID).map(c => c.customers);
            setreservationsCustomers(customers[0])
        }
        FetchCustomers()
        },
        []
      )

      // console.log(reservationsCustomers,'reservationsCustomers2')
     
      if(!reservationsCustomers) return <>Sorry! unable to get this information.</>

      return (
        <Stack direction="row" sx={{alignItems: 'center'}} spacing={2}>
            <Avatar
                sx={{
                    bgcolor: "#f2f2f2",
                    p: 1,
                }}
            >
                <PersonIcon sx={{color: 'appBar'}}/>
            </Avatar>
            <Stack>
                <Stack direction='row' spacing={1}>
                    <Typography>
                        {/* @ts-ignore */}
                        {reservationsCustomers?.firstName + reservationsCustomers?.lastName} ,
                    </Typography>

                    <Typography sx={{
                        bgcolor: 'skyblue',
                        borderRadius: 1,
                        letterSpacing: 1,
                        px: 1,
                        fontSize: 14,
                        fontWeight: 'bold'
                    }}>
                        {reservationsCustomers?.Passport}
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Link component="a" sx={{fontSize: 12, color: '#0080FF'}}
                          href={`mailto:${reservationsCustomers?.Email}`}>{reservationsCustomers.Email}</Link>
                    <Link component="a" sx={{fontSize: 12, color: '#0174DF', textDecoration: 'none'}}
                          href={`tel:${reservationsCustomers?.Phone}`}> , {reservationsCustomers?.Phone}</Link>
                </Stack>
            </Stack>
        </Stack>
        
    )
}