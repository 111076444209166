import {Autocomplete, Button, Chip, FormControlLabel, Paper, Stack, Switch, TextField} from '@mui/material';
import * as React from 'react';
import dayjs, {Dayjs} from "dayjs"
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {mailRegex, passportRegex, phoneRegex} from '../../constant';
// import { countries } from '../../countries'
import useToggle from '../ReadOnlyHook';

import {DataStore} from '@aws-amplify/datastore';
import {CountryType, Customers, GenderType} from '../../models';


interface Props {
    selectedRow: any
    rows: any
    cleanSelected: () => void
    onClose: () => void
}

export default function CreateCustomer(props: Props) {
    const [custID, setCustID] = React.useState<string>("")
    const [firstName, setFirstName] = React.useState<string>('')
    const [lastName, setLastName] = React.useState<string>('')
    const [email, setEmail] = React.useState<string>('')
    const [passport, setPassport] = React.useState<string>("")
    const [phone, setPhone] = React.useState<string>("")
    const [isNeedEmail, setIsNeedEmail] = React.useState<boolean>(false)
    const [isMember, setIsMember] = React.useState(false)
    const [birthday, setBirthday] = React.useState<Dayjs>(dayjs(new Date()))
    const [gender, setGender] = React.useState<GenderType>(GenderType.MALE)
    // const [country, setCountry] = React.useState<{ name: string, code: string, flag: string }>(countries[0])
    const [country, setCountry] = React.useState<CountryType>(CountryType.KOREAN)
    const [disable, setDisable] = React.useState<boolean>(false)
    const [isEdit, setIsEdit] = React.useState<boolean>(false)
    const [mailError, setMailError] = React.useState<boolean>(false)
    const [phoneError, setPhoneError] = React.useState<boolean>(false)
    const [passportError, setPassportError] = React.useState<boolean>(false)
    const [submitButtonDisable, setSubmitButtonDisable] = React.useState<boolean>(true)
    const [isReadOnly, setIsReadOnly] = useToggle();


    React.useEffect(() => {
        /*
        const getCountry = (name: string): { name: string, code: string, flag: string } => {
          let c = countries.find((c: { name: string }) => c.name === name)
          if (c) {
            return c
          }
          return { name: "", code: "", flag: "" }
        }
        */

        if (props.selectedRow) {
            setCustID(props.selectedRow.id)
            setFirstName(props.selectedRow.firstName)
            setLastName(props.selectedRow.lastName)
            setEmail(props.selectedRow.Email)
            setPassport(props.selectedRow.Passport)
            setPhone(props.selectedRow.Phone)
            setBirthday(props.selectedRow.Birthday)
            // setCountry(getCountry(props.selectedRow.Country))
            setCountry(props.selectedRow.Country)
            setIsMember(props.selectedRow.is_member)
            setBirthday(props.selectedRow.Birthday)
            // setCountry(getCountry(props.selectedRow.Country)) // fix here
            setCountry(props.selectedRow.Country)
            setGender(props.selectedRow.Gender)
            setDisable(true)
            setIsEdit(true)
            // console.log(props.selectedRow)
        } else {
            setCustID("")
            setFirstName("")
            setLastName("")
            setEmail("")
            setPassport("")
            setPhone("")
            setIsMember(false)
            setBirthday(dayjs(new Date))
            setCountry(CountryType.KOREAN)
            setGender(GenderType.MALE)

            setDisable(false)
            setIsEdit(false)
        }
    }, [props.selectedRow])


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (passport && custID) {
            if (props.rows.find((row: { Passport: string, id: string }) => (row.Passport === passport) && (row.id === custID))) {
                const original = await DataStore.query(Customers, custID)

                try {
                    if (original === undefined) new Error("Something went wrong! maybe no ID or other.")
                    if (original !== undefined) {
                        await DataStore.save(
                            Customers.copyOf(original, updated => {
                                updated.firstName = firstName
                                updated.lastName = lastName
                                updated.Email = email
                                updated.Phone = phone
                                updated.Birthday = dayjs(birthday).format("YYYY-MM-DD")
                                updated.Country = country
                                updated.Gender = gender
                            })
                        )
                        console.log("Updated Done!")
                    }
                } catch (err) {
                    console.log("UPDATED_ERR:", err)
                }
            }

            setCustID("")
            setFirstName("")
            setLastName("")
            setEmail("")
            setPassport("")
            setPhone("")
            // setIsMember(false)
            setBirthday(dayjs(new Date))
            setCountry(CountryType.KOREAN)
            setGender(GenderType.MALE)
            setDisable(false)
            setIsEdit(false)
            props.onClose()
        } else {
            setIsNeedEmail(true)
        }

        if (passport && !custID) {
            try {
                await DataStore.save(
                    new Customers({
                        firstName: firstName,
                        lastName: lastName,
                        Passport: passport,
                        Phone: phone,
                        Birthday: dayjs(birthday).format("YYYY-MM-DD"),
                        Country: country,
                        Email: email,
                        Gender: gender,
                    })
                )

            } catch (err) {
                console.log("CREATE_ERR:", err)
            } finally {
                props.onClose()
            }

            setCustID("")
            setFirstName("")
            setLastName("")
            setEmail("")
            setPassport("")
            setPhone("")
            setIsMember(false)
            setBirthday(dayjs(new Date))
            setCountry(CountryType.KOREAN)
            setGender(GenderType.MALE)
            setDisable(false)
            setIsEdit(false)
        } else {
            setIsNeedEmail(true)
        }

    }


    const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value)
    }

    const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value)
    }

    const handleChangePassport = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassport(event.target.value)
    }

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value)
    }

    const handleChangeGender = (event: React.SyntheticEvent, value: string | null): void => {
        if (value) setGender(value as GenderType)
    }

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        setCustID("")
        setFirstName("")
        setLastName("")
        setEmail("")
        setPassport("")
        setPhone("")
        setCountry(CountryType.KOREAN)
        setGender(GenderType.MALE)
        setDisable(false)
    }

    const handleChangeBirthday = (newValue: Dayjs | null) => {
        if (newValue !== null) setBirthday(newValue)
    }

    // const handleChangeCountry = (event: React.SyntheticEvent, value: { name: string, code: string, flag: string } | null) => {
    const handleChangeCountry = (event: React.SyntheticEvent, value: string | null) => {
        if (value) setCountry(value as CountryType)
    }

    const handleCheck = () => {
        setIsMember(c => !c)
    }

    const handleValidation = (event: any, regex: any, type: string) => {
        if (!regex.test(event.target.value)) {
            if (type === "mail") {
                setMailError(true);
                setSubmitButtonDisable(true)
            }
            if (type === "phone") {
                setPhoneError(true);
                setSubmitButtonDisable(true)
            }
            if (type === "passport") {
                setPassportError(true);
                setSubmitButtonDisable(true)
            }
        } else {
            if (type === "mail") {
                setMailError(false);
                setSubmitButtonDisable(false)
            }
            if (type === "phone") {
                setPhoneError(false);
                setSubmitButtonDisable(false)
            }
            if (type === "passport") {
                setPassportError(false);
                setSubmitButtonDisable(false)
            }
        }
    };


    return (
        <Stack spacing={2} sx={{width: 400, bgcolor: "page"}} component={Paper} p={2}>
            <Stack component="form" onSubmit={handleSubmit} spacing={2}>
                <Stack direction="row" spacing={2}>
                    <TextField
                        inputProps={{style: {textTransform: 'capitalize'}}}
                        disabled={isReadOnly}
                        required
                        autoFocus
                        autoComplete="off"
                        name="firstName"
                        fullWidth
                        id="firstName"
                        label="First Name"
                        size='small'
                        variant="standard"
                        InputLabelProps={{shrink: true}}
                        value={firstName}
                        onChange={handleChangeFirstName}
                    />
                    <TextField
                        inputProps={{style: {textTransform: 'capitalize'}}}
                        disabled={isReadOnly}
                        required
                        autoFocus
                        autoComplete="off"
                        name="lastName"
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        size='small'
                        variant="standard"
                        InputLabelProps={{shrink: true}}
                        value={lastName}
                        onChange={handleChangeLastName}
                    />
                </Stack>

                <TextField
                    disabled={isReadOnly}
                    required
                    autoFocus
                    autoComplete="off"
                    error={mailError}
                    helperText={mailError && "Please enter a valid email address"}
                    onKeyDown={(event: any) => handleValidation(event, mailRegex, "mail")}
                    name="email"
                    fullWidth
                    id="email"
                    type="email"
                    label="Email Address"
                    size='small'
                    variant="standard"
                    InputLabelProps={{shrink: true}}
                    value={email}
                    onChange={handleChangeEmail}
                />
                <TextField
                    disabled={isReadOnly}
                    required
                    autoFocus
                    autoComplete="off"
                    inputProps={{style: {textTransform: 'uppercase'}}}
                    error={passportError}
                    helperText={passportError && "Please enter a valid passport number"}
                    onKeyDown={(event: any) => handleValidation(event, passportRegex, "passport")}
                    name="passport"
                    fullWidth
                    id="passport"
                    label="Passport"
                    size='small'
                    variant="standard"
                    InputLabelProps={{shrink: true}}
                    value={passport}
                    onChange={handleChangePassport}
                />
                <TextField
                    disabled={isReadOnly}
                    required
                    autoFocus
                    autoComplete="off"
                    error={phoneError}
                    helperText={phoneError && "Please enter a valid phone number"}
                    onKeyDown={(event: any) => handleValidation(event, phoneRegex, "phone")}
                    name="phone"
                    fullWidth
                    id="phone"
                    type="tel"
                    label="Phone number"
                    size='small'
                    variant="standard"
                    InputLabelProps={{shrink: true}}
                    value={phone}
                    onChange={handleChangePhone}
                />

                <Autocomplete
                    disabled={isReadOnly}
                    value={gender}
                    onChange={handleChangeGender}
                    id="size-small-filled"
                    size="small"
                    options={genderOptions}
                    getOptionLabel={(option) => option}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option}
                                size="small"
                                {...getTagProps({index})}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Gender"
                            placeholder="Female/Male"
                            size="small"
                            InputLabelProps={{shrink: true}}
                            required
                        />
                    )}
                />
                {/*
        <FormControlLabel
          disabled={isReadOnly}
          control={<Checkbox checked={isMember} onClick={handleCheck} />}
          label="Is Member"
        />
        */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack direction="row" spacing={2}>
                        <DesktopDatePicker
                            disabled={isReadOnly}
                            inputFormat="YYYY-MM-DD"
                            value={birthday}
                            onChange={handleChangeBirthday}
                            renderInput={(parms) => <TextField
                                {...parms}
                                sx={{width: "50%"}}
                            />}
                        />
                        <Autocomplete
                            disabled={isReadOnly}
                            value={country}
                            onChange={handleChangeCountry}
                            size="small"
                            options={countries}
                            getOptionLabel={(option) => option}
                            // getOptionLabel={(option) => option.name + option.flag}
                            renderTags={(value, getTagProps) => (
                                value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        label={option}
                                        size="small"
                                        {...getTagProps({index})}
                                    />
                                ))
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Country"
                                    placeholder="Country"
                                    size="small"
                                    sx={{width: "200px"}}
                                    InputLabelProps={{shrink: true}}
                                    required
                                />
                            )}
                        />
                    </Stack>
                </LocalizationProvider>
                <Button
                    type="submit"
                    disabled={submitButtonDisable}
                    variant="contained"
                    fullWidth
                >
                    Save
                </Button>
                <Button
                    fullWidth
                    onClick={handleCancel}
                    disabled={isReadOnly}
                >
                    Cancel
                </Button>
                <FormControlLabel
                    control={<Switch checked={isReadOnly} onChange={setIsReadOnly}/>}
                    label="ReadOnly"
                />
            </Stack>
        </Stack>
    )
}

const genderOptions = [
    GenderType.MALE, GenderType.FEMALE
]

const countries = [
    "KOREAN", "JAPAN", "USA", "MYANMAR"
]