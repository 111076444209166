import {Box, Card, CardContent, Grid, IconButton, Stack, Typography, useTheme,} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CarRentalIcon from '@mui/icons-material/CarRental';

interface Props {
    data: any;
    page: number;
    rowsPerPage: number;
    handleModalOpen: () => void;
    handleClick: any;
}

export default function CarCardLayout(props: Props) {
    const theme = useTheme()

    return (
        <Box sx={{mt: 2}}>
            <Grid
                container
                spacing={3}
                sx={{mb: 1}}
            >
                {props.data.length ? (
                    props.data
                        .slice(
                            props.page * props.rowsPerPage,
                            props.page * props.rowsPerPage + props.rowsPerPage
                        )
                        .map((row: any, index: number) => {
                            // const isItemSelected = isSelected(row.room);
                            // const labelId = `enhanced-table-checkbox-${index}`;
                            // console.log('row', row);
                            const bgColor = theme.palette.mode === "dark" ? "#A9E2F3" : '#f2f2f2';
                            const carColor = theme.palette.mode === "dark" ? "#000" : '#0489B1';

                            return (
                                <Grid item xs={3} key={index}>
                                    <Card sx={{
                                        borderRadius: '8px',
                                        minWidth: '100px',
                                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                                        backgroundColor: theme.palette.mode === 'dark' ? '#0C090A' : "#fff",
                                    }}>
                                        <CardContent sx={{position: "relative"}}>
                                            <IconButton
                                                aria-label="settings"
                                                sx={{position: "absolute", top: 2, right: 2}}
                                                onClick={(event) => {
                                                    props.handleClick(event, row.id)
                                                    props.handleModalOpen();
                                                }}
                                            >
                                                <EditIcon sx={{fontSize: 16}}/>
                                            </IconButton>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: bgColor,
                                                borderRadius: '8px',
                                                height: '100px'
                                            }}>
                                                <CarRentalIcon sx={{fontSize: 60, color: carColor}}/>
                                            </Box>
                                            <Stack direction="row" spacing={1} sx={{mt: 1}}>
                                                <Typography sx={{
                                                    fontWeight: "bold",
                                                    fontSize: 14
                                                }}>No: {row.carNumber}</Typography>

                                            </Stack>

                                            <Stack direction='row' spacing={1} sx={{alignItems: 'center'}}>
                                                <Typography variant="subtitle1" sx={{fontSize: 13, fontWeight: '500'}}>
                                                    Enable :
                                                </Typography>
                                                {row.Condition ? <CheckIcon sx={{color: 'green', fontSize: 18}}/> :
                                                    <CloseIcon sx={{color: 'red', fontSize: 18}}/>}
                                                <Typography variant="subtitle1" sx={{fontSize: 13, fontWeight: '500'}}>
                                                    , Max : {row.maxPerson} person
                                                </Typography>
                                            </Stack>

                                            {row.Remarks && (
                                                <Typography variant="subtitle1"
                                                            sx={{fontSize: 16, fontWeight: 'bold', color: 'green'}}>
                                                    * {row.Remarks}
                                                </Typography>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })
                ) : (
                    <Box
                        sx={{
                            width: "100%",
                            minHeight: '200px',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 5
                        }}
                    >
                        <Box sx={{
                            width: "60%",
                            minHeight: '80%',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: theme.palette.mode === 'dark' ? '#0C090A' : "#f2f2f2",
                        }}>
                            <Typography> No data found</Typography>
                        </Box>
                    </Box>
                )}
            </Grid>
        </Box>
    );
}
