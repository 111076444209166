const drawerWidth = 60;
const drawerBleeding = 60;
const mailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const phoneRegex = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/;
const passportRegex = /^(?!^0+$)[a-zA-Z0-9]{6,9}$/;
const isMobile = window.screen.width <= 768;

function pluralize(singularWord: string, pluralWord: string, count: number) {
    return count > 1 ? pluralWord : singularWord;
}

function getDatesInRange(startDate: any, endDate: any) {
    const d1 = new Date(startDate);
    const d2 = new Date(endDate)
    const date = new Date(d1.getTime());
    const dates = [];
    while (date <= d2) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return dates;
}

export {drawerWidth, drawerBleeding, mailRegex, phoneRegex, passportRegex, isMobile, pluralize, getDatesInRange};



