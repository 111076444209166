import React from 'react'
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from '@mui/material'


const Commission = () => {
    return <Paper>
        <Toolbar>
            <Typography variant="h5">Commission</Typography>
        </Toolbar>

        <TableContainer>
            <Table
                size={"small"}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell>Price</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                        <TableCell>0</TableCell>
                        <TableCell>0</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
}


export default Commission