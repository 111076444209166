import React from 'react'
import dayjs, {Dayjs} from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import OccTable from './OccTable'



const RoomOccupancy = () => {
  const [checkIn, setCheckIn] = React.useState<Dayjs>(dayjs())
  const [checkOut, setCheckOut] = React.useState<Dayjs>(dayjs().add(1, "day"))
  
  
  const handleChangeCheckIn = (value: Dayjs|null) => {
    if (value) setCheckIn(value)
  }

  const handleChangeCheckOut = (value: Dayjs|null) => {
    if (value) setCheckOut(value)
  }

  
  return <Stack spacing={2}>    
    <Paper>
      <Stack p={2} spacing={2}>
        <Stack 
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{width: 270}}
        >
          <Typography>Check In</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                  inputFormat="YYYY-MM-DD"
                  value={checkIn}
                  onChange={handleChangeCheckIn}
                  renderInput={(parms) => <TextField
                      {...parms}
                      size="small"
                      sx={{width: 170}}
                  />}
              />
          </LocalizationProvider>
        </Stack>

        <Stack 
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{width: 270}}
        >
          <Typography>Check Out</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                  inputFormat="YYYY-MM-DD"
                  value={checkOut}
                  onChange={handleChangeCheckOut}
                  renderInput={(parms) => <TextField
                      {...parms}
                      size="small"
                      sx={{width: 170}}
                  />}
              />
          </LocalizationProvider>
        </Stack>
      </Stack>
    </Paper>
    
    <OccTable 
      checkIn={checkIn}
      checkOut={checkOut}
    />
  </Stack>    
}



export default RoomOccupancy