import React from 'react'
import {Box, IconButton, Stack, Typography} from '@mui/material'
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from '@mui/lab'
import CloseIcon from '@mui/icons-material/CloseRounded';


interface ItemProps {
    title?: string
    label?: string
}

const Item: React.FC<ItemProps> = ({title, label}): JSX.Element => (
    <Stack my={1}>
        <Typography
            variant="h6"
            sx={{color: "#fff"}}
        >
            {title}
        </Typography>
        <Typography variant="subtitle1" sx={{color: "#ccc"}}>{label}</Typography>
    </Stack>
)


const ResvTimeLine = ({end = true, label}: { end?: boolean, label: string }) => (
    <TimelineItem>
        <TimelineSeparator>
            <TimelineDot/>
            {!end && <TimelineConnector/>}
        </TimelineSeparator>
        <TimelineContent sx={{
            color: "#fff"
        }}>{label}</TimelineContent>
    </TimelineItem>
)


interface Props<T> {
    selectedRow?: T
    onClose: () => void
    requestCheckIn: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Detail = <T extends {
    room_id: string,
    id: string,

    // person 1 info
    customer_id: string,
    customer_passport: string,
    customer_email: string,
    customer_phone: string,

    // person 2 info
    customer2_id: string,
    customer2_passport: string,
    customer2_email: string,
    customer2_phone: string,
}>(props: Props<T>): JSX.Element => {
    const {selectedRow, onClose, requestCheckIn} = props

    const handleCheckIn = (event: React.MouseEvent<HTMLButtonElement>) => {
        requestCheckIn(event)
        onClose()
    }


    const getName = (id: string) => {
        /*
        let room = thinRoom?.find((room: { id: string }) => room.id === id)
        if (room) return room.room
        */
        return "-"
    }

    const getChange = (id?: string) => {
        /*
        let to_room = thinRoomTransfer?.filter((resv: { uuid: string }) => resv.uuid === id).map(resv => getName(resv.toRoom))
        if (to_room) return to_room
        */
        return null
    }


    return <Stack spacing={5} p={3}>
        <IconButton onClick={onClose} sx={{
            position: "absolute",
            left: 20,
        }}>
            <CloseIcon sx={{color: "#fff"}}/>
        </IconButton>

        <Item
            title="Reservation ID"
            label={selectedRow?.id}
        />

        <Stack direction="row" spacing={10}>
            {/* Person 1 Info */}
            <Stack>
                <Item
                    title="Customer Name"
                    label={selectedRow?.customer_id}
                />

                <Item
                    title="Passport"
                    label={selectedRow?.customer_passport}
                />

                <Item
                    title="Email"
                    label={selectedRow?.customer_email}
                />

                <Item
                    title="Phone"
                    label={selectedRow?.customer_phone}
                />
            </Stack>

            <Box
                sx={{
                    borderLeft: "1px solid #aaa"
                }}
            />

            {/* Person 2 Info */}
            {!selectedRow?.customer2_id ?
                <Stack>
                    <Typography variant="h6" color="#fff">Personal 2 Information</Typography>
                    <Typography variant="subtitle1" align="center" sx={{color: "#ccc"}}>Empty</Typography>
                </Stack> :

                <Stack>
                    <Item
                        title="Customer Name"
                        label={selectedRow?.customer2_id}
                    />

                    <Item
                        title="Passport"
                        label={selectedRow?.customer2_passport}
                    />

                    <Item
                        title="Email"
                        label={selectedRow?.customer2_email}
                    />

                    <Item
                        title="Phone"
                        label={selectedRow?.customer2_phone}
                    />
                </Stack>
            }
        </Stack>

        {/*
        <Typography align="center" variant="h6" sx={{color: "#fff"}}>Reservation Timeline</Typography>

        <Timeline>
            {selectedRow?.is_check_in ? <ResvTimeLine end={false} label="Check In"/> : null}
            {(selectedRow?.is_check_in) ? <ResvTimeLine end={false} label={selectedRow?.room_id}/> : null}

            {getChange(selectedRow?.uuid)?.map((room, index, row) => (
                <ResvTimeLine label={room} end={checkIsEnd(row, index, selectedRow?.is_check_out)} key={index}/>
            ))}

            {selectedRow?.is_check_out ? <ResvTimeLine label="Check Out"/> : null}
        </Timeline>
        */}
    </Stack>
}

const checkIsEnd = (row: any, i: number, isDone?: boolean) => {
    if (row.length === (i + 1) && !isDone) return true
    return false
}

export default Detail