import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {TimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {Dayjs} from "dayjs";
import * as React from "react";


import {DataStore} from '@aws-amplify/datastore';
import {CountryType, Customers, CustomersMembership} from '../../models';

import {mailRegex, passportRegex, phoneRegex} from "../../constant";
// import {countries} from "../../countries";


const countries = [
    "KOREAN", "JAPAN", "USA", "MYANMAR"
]


interface Props {
    personCount: number;
    // get state
    firstNames: string[];
    lastNames: string[];
    emails: string[];
    passports: string[];
    phones: string[];
    birthdays: Dayjs[];
    countrys: CountryType[];
    /*
    */
    priceType: string;
    // customerIds: Customers[]
    custMems: CustomersMembership[]

    // uniqueErr: boolean

    // set state
    setFirstNames: (value: string[]) => void;
    setLastNames: (value: string[]) => void;
    setEmails: (value: string[]) => void;
    setPassports: (value: string[]) => void;
    setPhones: (value: string[]) => void;
    setBirthdays: (value: Dayjs[]) => void;
    setCountrys: (value: CountryType[]) => void;
    /*
    arrivalAirlines: string[];
    arrivalDates: Dayjs[];
    arrivalTimes: Dayjs[];
    departureAirlines: string[];
    departureDates: Dayjs[];
    departureTimes: Dayjs[];
    setArrivalAirlines: (value: string[]) => void;
    setArrivalDates: (value: Dayjs[]) => void;
    setArrivalTimes: (value: Dayjs[]) => void;
    setDepartureAirlines: (value: string[]) => void;
    setDepartureDates: (value: Dayjs[]) => void;
    setDepartureTimes: (value: Dayjs[]) => void;
    */
    // setCustomerIds: (value: Customers[]) => void
    setCustMems: (value: CustomersMembership[]) => void
    // setUniqueErr: (value: boolean) => void
}

export default function StepTwo(props: Props) {
    const [mailErrors, setMailErrors] = React.useState<boolean[]>([]);
    const [phoneErrors, setPhoneErrors] = React.useState<boolean[]>([]);
    const [passportErrors, setPassportErrors] = React.useState<boolean[]>([]);

    const [memberOptions, setMemberOptions] = React.useState<CustomersMembership[]>([])

    const [uniqueErr, setUniqueErr] = React.useState(false);

    /*
    let thinCustomer = useQuery(query("customer").orderByDesc("createdAt"));
    let thinMemberShip = useQuery(query("membership").orderByDesc("createdAt"))

    const memberOptions: { name: string, id: string }[] = []

    thinMemberShip?.forEach(mem => {
        memberOptions.push({name: `${mem.fullName} (${mem.serialNo}) (${mem.passport})`, id: mem.customerId})
    })
    */

    React.useEffect(() => {
        const fetchMembership = async () => {
            await DataStore.observeQuery(CustomersMembership).subscribe(snapshot => {
                const {items} = snapshot
                setMemberOptions(items)
                console.log(items)
            })
        }

        fetchMembership()
    }, [])


    let count = [];
    for (let i = 0; i <= props.personCount; i++) {
        count.push(i);
    }

    const handleChangeFirstName = (
        event: React.ChangeEvent<HTMLInputElement>,
        who: number
    ) => {
        let newFirstNames = [...props.firstNames];
        newFirstNames[who] = event.target.value;

        props.setFirstNames(newFirstNames);
    };

    const handleChangeLastName = (
        event: React.ChangeEvent<HTMLInputElement>,
        who: number
    ) => {
        let newLastNames = [...props.lastNames];
        newLastNames[who] = event.target.value;

        props.setLastNames(newLastNames);
    };

    const handleChangeEmail = (
        event: React.ChangeEvent<HTMLInputElement>,
        who: number
    ) => {
        let newEmails = [...props.emails];
        newEmails[who] = event.target.value;

        props.setEmails(newEmails);
    };

    const handleChangePhone = (
        event: React.ChangeEvent<HTMLInputElement>,
        who: number
    ) => {
        let newPhones = [...props.phones];
        newPhones[who] = event.target.value;

        props.setPhones(newPhones);
    };

    const handleChangePassport = (
        event: React.ChangeEvent<HTMLInputElement>,
        who: number
    ) => {
        let newPassports = [...props.passports];
        newPassports[who] = event.target.value;

        setUniqueErr(false);
        props.setPassports(newPassports);
        /*
        newPassports.forEach((pass) => {
            let isHave = thinCustomer?.find((cust) => cust.passport === pass);
            if (isHave) {
                setUniqueErr(true);
            }
        })
        */
    };

    const handleChangeBirthday = (value: Dayjs | null, who: number) => {
        if (value) {
            let newBirthdays = [...props.birthdays];
            newBirthdays[who] = value;

            props.setBirthdays(newBirthdays);
        }
    };

    const handleChangeCountry = (
        event: React.SyntheticEvent,
        value: string,
        who: number
    ) => {
        let newCountrys = [...props.countrys];
        newCountrys[who] = value as CountryType;

        props.setCountrys(newCountrys);
    };

    /*
    const handleChangeArrivalAirline = (value: string | null, who: number) => {
        if (value) {
            let newAirlines = [...props.arrivalAirlines];
            newAirlines[who] = value;

            props.setArrivalAirlines(newAirlines);
        }
    };

    const handleChangeArrivalDate = (value: Dayjs | null, who: number) => {
        if (value) {
            let newDates = [...props.arrivalDates];
            newDates[who] = value;

            props.setArrivalDates(newDates);
        }
    };

    const handleChangeArrivalTime = (value: Dayjs | null, who: number) => {
        if (value) {
            let newTimes = [...props.arrivalTimes];
            newTimes[who] = value;

            props.setArrivalTimes(newTimes);
        }
    };

    const handleChangeDepartureAirline = (value: string | null, who: number) => {
        if (value) {
            let newAirlines = [...props.departureAirlines];
            newAirlines[who] = value;

            props.setDepartureAirlines(newAirlines);
        }
    };

    const handleChangeDepartureDate = (value: Dayjs | null, who: number) => {
        if (value) {
            let newDates = [...props.departureDates];
            newDates[who] = value;

            props.setDepartureDates(newDates);
        }
    };

    const handleChangeDepartureTime = (value: Dayjs | null, who: number) => {
        if (value) {
            let newTimes = [...props.departureTimes];
            newTimes[who] = value;

            props.setDepartureTimes(newTimes);
        }
    };
    */


    /*
    const handleChangeCustomer = (event: React.SyntheticEvent, value: Customers | null, who: number) => {
        if (value) {
            let newCust = [...props.customerIds]
            newCust[who] = value

            props.setCustomerIds(newCust)
        }
    }*/
    
    const handleChangeCustomerMember = (event: React.SyntheticEvent, value: CustomersMembership|null, who: number) => {
        if (value) {
            let newCustMember = [...props.custMems]
            newCustMember[who] = value
            
            props.setCustMems(newCustMember)
            console.log(value)
        }
    }

    const handleValidation = (
        event: any,
        regex: any,
        type: string,
        index: number
    ) => {
        let newMailErrors = [...mailErrors];
        let newPhoneErrors = [...phoneErrors];
        let newPassportErrors = [...passportErrors];

        if (!regex.test(event.target.value)) {
            if (type === "mail") {
                newMailErrors[index] = true;
                setMailErrors(newMailErrors);
            }
            if (type === "phone") {
                newPhoneErrors[index] = true;
                setPhoneErrors(newPhoneErrors);
            }
            if (type === "passport") {
                newPassportErrors[index] = true;
                setPassportErrors(newPassportErrors);
            }
        } else {
            if (type === "mail") {
                newMailErrors[index] = false;
                setMailErrors(newMailErrors);
            }
            if (type === "phone") {
                newPhoneErrors[index] = false;
                setPhoneErrors(newPhoneErrors);
            }
            if (type === "passport") {
                newPassportErrors[index] = false;
                setPassportErrors(newPassportErrors);
            }
        }
    };


    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Personal Information
            </Typography>

            {count.map((p: any, index: any) => (
                props.priceType !== "membership" ?
                    <div
                        style={{
                            marginBottom: "10%",
                            borderTop: "1px solid #ddd",
                        }}
                        key={index}
                    >
                        <Typography variant="subtitle1" my={2}>{`Person ${index + 1
                        }`}</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputProps={{style: {textTransform: "capitalize"}}}
                                    required
                                    id="firstName"
                                    name="firstName"
                                    label="First name"
                                    fullWidth
                                    autoComplete="off"
                                    variant="standard"
                                    value={props.firstNames[index]}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleChangeFirstName(event, index)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputProps={{style: {textTransform: "capitalize"}}}
                                    required
                                    id="lastName"
                                    name="lastName"
                                    label="Last name"
                                    fullWidth
                                    autoComplete="off"
                                    variant="standard"
                                    value={props.lastNames[index]}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleChangeLastName(event, index)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    fullWidth
                                    autoComplete="off"
                                    error={mailErrors[index]}
                                    helperText={
                                        mailErrors[index] && "Please enter a valid email address"
                                    }
                                    onKeyDown={(event: any) =>
                                        handleValidation(event, mailRegex, "mail", index)
                                    }
                                    variant="standard"
                                    value={props.emails[index]}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleChangeEmail(event, index)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="phone"
                                    name="phone"
                                    label="Phone Number"
                                    fullWidth
                                    autoComplete="off"
                                    error={phoneErrors[index]}
                                    helperText={
                                        phoneErrors[index] && "Please enter a valid phone number"
                                    }
                                    onKeyDown={(event: any) =>
                                        handleValidation(event, phoneRegex, "phone", index)
                                    }
                                    variant="standard"
                                    value={props.phones[index]}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleChangePhone(event, index)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="passport"
                                    name="passport"
                                    label="Passport"
                                    inputProps={{style: {textTransform: 'uppercase'}}}
                                    fullWidth
                                    autoComplete="off"
                                    error={passportErrors[index] || uniqueErr}
                                    helperText={
                                        (passportErrors[index] &&
                                            "Please enter a valid passport number") ||
                                        (uniqueErr && "Passport must be unique")
                                    }
                                    onKeyDown={(event: any) =>
                                        handleValidation(event, passportRegex, "passport", index)
                                    }
                                    variant="standard"
                                    value={props.passports[index]}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleChangePassport(event, index)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="Birthday"
                                        value={props.birthdays[index]}
                                        inputFormat="YYYY-MM-DD"
                                        onChange={(value) => handleChangeBirthday(value, index)}
                                        renderInput={(parms) => <TextField {...parms} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    value={props.countrys[index]}
                                    onChange={(event: any, value: any) =>
                                        handleChangeCountry(event, value, index)
                                    }
                                    size="small"
                                    options={countries}
                                    getOptionLabel={(option) => option}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                variant="outlined"
                                                label={option}
                                                size="small"
                                                {...getTagProps({index})}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Country"
                                            autoComplete="off"
                                            placeholder="Country"
                                            size="small"
                                            InputLabelProps={{shrink: true}}
                                            required
                                        />
                                    )}
                                />
                            </Grid>
                            {/*
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    inputProps={{style: {textTransform: "capitalize"}}}
                                    required
                                    id="arrivalAirline"
                                    name="arrivalAirline"
                                    label="Arrival Airline"
                                    fullWidth
                                    autoComplete="off"
                                    variant="standard"
                                    value={props.arrivalAirlines[index]}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleChangeArrivalAirline(event.target.value, index)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="Arrival Date"
                                        value={props.arrivalDates[index]}
                                        inputFormat="YYYY-MM-DD"
                                        onChange={(value) => handleChangeArrivalDate(value, index)}
                                        renderInput={(parms) => <TextField {...parms} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label="Arrival Time"
                                        ampm={false}
                                        value={props.arrivalTimes[index]}
                                        onChange={(value) => handleChangeArrivalTime(value, index)}
                                        renderInput={(parms) => <TextField {...parms} />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <TextField
                                    inputProps={{style: {textTransform: "capitalize"}}}
                                    required
                                    id="departureAirline"
                                    name="departureAirline"
                                    label="Departure Airline"
                                    fullWidth
                                    autoComplete="off"
                                    variant="standard"
                                    value={props.departureAirlines[index]}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleChangeDepartureAirline(event.target.value, index)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="Departure Date"
                                        value={props.departureDates[index]}
                                        inputFormat="YYYY-MM-DD"
                                        onChange={(value) => handleChangeDepartureDate(value, index)}
                                        renderInput={(parms) => <TextField {...parms} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label="Departure Time"
                                        ampm={false}
                                        value={props.departureTimes[index]}
                                        onChange={(value) => handleChangeDepartureTime(value, index)}
                                        renderInput={(parms) => <TextField {...parms} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            */}

                        </Grid>
                    </div>
                    : (
                        <div
                            style={{
                                marginBottom: "10%",
                                borderTop: "1px solid #ddd",
                            }}
                            key={index}
                        >
                            <Typography variant="subtitle1" my={2}>{`Membership Person ${index + 1
                            }`}</Typography>
                            <Grid container spacing={3}>
                                {/* Membership Dropdown */}
                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        // value={props.customerIds[index]}
                                        value={props.custMems[index]}
                                        onChange={(event, value) => handleChangeCustomerMember(event, value, index)}
                                        id="size-small-filled"
                                        size="small"
                                        options={memberOptions}  // []{name: "{name}+({serial})+({passport})", id: ""}
                                        getOptionLabel={(option) => `${option.customers.firstName} ${option.customers.lastName}`}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    variant="outlined"
                                                    label={`${option.customers.firstName} ${option.customers.lastName}`}
                                                    size="small"
                                                    {...getTagProps({index})}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Membership"
                                                placeholder="Name (serial) (passport)"
                                                size="small"
                                                InputLabelProps={{shrink: true}}
                                                required
                                            />
                                        )}
                                    />
                                </Grid>

                                {/*
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        inputProps={{style: {textTransform: "capitalize"}}}
                                        required
                                        id="arrivalAirline"
                                        name="arrivalAirline"
                                        label="Arrival Airline"
                                        fullWidth
                                        autoComplete="off"
                                        variant="standard"
                                        value={props.arrivalAirlines[index]}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            handleChangeArrivalAirline(event.target.value, index)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="Arrival Date"
                                            value={props.arrivalDates[index]}
                                            inputFormat="YYYY-MM-DD"
                                            onChange={(value) => handleChangeArrivalDate(value, index)}
                                            renderInput={(parms) => <TextField {...parms} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            label="Arrival Time"
                                            ampm={false}
                                            value={props.arrivalTimes[index]}
                                            onChange={(value) => handleChangeArrivalTime(value, index)}
                                            renderInput={(parms) => <TextField {...parms} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        inputProps={{style: {textTransform: "capitalize"}}}
                                        required
                                        id="departureAirline"
                                        name="departureAirline"
                                        label="Departure Airline"
                                        fullWidth
                                        autoComplete="off"
                                        variant="standard"
                                        value={props.departureAirlines[index]}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            handleChangeDepartureAirline(event.target.value, index)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="Departure Date"
                                            value={props.departureDates[index]}
                                            inputFormat="YYYY-MM-DD"
                                            onChange={(value) => handleChangeDepartureDate(value, index)}
                                            renderInput={(parms) => <TextField {...parms} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            label="Departure Time"
                                            ampm={false}
                                            value={props.departureTimes[index]}
                                            onChange={(value) => handleChangeDepartureTime(value, index)}
                                            renderInput={(parms) => <TextField {...parms} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                */}

                            </Grid>
                        </div>
                    )
            ))}
        </React.Fragment>
    );
}


