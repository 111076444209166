import React from 'react'
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    TextField
} from '@mui/material'
import {DataStore, SortDirection} from "@aws-amplify/datastore";
import {Reservations, TransportationCheckIn} from "../../models";


interface Props {
    resv: Reservations|null
    transportIn: {krw?: number|null, thb?: number|null, usd?: number|null}|null,
    setTransportIn: (state: {krw?: number|null, thb?: number|null, usd?: number|null}) => void
}

const Single = (props: Props) => {
    const {transportIn, setTransportIn, resv} = props
    const [krw, setKrw] = React.useState<number|null|undefined>(0)
    const [usd, setUsd] = React.useState<number|null|undefined>(0)
    const [thb, setThb] = React.useState<number|null|undefined>(0)
    
    React.useEffect(() => {
        setKrw(0)
        setUsd(0)
        setThb(0)
        if (resv !== null) {
            let subscription = DataStore
                .observeQuery(TransportationCheckIn, (c) => c.reservationsID("eq", resv.id), {
                    sort: c => c.createdAt(SortDirection.DESCENDING)
                })
                .subscribe((snapshot) => {
                    let {items} = snapshot
                
                    console.log(items)
                    setKrw(items[0].KOR)
                    setUsd(items[0].USD)
                    setThb(items[0].THB)
                })
            return () => subscription.unsubscribe()
        }
    }, [resv])
    
    
    const handleChangeKr = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = +event.target.value
        if (!isNaN(value)) {
            setKrw(value)
            setTransportIn({...transportIn, krw: value})
        }
    }
    
    const handleChangeUs = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = +event.target.value
        if (!isNaN(value)) {
            setUsd(value)
            setTransportIn({...transportIn, usd: value})
        }
    }
    
    const handleChangeTh = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = +event.target.value
        if (!isNaN(value)) {
            setThb(value)
            setTransportIn({...transportIn, thb: value})
        }
    }
    
    return <Paper>
        <Toolbar>
            <Typography variant="h5">Transportation (check in)</Typography>
        </Toolbar>

        <TableContainer>
            <Table
                size={"small"}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>(KRW)</TableCell>
                        <TableCell>(USD)</TableCell>
                        <TableCell>(THB)</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                        <TableCell>
                            <TextField 
                                size="small"
                                label="Korea Won"
                                value={krw}
                                onChange={handleChangeKr}
                                InputLabelProps={{shrink: true}}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField 
                                size="small"
                                label="Korea Dollar"
                                value={usd}
                                onChange={handleChangeUs}
                                InputLabelProps={{shrink: true}}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField 
                                size="small"
                                label="Thai Baht"
                                value={thb}
                                onChange={handleChangeTh}
                                InputLabelProps={{shrink: true}}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
}


export default Single