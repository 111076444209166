import {Autocomplete, Button, Chip, Paper, Stack, TextField} from '@mui/material';
import dayjs, {Dayjs} from "dayjs";
import * as React from 'react';
import {DataStore} from "@aws-amplify/datastore";
import {Reservations, ReservationsRooms, RoomHistory, Rooms, ReservationsCondition} from "../../models";


interface Props {
    reservation?: Reservations
    toRoom: Rooms|null
    setToRoom: (current: Rooms|null) => void
    fromRoom: Rooms|null
    setFromRoom: (current: Rooms|null) => void
}

export default function CreateSeasonalPrice(props: Props) {
    const [reservation, setReservation] = React.useState<Reservations | null>(null)
    const [resvOptions, setResvOptions] = React.useState<Reservations[]>([])
    const [roomOptions, setRoomOptions] = React.useState<Rooms[]>([])
    
    const {toRoom, setToRoom, fromRoom, setFromRoom} = props
    
    const [currentResvRoom, setCurrentResvRoom] = React.useState<ReservationsRooms|null>(null)


    React.useEffect(() => {
        let fetchRooms = async () => {
            let data = await DataStore.query(Rooms)
            setRoomOptions(data.filter(rpt => rpt.roomNumber !== fromRoom?.roomNumber))
        }
        let fetchRresvRoom = async () => {
            const resvRoom: any = await DataStore.query(ReservationsRooms)
            const room = resvRoom.filter((c: ReservationsRooms) => c.reservations.id === props.reservation?.id)
            setFromRoom(room[0].rooms)
            setCurrentResvRoom(room[0])
        }
        
        fetchRooms()
        fetchRresvRoom()
        if (props.reservation) setReservation(props.reservation)
    }, [props.reservation, fromRoom?.roomNumber, setFromRoom])
    
    
    React.useEffect(() => {
        const fetchResvRooms: any = DataStore.observeQuery(ReservationsRooms).subscribe((snapshot) => {
            const had = snapshot
                .items
                .filter((resv: ReservationsRooms) => (
                    resv.reservations.Condition !== ReservationsCondition.CANCELLED
                ))
                .filter((resv: ReservationsRooms) => (
                    dayjs().isBefore(dayjs(resv.reservations.checkOut))
                ))
                .map(resv => resv.rooms.id)
            setRoomOptions(c => c.filter(room => !had.includes(room.id)))
        });
        return () => fetchResvRooms.unsubscribe()
    }, [])


    const handleChangeReservation = (event: React.SyntheticEvent, value: Reservations | null): void => {
        if (value) setReservation(value)
    }

    const handleChangeToRoom = (event: React.SyntheticEvent, value: Rooms | null) => {
        if (value) setToRoom(value)
    }


    const handleSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (reservation && fromRoom && toRoom) {
            try {
                const roomHistory = await DataStore.save(
                    new RoomHistory({
                        Reservations: reservation,
                        from: fromRoom.roomNumber,
                        to: toRoom.roomNumber,
                        Remarks: "",
                        roomHistoryReservationsId: reservation.id,
                    })
                )
                console.log("Created Success historu", roomHistory)
                
                // updated reservationsRooms
                let newResvRoom: ReservationsRooms|null = null
                if (currentResvRoom !== null) {
                    newResvRoom = await DataStore.save(
                        ReservationsRooms.copyOf(currentResvRoom, updated => {
                            updated.reservations = reservation
                            updated.rooms = toRoom
                        })
                    )
                    console.log("Updated Success ReservationsRooms", newResvRoom)
                }
                
                // updated reservations
                if (newResvRoom !== null) {
                    const originalResv = await DataStore.query(Reservations, reservation.id)
                    if (originalResv !== undefined) {
                        await DataStore.save(
                            Reservations.copyOf(originalResv, updated => {
                                updated.Rooms = [newResvRoom]
                            })
                        )
                    }
                }
                
                setFromRoom(toRoom)
            } catch (err) {
                console.log("CREATE_ERR:", err)
            }
        }


        setReservation(null)
        setToRoom(null)
    }

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        setReservation(null)
        setToRoom(null)
    }

    return (
        <Stack spacing={2} component={Paper} p={2}>
            <TextField 
                value={fromRoom?.roomNumber}
                variant="standard"
                disabled
            />

            <Autocomplete
                value={toRoom}
                onChange={handleChangeToRoom}
                id="size-small-filled"
                size="small"
                options={roomOptions}
                getOptionLabel={(option) => option.roomNumber}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option.roomNumber}
                            size="small"
                            {...getTagProps({index})}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="To Room"
                        placeholder="ROOM"
                        size="small"
                        InputLabelProps={{shrink: true}}
                        required
                    />
                )}
            />

            <Button variant="contained" onClick={handleSave}>Confirm Transfer</Button>
            <Button onClick={handleCancel}>Cancel</Button>
        </Stack>
    );
}

