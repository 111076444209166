import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
    AppBar,
    Box,
    Stack,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Toolbar,
    Typography,
    useTheme
} from "@mui/material";

import { grey } from "@mui/material/colors";
import React from "react";
import { ReservationsCondition } from "../../models";
import BookingDetail from "../Booking/BookingDetail";
import BookingCarText from './BookingCarText';
import BookingCustomerCard from "./BookingCustomerCard";
import BookingRoomText from './BookingRoomText';

interface Props {
    data: any;
    page: number;
    rowsPerPage: number;
    handleClick: any;
    
    reload: boolean
    setReload: (c: boolean) => void
}

export default function BookingCardLayout(props: Props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<readonly string[]>([]);


    let [rows, setRows] = React.useState([])

    React.useEffect(() => {
        let newRows: any = []
        props.data.forEach(async (m: any) => {
            if (newRows.find((row: any) => row.reservationsID === m.reservations.id)) {
                let toAdd = newRows.find((row: any) => row.reservationsID === m.reservations.id)
                if (toAdd) toAdd.customers2 = m.customers
            } else {
                newRows.push({
                    ...m,
                    customers2: "",
                    reservationsID: m.reservations.id
                })
            }
        })
        setRows(newRows)
    }, [props.data])


    const handleModalOpen = () => {
        setOpen(true);
    };

    const handleModalClose = () => {
        setOpen(false);
        props.setReload(!props.reload)
        if (selected) {
            handleCleanSelected();
        }
    };

    const handleCleanSelected = () => setSelected([])

    return (
        <Box
            sx={{
                mt: 5,
            }}
        >
            <Grid
                container
                spacing={2}
                sx={{
                    mb: 1,
                }}
            >
                {rows.length ? (
                    rows
                        .slice(
                            props.page * props.rowsPerPage,
                            props.page * props.rowsPerPage + props.rowsPerPage
                        )
                        .map((row: any, index: number) => {

                            return (
                                <Grid item xs={4} key={index}>
                                    <Card sx={{
                                        minWidth: 275,
                                        borderRadius: '8px',
                                        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                                        bgcolor: theme.palette.mode === 'dark' ? '#0C090A' : "#fff",
                                    }}>
                                        <CardContent sx={{
                                            position: "relative",
                                        }}>
                                            <Box sx={{ borderBottom: '1px solid #ddd', pb: 1 }}>
                                                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                                    <AccessTimeIcon />
                                                    {/* <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                                                        {row.reservations.checkIn}, {` ROOM NO. ${row.Rooms?.roomNumber}`}
                                                    </Typography> */}
                                                    <BookingRoomText reload={props.reload} reservationsID={row.reservations.id} />
                                                </Stack>
                                            </Box>

                                            <Box sx={{ padding: 1 }}>
                                                <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                                                    <Typography sx={{
                                                        fontSize: 13,
                                                        fontWeight: 'bold'
                                                    }}>ReservationID: {row.reservations.id.slice(-4)}</Typography>
                                                    {row.reservations.Condition === ReservationsCondition.BOOKED && (
                                                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                                            <CheckIcon sx={{
                                                                color: '#3ADF00',
                                                                fontSize: 16,
                                                                fontWeight: 'bold'
                                                            }} />
                                                            <Typography sx={{
                                                                color: '#3ADF00',
                                                                fontSize: 14,
                                                                fontWeight: 'bold'
                                                            }}>BOOKED</Typography>
                                                        </Stack>
                                                    )}
                                                    {row.reservations.Condition === ReservationsCondition.PENDING && (
                                                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                                            <CheckIcon sx={{
                                                                color: '#ff9800',
                                                                fontSize: 16,
                                                                fontWeight: 'bold'
                                                            }} />
                                                            <Typography sx={{
                                                                color: '#ff9800',
                                                                fontSize: 14,
                                                                fontWeight: 'bold'
                                                            }}>PENDING</Typography>
                                                        </Stack>
                                                    )}
                                                    {row.reservations.Condition === ReservationsCondition.CONFIRM && (
                                                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                                            <CheckIcon sx={{
                                                                color: '#4caf50',
                                                                fontSize: 16,
                                                                fontWeight: 'bold'
                                                            }} />
                                                            <Typography sx={{
                                                                color: '#4caf50',
                                                                fontSize: 14,
                                                                fontWeight: 'bold'
                                                            }}>CONFIRM</Typography>
                                                        </Stack>
                                                    )}
                                                    {row.reservations.Condition === ReservationsCondition.CANCELLED && (
                                                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                                            <CheckIcon sx={{
                                                                color: '#d32f2f',
                                                                fontSize: 16,
                                                                fontWeight: 'bold'
                                                            }} />
                                                            <Typography sx={{
                                                                color: '#d32f2f',
                                                                fontSize: 14,
                                                                fontWeight: 'bold'
                                                            }}>CANCELLED</Typography>
                                                        </Stack>
                                                    )}
                                                </Stack>
                                                <BookingCustomerCard reservationsID={row.reservations.id} />


                                                <Stack spacing={1} direction="row" sx={{ my: 2 }}>
                                                   <BookingCarText reservationsID={row.reservations.id} />
                                                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                                        {row.reservations.isGeneral === true ?
                                                            <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}> Rev
                                                                type : General</Typography> : null}
                                                        {row.reservations.isTour === true ?
                                                            <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}> Rev
                                                                type : Tour</Typography> : null}
                                                        {row.reservations.isMembership === true ?
                                                            <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}> Rev
                                                                type : Membership</Typography> : null}
                                                    </Stack>
                                                </Stack>

                                                <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
                                                    <Typography variant="subtitle1"
                                                        sx={{ fontSize: 13, fontWeight: 'bold' }}>
                                                        IsCheckin :
                                                    </Typography>
                                                    {row.reservations.isCheckIn ? <CheckIcon sx={{ color: 'green' }} /> :
                                                        <CloseIcon sx={{ color: 'red' }} />}
                                                </Stack>
                                                <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
                                                    <Typography variant="subtitle1"
                                                        sx={{ fontSize: 13, fontWeight: 'bold' }}>
                                                        IsCheckOut :
                                                    </Typography>
                                                    {row.reservations.isCheckOut ? <CheckIcon sx={{ color: 'green' }} /> :
                                                        <CloseIcon sx={{ color: 'red' }} />}
                                                </Stack>
                                            </Box>

                                            <Button sx={{
                                                fontSize: 12, position: 'absolute', fontWeight: 'bold',
                                                right: 4, bottom: 4,
                                            }} onClick={() => {
                                                setSelected(row);
                                                handleModalOpen();
                                            }}>View Detail</Button>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })
                ) : (
                    <Box
                        sx={{
                            width: "100%",
                            minHeight: '200px',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{
                            width: "60%",
                            minHeight: '80%',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: grey[50],
                        }}>
                            <Typography sx={{ color: theme.palette.mode === 'dark' ? '#000' : '#000' }}> No data
                                found</Typography>
                        </Box>
                    </Box>
                )}
            </Grid>
            <Dialog
                fullScreen
                open={open}
                onClose={handleModalClose}
                aria-labelledby="responsive-dialog-title"
            >
                <AppBar sx={{ position: "relative", background: "rgb(42, 19, 46)" }}>
                    <Toolbar>
                        <Stack 
                            direction="row" 
                            alignItems="center" 
                            justifyContent="space-between"
                            sx={{width: "100%"}}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleModalClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Booking Detail Information
                            </Typography>
                            <Button variant="contained">Invoice</Button>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
                    <BookingDetail data={selected} setReload={props.setReload} />
                </DialogContent>
            </Dialog>
        </Box>
    );
}
