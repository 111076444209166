import * as React from "react";
import {alpha} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import {visuallyHidden} from "@mui/utils";
// import FloatingActionButtons from '../FloatingActionButtons';
// import CreateRoom from './createCustomer';
import Grid from "@mui/material/Grid";

import {Dayjs} from "dayjs"


// import useToggle from '../ReadOnlyHook';

interface Data {
    id: string;
    check_in: string; // Datejs
    check_out: string; // Datejs
    room_id: string; // ref_id
    car_id: string; // ref_id
    customer_id: string; // ref_id

    arrival_airline: string;
    arrival_date: string;
    arrival_time: string;

    departure_airline: string;
    departure_date: string;
    departure_time: string;

    // from cashflow
    depo: number
    during: number
    max_person: number

    customer_email: string;
    customer_passport: string;
    customer_phone: string;

    status: string;
}

// function createData(
// room: string,
// room_type: string,
// max_person: number,
// // is_checkout: boolean,
// // check_in: string,
// // check_out: string,
// remarks: number,
// // prices: number
// ): Data {
// return {
// room,
// room_type,
// max_person,
// // is_checkout, check_in, check_out,
// remarks,
// // prices
// };
// }

// const rows: Data[] = [
// createData("A9456", "Korean Grand Garden", 2, true, "2022-04-14", "2022-04-15", 82, 100),
// ];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string | boolean },
    b: { [key in Key]: number | string | boolean }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: "customer_id",
        numeric: false,
        disablePadding: true,
        label: "Customer",
    },
    {
        id: "max_person",
        numeric: false,
        disablePadding: true,
        label: "Max Person",
    },
    {
        id: "arrival_time",
        numeric: false,
        disablePadding: true,
        label: "Arrival Time",
    },
    {
        id: "arrival_date",
        numeric: false,
        disablePadding: true,
        label: "Arrival Date",
    },
    {
        id: "arrival_airline",
        numeric: false,
        disablePadding: true,
        label: "Arrival Airline",
    },
    {
        id: "check_in",
        numeric: false,
        disablePadding: true,
        label: "Check In",
    },
    {
        id: "check_out",
        numeric: false,
        disablePadding: true,
        label: "Check Out",
    },
    {
        id: "room_id",
        numeric: false,
        disablePadding: true,
        label: "Room",
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
    selected: readonly string[];
    cleanSelected: () => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const {numSelected, selected, cleanSelected} = props;

    // const [isReadOnly, setIsReadOnly] = useToggle();

    /*
    let thinCustomer = useQuery(query("reservations").orderByDesc("createdAt"));
    const ids = thinCustomer
        ?.filter((room) => selected.includes(room.id))
        .map((id) => id.id);
    */
    
    const handleDelete = async () => {
        cleanSelected();
    };

    return (
        <Toolbar
            sx={{
                pl: {sm: 2},
                pr: {xs: 1, sm: 1},
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{flex: "1 1 100%"}}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{flex: "1 1 100%"}}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Check Out
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton onClick={handleDelete}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon/>
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

export default function Booking(props: { rows: Data[], dateFilter: Dayjs | null }) {
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<keyof Data>("customer_id");
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [open, setOpen] = React.useState(false);


    let rows = props.rows.filter((row: Data) => row.check_out === props!.dateFilter!.format("YYYY-MM-DD"))


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const selectedRow = rows.find((row) => row.id === selected.slice(-1)[0]);

    const handleCleanSelected = () => setSelected([]);

    const handleClickCheckOut = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (selectedRow !== undefined) {
            /*
            updateRecord("reservations", selectedRow?.id, {
                isCheckOut: true,
            });
            */
        }
    };

    return (
        <Box sx={{width: "100%"}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={{width: "100%", mb: 2}}>
                        <EnhancedTableToolbar
                            numSelected={selected.length}
                            selected={selected}
                            cleanSelected={handleCleanSelected}
                        />
                        <TableContainer>
                            <Table
                                sx={{minWidth: 750}}
                                aria-labelledby="tableTitle"
                                size={dense ? "small" : "medium"}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                />
                                <TableBody>
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                                    {rows.length
                                        ? stableSort(rows, getComparator(order, orderBy))
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <React.Fragment>
                                                        <TableRow
                                                            hover
                                                            onClick={(event) => handleClick(event, row.id)}
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            selected={isItemSelected}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={isItemSelected}
                                                                    inputProps={{
                                                                        "aria-labelledby": labelId,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="left">{row.customer_id}</TableCell>
                                                            <TableCell align="left">{row.max_person}</TableCell>
                                                            <TableCell align="left">{row.arrival_time}</TableCell>
                                                            <TableCell align="left">{row.arrival_date}</TableCell>
                                                            <TableCell align="left">{row.arrival_airline}</TableCell>
                                                            <TableCell align="left">{row.check_in}</TableCell>
                                                            <TableCell align="left">{row.check_out}</TableCell>
                                                            <TableCell align="left">{row.room_id}</TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                );
                                            })
                                        : null}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (dense ? 33 : 53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6}/>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <FormControlLabel
                        control={<Switch checked={dense} onChange={handleChangeDense}/>}
                        label="Dense padding"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}