import React, {useState} from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, {timelineItemClasses} from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CustomPagination from "../CustomPagination";
import {DataStore, Predicates, SortDirection} from "@aws-amplify/datastore";
import {Reservations} from "../../models";

import dayjs from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime'
import { ReservationsCondition } from "../../models";


export default function CustomTimeline() {
    dayjs.extend(relativeTime)
    let color = "grey" as any;
    let bgColor = "grey" as any;
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [reservation, setReservation] = useState<Reservations[]>([])

    React.useEffect(() => {
        const subscription: any = DataStore.observeQuery(Reservations, Predicates.ALL, {
            sort: s => s.createdAt(SortDirection.DESCENDING),
          }).subscribe((snapshot) => setReservation(snapshot.items));
        return () => subscription.unsubscribe();
    }, [])


    let data: { id: string, status: string, time: string }[] = []
    let ids: string[] = []  // to check unique

    reservation?.forEach(res => {
        if (!ids.includes(res.id)) {
            if (res.Condition) data.push({
                id: res.id,
                status: res.Condition,
                time: dayjs(res.createdAt).fromNow(true) + " ago.",
            })
        }
        ids.push(res.id)
    })


    // console.log(dayjs(thinReservation?.createdAt).fromNow(true), thinReservation?.carNo)
    console.log(data.length)


    function getColor(status: string) {
        switch (status) {
            case ReservationsCondition.BOOKED:
                color = "success";
                break;
            case ReservationsCondition.PENDING:
                color = "info";
                break;
            case ReservationsCondition.CONFIRM:
                color = "info";
                break;
            case ReservationsCondition.CANCELLED:
                color = "error";
                break;
            default:
                return color;
        }
        return color;
    }

    function getBgColor(status: string) {
        switch (status) {
            case "checkin":
                bgColor = "#e6fffb";
                break;
            case "booked":
                bgColor = "#f6ffed";
                break;
            case "successful":
                bgColor = "#f6ffed";
                break;
            case "checkout":
                bgColor = "#e6f7ff";
                break;
            case "cancelled":
                bgColor = "#fff1f0";
                break;
            default:
                return bgColor;
        }
        return bgColor;
    }

    return (
        <div
            style={{
                width: "100%",
                height: "calc(100vh - 240px)",
                overflowX: "hidden",
                overflowY: "scroll",
            }}
        >
            {data.length ? (
                <Timeline
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                        },
                    }}
                >
                    {data
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((d, index) => (
                            <TimelineItem key={index}>
                                <TimelineSeparator>
                                    <TimelineDot variant="outlined" color={getColor(d.status)}/>
                                    <TimelineConnector/>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Box
                                        style={{
                                            backgroundColor: getBgColor(d.status),
                                            width: "100%",
                                            padding: "4px 10px",
                                            borderRadius: 5,
                                        }}
                                    >
                                        <Typography color="text.primary">{d.time}</Typography>
                                        <Typography color="text.primary">{`Reservation is ${d.status}`}</Typography>
                                        <Typography
                                            color="text.primary"
                                            fontWeight="bold"
                                        >{`#${d.id.slice(-4)}`}</Typography>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                </Timeline>
            ) : (
                <div>No data found</div>
            )}
            <CustomPagination
                total={4}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    );
}