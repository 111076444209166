import {StyledEngineProvider} from "@mui/material/styles";
import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Page404 from "./component/404Page";
import Header from "./component/AppBar";
import Booking from "./component/Booking/index";
import Customer from "./component/CustomerTable";
import Car from "./component/GolfCar";
// import "./component/Login/auth.css";
import Main from "./component/Main/Main";
import Membership from "./component/Membership";
import MobileViewText from "./component/MobileViewText";
import Room from "./component/RoomTable";
import SeasonalPriceTable from "./component/SeasonalPrice";
import ReservationForm from "./component/ReservationForm";
import TempPlaceHolder from "./component/TempPlaceHolder";
import Pickup from './component/Pickup';
import Calc from './component/Calc';
import Revenue from './component/Revenue';
import RoomOccupancy from './component/RoomOccupancy';

import {ToggleColorMode} from "./component/theme";
import {isMobile} from "./constant";
import "./index.css";
import ResponsiveDrawer from "./ResponsiveDrawer";
import {withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {DataStore} from "@aws-amplify/datastore";
import { Hub } from "aws-amplify";

function App() {

    Hub.listen('auth', async (data) => {
        if (data.payload.event === 'signOut') {
            await DataStore.clear();
        }
        if (data.payload.event === 'signIn') {
            await DataStore.clear();
        }
    });

    return (
        <React.StrictMode>
            <StyledEngineProvider injectFirst>
                <ToggleColorMode>
                    <BrowserRouter>
                        <Routes>
                            {isMobile ? (
                                <Route
                                    path="*"
                                    element={
                                        <ResponsiveDrawer
                                            children={<Main title={""} view={<MobileViewText/>}/>}
                                            appbar={<Header shown={true}/>}
                                        />
                                    }
                                ></Route>
                            ) : (
                                <React.Fragment>
                                    <Route
                                        path="/"
                                        element={
                                            <ResponsiveDrawer
                                                children={
                                                    <Main title={""} view={<ReservationForm/>}/>
                                                }
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/reservation"
                                        element={
                                            <ResponsiveDrawer
                                                children={
                                                    <Main title={""} view={<ReservationForm/>}/>
                                                }
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/booking"
                                        element={
                                            <ResponsiveDrawer
                                                children={
                                                    <Main title={""} view={<Booking/>}/>
                                                }
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/lead"
                                        element={
                                            <ResponsiveDrawer
                                                children={
                                                    <Main title={""} view={<TempPlaceHolder/>}/>
                                                }
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/checkin"
                                        element={
                                            <ResponsiveDrawer
                                                children={
                                                    <Main title={""} view={<TempPlaceHolder/>}/>
                                                }
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/checkout"
                                        element={
                                            <ResponsiveDrawer
                                                children={
                                                    <Main title={""} view={<TempPlaceHolder/>}/>
                                                }
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/customer"
                                        element={
                                            <ResponsiveDrawer
                                                children={<Main title={""} view={<Customer/>}/>}
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/membership"
                                        element={
                                            <ResponsiveDrawer
                                                children={<Main title={""} view={<Membership/>}/>}
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/pickup"
                                        element={
                                            <ResponsiveDrawer
                                                children={
                                                    <Main title={""} view={<Pickup />}/>
                                                }
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/room"
                                        element={
                                            <ResponsiveDrawer
                                                children={<Main title={""} view={<Room/>}/>}
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/car"
                                        element={
                                            <ResponsiveDrawer
                                                children={<Main title={""} view={<Car/>}/>}
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/price"
                                        element={
                                            <ResponsiveDrawer
                                                children={
                                                    <Main title={""} view={<SeasonalPriceTable/>}/>
                                                }
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/move"
                                        element={
                                            <ResponsiveDrawer
                                                children={
                                                    <Main title={""} view={<TempPlaceHolder/>}/>
                                                }
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/revenue"
                                        element={
                                            <ResponsiveDrawer
                                                children={
                                                    <Main title={""} view={<Revenue />}/>
                                                }
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>

                                    <Route
                                        path="/occupancy"
                                        element={
                                            <ResponsiveDrawer
                                                children={
                                                    <Main title={""} view={<RoomOccupancy/>}/>
                                                }
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/calc"
                                        element={
                                            <ResponsiveDrawer
                                                children={
                                                    <Main title={""} view={<Calc/>}/>
                                                }
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>


                                    <Route
                                        path="/setting"
                                        element={
                                            <ResponsiveDrawer
                                                children={
                                                    <Main title={""} view={<TempPlaceHolder/>}/>
                                                }
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="*"
                                        element={
                                            <ResponsiveDrawer
                                                children={
                                                    <Main
                                                        title={""}
                                                        view={<Page404/>}
                                                        hideChooseMarker
                                                    />
                                                }
                                                appbar={<Header shown={true}/>}
                                            />
                                        }
                                    ></Route>
                                </React.Fragment>
                            )}
                        </Routes>
                    </BrowserRouter>
                </ToggleColorMode>
            </StyledEngineProvider>
        </React.StrictMode>
    );
}

export default withAuthenticator(App);


    



