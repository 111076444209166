import {Autocomplete, Button, Chip, Paper, Stack, TextField,} from "@mui/material";
// import { API, graphqlOperation } from "@aws-amplify/datastore";
import {DataStore} from '@aws-amplify/datastore';
import {BedType, Rooms, RoomType} from '../../models';

import * as React from "react";


// import { createRooms } from "../../graphql/mutations";

interface Props {
    selectedRow: any;
    rows: any;
    cleanSelected: () => void;
    onClose: () => void;
}

export default function CreateRoom(props: Props) {
    const [roomID, setRoomID] = React.useState<string>("");   // privte key for only Unique
    const [roomNumber, setRoomNumber] = React.useState<string>("");
    const [roomType, setRoomType] = React.useState<RoomType>(RoomType.MODERN_DELUXE);
    const [bedType, setBedType] = React.useState<BedType>(BedType.TWIN);
    const [roomMax, setRoomMax] = React.useState<number>(1);
    const [roomRemarks, setRoomRemarks] = React.useState<string>("");
    const [isNeedRoomNumber, setIsNeedRoomNumber] = React.useState<boolean>(false);
    const [isDisableNum, setIsDisableNum] = React.useState<boolean>(false) // for only update 


    React.useEffect(() => {
        // addRooms();
        if (props.selectedRow) {
            setIsDisableNum(true)
            setRoomID(props.selectedRow.id);
            setRoomNumber(props.selectedRow.roomNumber);
            setRoomMax(props.selectedRow.maxPerson);
            setRoomType(props.selectedRow.roomType);
            setRoomRemarks(props.selectedRow.Remarks);
            setBedType(props.selectedRow.bedType);
        } else {
            setIsDisableNum(false)
            setRoomID("");
            setRoomNumber("")
            setRoomMax(2);
            setRoomType(RoomType.MODERN_DELUXE);
            setRoomRemarks("");
            setBedType(BedType.TWIN);
        }
    }, [props.selectedRow]);

    const handleSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log("clicke save", props.rows)
        if (roomNumber && roomID) {
            if (props.rows.find((row: { roomNumber: string, id: string }) => (row.roomNumber === roomNumber) && (row.id === roomID))) {
                const original = await DataStore.query(Rooms, roomID)

                try {
                    if (original === undefined) new Error("Something went wrong! maybe no ID or other.")
                    if (original !== undefined) {
                        await DataStore.save(
                            Rooms.copyOf(original, updated => {
                                updated.roomNumber = roomNumber
                                updated.bedType = bedType
                                updated.maxPerson = roomMax
                                updated.roomType = roomType
                                updated.Remarks = roomRemarks
                            })
                        )
                        console.log("Updated Done!")
                    }
                } catch (err) {
                    console.log("UPDATED_ERR:", err)
                }
            }

            setRoomID("");
            setRoomNumber("")
            setRoomMax(1);
            setRoomType(RoomType.MODERN_DELUXE);
            setRoomRemarks("");
            setBedType(BedType.TWIN);
            props.onClose();
        } else {
            setIsNeedRoomNumber(true);
        }


        if (roomNumber && !roomID) {  // create
            console.log("Created Room", roomNumber)

            try {
                await DataStore.save(
                    new Rooms({
                        roomNumber: roomNumber,
                        roomType: roomType,
                        bedType: bedType,
                        maxPerson: roomMax,
                        Zone: 1020,
                        Condition: true,
                        Remarks: roomRemarks
                    })
                )
                console.log("Create Done!")
            } catch (err) {
                console.log("CREATE_ERR:", err)
            }

            setRoomID("");
            setRoomNumber("")
            setRoomMax(1);
            setRoomType(RoomType.MODERN_DELUXE);
            setRoomRemarks("");
            setBedType(BedType.TWIN);
            props.onClose();
        } else {
            setIsNeedRoomNumber(true);
        }
    };

    const handleCancel = (event?: React.MouseEvent<HTMLButtonElement>) => {
        setRoomID("");
        setRoomNumber("")
        setRoomMax(1);
        setRoomType(RoomType.MODERN_DELUXE);
        setRoomRemarks("");
        setBedType(BedType.TWIN);
        props.onClose();
    };

    const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsNeedRoomNumber(false);
        setRoomNumber(event.target.value.toUpperCase());
    };

    const handleChangeType = (
        event: React.SyntheticEvent,
        value: string | null
    ): void => {
        if (value) setRoomType(value as RoomType);
        console.log(value);
    };

    const handleChangeBedType = (
        event: React.SyntheticEvent,
        value: string | null
    ): void => {
        if (value) setBedType(value as BedType);
        console.log(value);
    };

    const handleChangeMax = (
        event: React.SyntheticEvent,
        value: string | null
    ) => {
        if (value) setRoomMax(+value);
        console.log(value);
    };

    const handleChangeRemarks = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRoomRemarks(event.target.value);
    };

    return (
        <Stack spacing={2} component={Paper} p={2} sx={{width: "80%"}}>
            <TextField
                disabled={isDisableNum}
                id="filled-basic"
                label="Room Number"
                variant="standard"
                size="small"
                value={roomNumber}
                autoComplete="off"
                InputLabelProps={{shrink: true}}
                onChange={handleChangeNumber}
                required={true}
                error={isNeedRoomNumber}
                helperText={isNeedRoomNumber ? "Need Number" : ""}
            />

            <Autocomplete
                value={roomType}
                onChange={handleChangeType}
                id="size-small-filled"
                size="small"
                options={roomTypeOptions}
                getOptionLabel={(option) => option}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option}
                            size="small"
                            {...getTagProps({index})}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Room Type"
                        placeholder="Room Type"
                        size="small"
                        InputLabelProps={{shrink: true}}
                        required
                    />
                )}
            />

            <Autocomplete
                value={bedType}
                onChange={handleChangeBedType}
                id="size-small-filled"
                size="small"
                options={bedTypeOptions}
                getOptionLabel={(option) => option}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option}
                            size="small"
                            {...getTagProps({index})}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Bed Type"
                        placeholder="Twin/Doulbe"
                        size="small"
                        InputLabelProps={{shrink: true}}
                        required
                    />
                )}
            />

            <Autocomplete
                value={roomMax.toString()}
                id="size-small-filled"
                size="small"
                onChange={handleChangeMax}
                options={roomMaxOptions}
                getOptionLabel={(option) => option}
                defaultValue={roomMaxOptions[13]}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option}
                            size="small"
                            {...getTagProps({index})}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        size="small"
                        label="Max Person"
                        placeholder="Max Person"
                        InputLabelProps={{shrink: true}}
                        required
                    />
                )}
            />

            <TextField
                id="standard-basic"
                label="Remarks"
                variant="standard"
                size="small"
                InputLabelProps={{shrink: true}}
                value={roomRemarks}
                onChange={handleChangeRemarks}
            />

            <TextField
                id="standard-basic"
                label="Room Name"
                variant="standard"
                size="small"
                InputLabelProps={{shrink: true}}
                value={`${roomNumber} ${roomType} (${bedType})`}
                disabled
            />
            <Stack direction="row" spacing={2} sx={{width: "100%"}}>
                <Button
                    variant="contained"
                    disabled={isNeedRoomNumber}
                    onClick={handleSave}
                    sx={{width: "10%"}}
                >
                    Save
                </Button>
                <Button onClick={handleCancel} sx={{width: "10%"}}>
                    Cancel
                </Button>
            </Stack>
        </Stack>
    );
}

const roomTypeOptions = [
    RoomType.MODERN_DELUXE,
    RoomType.STANDARD_SUPERIOR,
    RoomType.KOREAN_GRAND_GARDEN,
    RoomType.SUITE,
];

const bedTypeOptions = [BedType.TWIN, BedType.DOUBLE];

const roomMaxOptions = ["1", "2", "3", "4", "5"];