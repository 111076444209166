import React from 'react'
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    TextField,
} from '@mui/material'
import {DataStore, SortDirection} from "@aws-amplify/datastore";
import {Reservations, EtcCharge} from "../../models";



interface Props {
    resv: Reservations|null
    etc: {item?: string|null, price?: number|null}|null
    setEtc: (c: {item?: string|null, price?: number|null}|null) => void
}

const Single = (props: Props) => {
    const {resv, etc, setEtc} = props
    const [item, setItem] = React.useState<string>("")
    const [price, setPrice] = React.useState<number>(0)
    
    React.useEffect(() => {
        setItem("")
        setPrice(0)
        if (resv !== null) {
            let subscription = DataStore
                .observeQuery(EtcCharge, (c) => c.reservationsID("eq", resv.id), {
                    sort: c => c.createdAt(SortDirection.DESCENDING)
                })
                .subscribe((snapshot) => {
                    let {items} = snapshot
                
                    console.log(items)
                    setItem(items[0].Items)
                    setPrice(items[0].Price)
                })
            return () => subscription.unsubscribe()
        }
    }, [resv])
    
    
    const handleChangeItem = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem(event.target.value)
        setEtc({...etc, item: event.target.value})
    }
    
    const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isNaN(+event.target.value)) {
            setPrice(+event.target.value)
            setEtc({...etc, price: +event.target.value})
        }
    }
    
    
    return <Paper>
        <Toolbar>
            <Typography variant="h5">Etc Charge</Typography>
        </Toolbar>

        <TableContainer>
            <Table
                size={"small"}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell>Price</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                        <TableCell>
                            <TextField 
                                size="small"
                                label="Item"
                                placeholder="Item"
                                value={item}
                                onChange={handleChangeItem}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField 
                                size="small"
                                label="Item"
                                placeholder="Price"
                                value={price}
                                InputLabelProps={{shrink: true}}
                                onChange={handleChangePrice}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
}


export default Single