import {IconButton, InputBase} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React from "react";

interface Props {
    total: number;
    page: number;
    setPage: any;
    rowsPerPage: number;
    setRowsPerPage: any;
}

export default function CustomPagination(props: Props) {
    const totalPage = Math.round(props.total / props.rowsPerPage);
    const [value, setValue] = React.useState<number | null>(1);

    const onKeydown = (e: any) => {
        if (e.code === "Backspace") {
            setValue(null);
        }
        console.log("here", e.target.value);

        if (e.code === "Enter") {
            if (parseInt(e.target.value) * props.rowsPerPage <= props.total) {
                props.setPage(parseInt(e.target.value) - 1);
            } else {
                props.setPage(totalPage - 1);
                setValue(totalPage);
            }
        }
    };
    return (
        <div>
            <IconButton
                disabled={props.page === 0}
                edge="end"
                onClick={() => {
                    setValue(props.page);
                    props.setPage(props.page - 1);
                }}
            >
                <NavigateBeforeIcon/>
            </IconButton>
            <InputBase
                value={value}
                sx={{mx: 2, flex: 1, paddingLeft: 3}}
                style={{
                    width: "60px",
                    height: "30px",
                    borderRadius: "5%",
                    outline: "none",
                    border: "1px solid #ddd",
                }}
                onKeyDown={(e: any) => onKeydown(e)}
            />
            / &nbsp;<span style={{marginRight: "4px"}}>{totalPage}</span>
            <IconButton
                disabled={props.page === totalPage - 1}
                edge="end"
                onClick={() => {
                    setValue(props.page + 2);
                    props.setPage(props.page + 1);
                }}
            >
                <NavigateNextIcon/>
            </IconButton>
        </div>
    );
}
