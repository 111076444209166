import React from 'react'
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    TextField
} from '@mui/material'
import {DataStore, SortDirection} from "@aws-amplify/datastore";
import {Reservations, SingleCharge} from "../../models";


interface Props {
    resv: Reservations | null
    single: {days?: string|null, unit_price?: number|null, price?: number}|null
    setSingle: (state: {days?: string|null, unit_price?: number|null, price?: number}|null) => void
}

const Single = (props: Props) => {
    const {resv, single, setSingle} = props
    const [days, setDays] = React.useState<string>()
    const [unit, setUnit] = React.useState<number|null|undefined>(0)
    const [price, setPrice] = React.useState<number|null|undefined>(0)
    
    React.useEffect(() => {
        setDays("")
        setUnit(0)
        setPrice(0)

        if (resv !== null) {
            let subscription = DataStore
                .observeQuery(SingleCharge, (c) => c.reservationsID("eq", resv.id), {
                    sort: c => c.createdAt(SortDirection.DESCENDING)
                })
                .subscribe((snapshot) => {
                    let {items} = snapshot
                    setDays(resv.CashFlow?.totalDuration.toString())
                    setUnit(items[0].UnitPrice)
                    setPrice(items[0].Price)
                })
            return () => subscription.unsubscribe()
        }
    }, [resv])
    
    
    const handleChangeDays = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!isNaN(+event.target.value)) {
            setDays(event.target.value)
            setSingle({...single, days: event.target.value})
        }
    }
    
    const handleChangeUnit = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!isNaN(+event.target.value)) {
            setUnit(+event.target.value)
            setSingle({...single, unit_price: +event.target.value})
        }
    }

    const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!isNaN(+event.target.value)) {
            setPrice(+event.target.value)
            setSingle({...single, price: +event.target.value})
        }
    }
    

    return <Paper>
        <Toolbar>
            <Typography variant="h5">Single Charge</Typography>
        </Toolbar>

        <TableContainer>
            <Table
                size={"small"}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Days</TableCell>
                        <TableCell>Unit Price</TableCell>
                        <TableCell>Price</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                        <TableCell>
                            <TextField 
                                size="small"
                                label="Total days"
                                value={days}
                                onChange={handleChangeDays}
                                InputLabelProps={{shrink: true}}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField 
                                size="small"
                                label="Unit Price"
                                value={unit}
                                onChange={handleChangeUnit}
                                InputLabelProps={{shrink: true}}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField 
                                size="small"
                                label="Price"
                                value={price}
                                onChange={handleChangePrice}
                                InputLabelProps={{shrink: true}}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
}


export default Single