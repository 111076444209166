import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import Grid from '@mui/material/Grid';
import CreateCar from './CreateCar';

import {AppBar, Dialog, DialogContent, InputAdornment, Stack, TextField,} from '@mui/material';
import {DataStore, SortDirection} from 'aws-amplify';
import {Cars} from '../../models';
import CarCardLayout from '../CardLayout/CarCardLayout';


interface Data {
    id: string
    carNumber: string
    maxPerson: number
    Condition: boolean
    Remarks: string
}


export default function Car() {
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [cars, setCars] = React.useState<Cars[]>([])
    const [open, setOpen] = React.useState<boolean>(false);

    const [search, setSearch] = React.useState<string>("");
    const [filter, setFilter] = React.useState<string>("");


    const handleModalOpen = () => {
        setOpen(true);
    };

    const handleModalClose = () => {
        setOpen(false);
        if (selected) {
            handleCleanSelected();
        }
    };


    React.useEffect(() => {
        const subscription: any = DataStore.observeQuery(Cars).subscribe((snapshot) => setCars(snapshot.items));

        return () => subscription.unsubscribe();
    }, [search]);


    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    // const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const isSelected = selected.length > 0 ? true : false;
    const selectedRow = cars.find(row => row.id === selected[0])

    const handleCleanSelected = () => setSelected([])

    return (

        <Box sx={{width: '100%'}}>
            <Grid container spacing={2}>
                {/* <Grid item xs={4}>
                    <CreateCar selectedRow={selectedRow} rows={rows} cleanSelected={handleCleanSelected} />
                </Grid> */}
                <Grid item xs={12}>
                    <Paper sx={{py: 2, px: 2}}>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h5">Cars</Typography>

                            {/* Search */}
                            <TextField
                                type={'text'}
                                autoComplete={'off'}
                                id="outlined-end-adornment"
                                placeholder="Room search by ID ..."
                                sx={{
                                    width: "55%",
                                    "& .MuiInputBase-root": {
                                        height: 40,
                                    },
                                    marginX: 4,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e: any) => setSearch(e.target.value)}
                            />

                            <Button
                                variant="outlined"
                                startIcon={<AddIcon/>}
                                onClick={handleModalOpen}
                            >
                                Add New Car
                            </Button>
                        </Stack>

                        <CarCardLayout
                            data={cars}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleModalOpen={handleModalOpen}
                            handleClick={handleClick}/>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={cars.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    {/* <FormControlLabel
                        control={<Switch checked={dense} onChange={handleChangeDense}/>}
                        label="Dense padding"
                    /> */}
                </Grid>
            </Grid>
            <Dialog
                fullScreen
                open={open}
                onClose={handleModalClose}
                aria-labelledby="responsive-dialog-title"
            >
                <AppBar sx={{position: "relative", background: "#1A5276"}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleModalClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            {isSelected ? 'Car Update' : 'Car Create'}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{display: "flex", justifyContent: "center"}}>
                    <CreateCar selectedRow={selectedRow} rows={cars} cleanSelected={handleCleanSelected}
                               onClose={handleModalClose}/>
                </DialogContent>
            </Dialog>
        </Box>

    );
}
