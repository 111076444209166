import React from 'react'
import {Autocomplete, Chip, Grid, TextField, Stack, Button} from '@mui/material'

import FeeCalc from './FeeCalc'
import Single from './Single'
import Etc from './Etc'
import Commission from './Commission'
import TranCheckIn from './TranCheckIn'
import TranCheckOut from './TranCheckOut'

import {DataStore} from "@aws-amplify/datastore";
import {Reservations, ReservationsRooms, CashFlow, SeasonalPrice, EtcCharge, SingleCharge, TransportationCheckIn, TransportationCheckOut} from "../../models";


const Calc = () => {
    const [reservation, setReservation] = React.useState<Reservations | null>(null)
    // const [resvOptions, setResvOptions] = React.useState<Reservations[]>([])
    const [seasonalPrices, setSeasonalPrices] = React.useState<SeasonalPrice[]>([])
    
    const [etc, setEtc] = React.useState<{item?: string|null, price?: number|null}|null>(null)
    const [single, setSingle] = React.useState<{days?: string|null, unit_price?: number|null, price?: number}|null>(null)
    const [transportIn, setTransportIn] = React.useState<{krw?: number|null, thb?: number|null, usd?: number|null}|null>(null)
    const [transportOut, setTransportOut] = React.useState<{krw?: number|null, thb?: number|null, usd?: number|null}|null>(null)
    
    const [remark, setRemark] = React.useState<string|null|undefined>("")
    const [resvRoom, setResvRoom] = React.useState<ReservationsRooms[]>([])

    React.useEffect(() => {
        // must use clean up effect to avoid memory leak after the component unmount.
        // const fetchReservations: any = DataStore.observeQuery(Reservations).subscribe((snapshot) => setResvOptions(snapshot.items));
        const fetchSeasonal: any = DataStore.observeQuery(SeasonalPrice).subscribe((snapshot) => setSeasonalPrices(snapshot.items));
        const fetchReservationsRooms: any = DataStore.observeQuery(ReservationsRooms).subscribe((snapshot) => {
            const {items} = snapshot
            setResvRoom(items.filter((rero => (
                !rero.reservations.isCheckOut
            ))))
        });
        return () => {
            // fetchReservations.unsubscribe();
            fetchSeasonal.unsubscribe();
            fetchReservationsRooms.unsubscribe()
        }
    }, [])
    
    React.useEffect(() => {
        setRemark(reservation?.CashFlow?.Remarks)
        console.log(reservation)
    }, [reservation])
    

    const handleChangeReservation = (event: React.SyntheticEvent, value: ReservationsRooms | null): void => {
        if (value) setReservation(value.reservations)
    }
    
    const handleChangeRemark = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRemark(event.target.value)
    }
    
    const handleClickChange = async (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log("etc", etc)
        console.log("single", single)
        console.log("transport in", transportIn)
        console.log("transport out", transportOut)
        if (reservation !== null) {
            // create Etc Charge
            if (etc) {
                const newEtcCharge = await DataStore.save(
                    new EtcCharge({
                        reservationsID: reservation.id,
                        Items: etc.item || "",
                        Price: etc.price || 0,
                    })
                )
                setEtc(null)
                console.log("Success create Etc Charge", newEtcCharge)
            }
            
            // create Single Charge
            if (single) {
                const newSingleCharge = await DataStore.save(
                    new SingleCharge({
                        reservationsID: reservation.id,
                        Days: single.days || "0",
                        UnitPrice: single.unit_price,
                        Price: single.price,
                    })
                )
                setSingle(null)
                console.log("Success create Single Charge", newSingleCharge)
            }
            
            // create Transportation Check In
            if (transportIn) {
                const newTransportIn = await DataStore.save(
                    new TransportationCheckIn({
                        reservationsID: reservation.id,
                        USD: transportIn.usd,
                        KOR: transportIn.krw,
                        THB: transportIn.thb
                    })
                )
                console.log("Success create Transport In Charge", newTransportIn)
            }
            
            // create Transportation Check In
            if (transportOut) {
                const newTransportOut = await DataStore.save(
                    new TransportationCheckOut({
                        reservationsID: reservation.id,
                        USD: transportOut.usd,
                        KOR: transportOut.krw,
                        THB: transportOut.thb
                    })
                )
                console.log("Success create Transport Out Charge", newTransportOut)
            }
            
            
            // update CashFlow Remark
            if (reservation.CashFlow) {
                const cashFlowOriginal = await DataStore.query(CashFlow, reservation.CashFlow.id)
                if (cashFlowOriginal !== undefined) {
                    const newCashFlow = await DataStore.save(
                        CashFlow.copyOf(cashFlowOriginal, updated => {
                            updated.Remarks = remark
                        })
                    )
                    console.log("Success update remark", reservation.CashFlow.id, newCashFlow)
                }
            }
        }
    }

    const getResvRoom = (id?: string) => {
        const current = resvRoom.find(c => c.reservations.id === id)
        if (current) return current
        return null
    }

    return <Grid container gap={2}>
        <Grid item xs={16}>
            <Autocomplete
                value={getResvRoom(reservation?.id)}
                onChange={handleChangeReservation}
                id="size-small-filled"
                size="small"
                options={resvRoom}
                //getOptionLabel={(option) => `${option.id.slice(-4)} ${option.Rooms?.roomNumber}`}
                getOptionLabel={(option) => `${option.reservations.id.slice(-4)} ${option.rooms.roomNumber}`}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            //label={`${option.id.slice(-4)} ${option.Rooms?.roomNumber}`}
                            label={`${option.reservations.id.slice(-4)} ${option.rooms.roomNumber}`}
                            size="small"
                            {...getTagProps({index})}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Reservation"
                        placeholder="MADI-00000000 (ROOM) CUSTOMER"
                        size="small"
                        InputLabelProps={{shrink: true}}
                        required
                    />
                )}
            />
        </Grid>

        <Grid item xs={5}>
            <FeeCalc resv={reservation} seasonalPrices={seasonalPrices}/>
        </Grid>

        <Grid item xs={3}>
            <Single resv={reservation} single={single} setSingle={setSingle} />
        </Grid>

        <Grid item xs={3}>
            <Etc resv={reservation} etc={etc} setEtc={setEtc}/>
        </Grid>

        <Grid item xs={5}>
            <Commission/>
        </Grid>

        <Grid item xs={3}>
            <TranCheckIn resv={reservation} transportIn={transportIn} setTransportIn={setTransportIn} />
        </Grid>

        <Grid item xs={3}>
            <TranCheckOut resv={reservation} transportOut={transportOut} setTransportOut={setTransportOut} />
        </Grid>
        
        <Grid item xs={12}>
            <Stack spacing={2}>
                <TextField 
                    label="Remark"
                    placeholder="Remark ..."
                    multiline
                    rows={2}
                    sx={{width: 520}}
                    value={remark}
                    onChange={handleChangeRemark}
                />
                
                <Stack 
                    direction="row"
                    alignItems="center"
                    justifyContent="start"
                >
                    <Button 
                        variant="contained"
                        onClick={handleClickChange}
                    >Charge</Button>
                </Stack>
            </Stack>
        </Grid>
    </Grid>
}


export default Calc
