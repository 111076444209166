import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TablePagination,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";

import * as React from 'react';

import CustomerCardLayout from '../CardLayout/CustomerCardLayout';
import CreateCustomer from './createCustomer';

import { DataStore, SortDirection, Predicates } from "aws-amplify";
import { Customers } from "../../models";


interface Data {
    firstName: string
    lastName: string
    Email?: string // unique
    Passport: string
    Phone: string
    Birthday: string
    Country: string
    Gender: string
}


export default function RoomTable() {
    const [orderBy, setOrderBy] = React.useState<keyof Data>('firstName');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [customers, setCustomers] = React.useState<Customers[]>([])

    const [searchWord, setSearchWord] = React.useState<string>("")

    const [open, setOpen] = React.useState<boolean>(false);

    console.log(customers)

    const handleModalOpen = () => {
        setOpen(true);
    };

    const handleModalClose = () => {
        setOpen(false);
        if (selected) {
            handleCleanSelected();
        }
    };

    function searchCustomers(search: string) {
        try {


        } catch (err) {
            console.log('error fetching filter rooms')
        }
    }


    React.useEffect(() => {

        const subscription: any = DataStore.observeQuery(Customers, Predicates.ALL, {
            sort: s => s.createdAt(SortDirection.DESCENDING)
        }).subscribe((snapshot) => {
            // console.log(snapshot.items)
            setCustomers(snapshot.items.filter(c => (
                (c.firstName+" "+c.lastName).indexOf(searchWord) !== -1
            )))
        });

        return () => subscription.unsubscribe();

    }, [searchWord]);


    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    console.log(searchWord)

    // const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const isSelected = selected.length > 0 ? true : false;

    // Avoid a layout jump when reaching the last page with empty rows.


    const selectedRow = customers.find(row => row.Passport === selected[0])

    const handleCleanSelected = () => setSelected([])


    return (
        <Paper sx={{py: 5, px: 2}}>
            <Box sx={{width: "100%"}}>
                <Grid
                    container
                    spacing={3}
                    sx={{
                        mb: 3,
                    }}
                >
                    <Grid item xs={12}>
                        <Box sx={{display: "flex"}}>
                            <Typography variant="h5">Customers</Typography>
                            <TextField
                                autoComplete="off"
                                id="outlined-end-adornment"
                                placeholder="Customer search by name ..."
                                sx={{
                                    width: "40%",
                                    "& .MuiInputBase-root": {
                                        height: 40,
                                    },
                                    marginX: 4,
                                }}
                                type="text"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {!searchWord ? <SearchIcon/> : 
                                                <IconButton onClick={() => setSearchWord("")}>
                                                    <CloseIcon />
                                                </IconButton>
                                            }
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e: any) => setSearchWord(e.target.value)}
                            />
                            <Button
                                variant="outlined"
                                startIcon={<AddIcon/>}
                                onClick={handleModalOpen}
                            >
                                Add Customer
                            </Button>
                        </Box>
                        <CustomerCardLayout
                            data={customers}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleModalOpen={handleModalOpen}
                            handleClick={handleClick}
                        />
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={customers.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleModalClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <AppBar sx={{position: "relative", bgcolor: "appBar"}}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleModalClose}
                                aria-label="close"
                            >
                                <CloseIcon/>
                            </IconButton>
                            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                                {isSelected ? 'Customer Update' : 'Customer Create'}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent sx={{display: "flex", justifyContent: "center"}}>
                        <CreateCustomer
                            selectedRow={selectedRow}
                            rows={customers}
                            cleanSelected={handleCleanSelected}
                            onClose={handleModalClose}
                        />
                    </DialogContent>
                </Dialog>
            </Box>
        </Paper>


    );
}