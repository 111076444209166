import React from 'react'
import {
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography
} from '@mui/material'
import dayjs from 'dayjs'
import {Reservations, SeasonalPrice} from "../../models";

import {getDatesInRange} from "../../constant";


interface Props {
    resv: Reservations | null
    seasonalPrices: SeasonalPrice[]
}

const FeeCalc = (props: Props) => {
    const {resv, seasonalPrices} = props
    const [eachPrice, setEachPrice] = React.useState<number[]>([0, 0])

    let total = 0

    // let thinCashFlow = useQuery(query("cashflow"))
    // let thinReservation = useQuerySingleResult(query("reservations").where("id", resvId))

    /*
    const getDays = async (uuid?: string) => {
        let cashFlow = await DataStore.query(CashFlow)
        let data = cashFlow?.find((cash: {id: string}) => cash.id === resvid)
        if (data) return data.totalDuration
        return 0
    }
    */

    const getData = (month: string) => {
        console.log("Run getData")
        let price = seasonalPrices.find((season: SeasonalPrice) => season.Months === month.toUpperCase())
        if (price && resv) {
            if (resv.isGeneral) return price.General
            if (resv.isTour) return price.Tour
            if (resv.isMembership) return price.Membership
        }
        return 0
    }

    const handleChangeEachPrice = (event: React.ChangeEvent<HTMLInputElement>, which: number) => {
        let value = +event.target.value
        let newValue = [...eachPrice]
        if (!isNaN(value)) {
            newValue[which] = value
            setEachPrice(newValue)
        }
    }

    let to = dayjs(getDatesInRange(resv?.checkIn, resv?.checkOut).filter(date => date.getMonth() === dayjs(resv?.checkIn).get("month")).slice(-1)[0])
    let rows: { from?: string | null, to?: string | null, days: number, Price: number }[] = [
        {
            from: resv?.checkIn,
            to: resv?.checkIn ? to.format("YYYY-MM-DD") : "-",
            days: resv?.checkIn ? (getDatesInRange(resv?.checkIn, to).length) : 0,
            Price: !(eachPrice[0])
                ? (getData(to.format("MMMM")))
                : (getDatesInRange(resv?.checkIn, to).length - 1) * eachPrice[0]
        }
    ]
    total += (eachPrice[0])
        ? (getDatesInRange(resv?.checkIn, to).length - 1) * eachPrice[0]
        : (getData(to.format("MMMM")))

    let idx = 0
    if (dayjs(resv?.checkIn).get("month") !== dayjs(resv?.checkOut).get('month')) {
        let from = dayjs(getDatesInRange(resv?.checkIn, resv?.checkOut).filter(date => (date.getMonth()) === dayjs(resv?.checkOut).get("month"))[0])
        idx += 1
        rows.push({
            from: from.format("YYYY-MM-DD"),
            to: resv?.checkOut,
            days: getDatesInRange(from, resv?.checkOut).length,
            Price: !(eachPrice[idx])
                ? (getData(from.format("MMMM")))
                : (getDatesInRange(from, resv?.checkOut).length - 1) * eachPrice[idx]
        })
        total += (eachPrice[idx])
            ? (getDatesInRange(from, resv?.checkOut).length - 1) * eachPrice[idx]
            : (getData(from.format("MMMM")))
    }


    return <Paper>
        <Toolbar>
            <Typography variant="h5">Fee Calculate</Typography>
        </Toolbar>

        <TableContainer>
            <Table
                size={"small"}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>From Date</TableCell>
                        <TableCell>To Date</TableCell>
                        <TableCell>Days</TableCell>
                        <TableCell>price</TableCell>
                        <TableCell>Price</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{row.from}</TableCell>
                            <TableCell>{row.to}</TableCell>
                            <TableCell>{row.days}</TableCell>
                            <TableCell>{row.Price}</TableCell>

                            <TableCell>
                                <TextField
                                    size="small"
                                    label="price"
                                    value={eachPrice[index]}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeEachPrice(event, index)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Stack direction="row" alignItems="center" justifyContent="end" spacing={2} p={2}>
                <Typography>Total:</Typography>
                <Typography>{total}</Typography>
            </Stack>
        </TableContainer>
    </Paper>
}


export default FeeCalc