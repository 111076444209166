import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {Checkbox, Chip, FormControlLabel} from "@mui/material";
import * as React from "react";

import {DataStore} from 'aws-amplify';
import {Cars, Rooms, ReservationsRooms, ReservationsCars, ReservationsCondition} from '../../models';
import {TimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

import Date from "./Date";
import dayjs, {Dayjs} from "dayjs";

export default function StepOne({
                                    personCount,
                                    checkInDate,
                                    checkOutDate,
                                    room,
                                    car,
                                    setPersonCount,
                                    setCheckInDate,
                                    setCheckOutDate,
                                    setRoom,
                                    setCar,
                                    priceType,
                                    setPriceType,

                                    isEmptyRoom, setIsEmptyRoom,
                                    isEmptyCar, setIsEmptyCar,
                                    arrivalAirlines,//: string[];
                                    arrivalDates,//: Dayjs[];
                                    arrivalTimes,//: Dayjs[];
                                    departureAirlines,//: string[];
                                    departureDates,//: Dayjs[];
                                    departureTimes, //: Dayjs[];
                                    setArrivalAirlines, // : (value: string[]) => void;
                                    setArrivalDates,  //: (value: Dayjs[]) => void;
                                    setArrivalTimes, //: (value: Dayjs[]) => void;
                                    setDepartureAirlines, //: (value: string[]) => void;
                                    setDepartureDates, //: (value: Dayjs[]) => void;
                                    setDepartureTimes,  //: (value: Dayjs[]) => void;
                                }: any) {

    /*
    const availableRoom: { label: string; roomID: string }[] = [
        {label: "", roomID: ""},
    ];
    const availableCar: { label: string; carID: string }[] = [
        {label: "", carID: ""},
    ];
    */

    const [general, setGeneral] = React.useState(priceType === "general");
    const [tour, setTour] = React.useState(priceType === "tour");
    const [membership, setMembership] = React.useState(
        priceType === "membership"
    );

    // Options
    const [availableRoom, setAvailableRoom] = React.useState<Rooms[]>([])
    const [availableCar, setAvailableCar] = React.useState<Cars[]>([])
    const [reservationsRooms, setReservationsRooms] = React.useState<ReservationsRooms[]>([])

    React.useEffect(() => {
        // must use clean up effect to avoid memory leak after the component unmount.
        const fetchCars: any = DataStore.observeQuery(Cars).subscribe((snapshot) => setAvailableCar(snapshot.items));
        const fetchRooms: any = DataStore.observeQuery(Rooms).subscribe((snapshot) => setAvailableRoom(snapshot.items));
        return () => fetchRooms.unsubscribe() && fetchCars.unsubscribe();
    }, [checkInDate])
    
    
    // avaliable room logic
    React.useEffect(() => {
        const fetchResvRooms: any = DataStore.observeQuery(ReservationsRooms).subscribe((snapshot) => {
            const had: string[] = snapshot
                .items
                .filter((resv: ReservationsRooms) => (
                    resv.reservations.Condition !== ReservationsCondition.CANCELLED
                ))
                .filter((resv: ReservationsRooms) => (
                    checkInDate.isBefore(dayjs(resv.reservations.checkOut))
                ))
                .map(resv => resv.rooms.id)
            setAvailableRoom((current) => current.filter(room => !had.includes(room.id)))
        });
        return () => fetchResvRooms.unsubscribe()
    }, [checkInDate])

    // avaliable car logic
    React.useEffect(() => {
        const fetchResvCars: any = DataStore.observeQuery(ReservationsCars).subscribe((snapshot) => {
            const had = snapshot
                .items
                .filter((resv: ReservationsCars) => (
                    resv.reservations.Condition !== ReservationsCondition.CANCELLED
                ))
                .filter((resv: ReservationsCars) => (
                    checkInDate.isBefore(dayjs(resv.reservations.checkOut))
                ))
                .map(resv => resv.cars.id)
            setAvailableCar((current) => current.filter(car => !had.includes(car.id)))
        });
        return () => fetchResvCars.unsubscribe()
    }, [checkInDate])

    const defaultRoom =
        room === null
            ? availableRoom[0]
            : availableRoom.filter((r) => r.id === room)[0];
    const defaultCar =
        car === null
            ? availableCar[0]
            : availableCar.filter((c) => c.id === car)[0];

    const handleClickCheck = (event: any) => {
        setPriceType(event.target.id);
        if (event.target.id === "tour") {
            setTour(true);
            setGeneral(false);
            setMembership(false);
        } else if (event.target.id === "membership") {
            setTour(false);
            setGeneral(false);
            setMembership(true);
        } else {
            setTour(false);
            setGeneral(true);
            setMembership(false);
        }
    };

    const onCheckInDateChange = (value: Dayjs) => {
        setCheckInDate(value);
        setCheckOutDate(dayjs(value).add(4, "day"));
    };

    const handleChangeRoom = (_: React.SyntheticEvent, newValue: Rooms | null) => {
        if (newValue) {
            setRoom(newValue)
            setIsEmptyRoom(false)
        }
    }


    const handleChangeCar = (_: React.SyntheticEvent, newValue: Cars | null) => {
        if (newValue) {
            setCar(newValue)
            setIsEmptyCar(false)
        }
    }
    
    // Flight time
    const handleChangeArrivalAirline = (value: string | null, who: number) => {
        if (value) {
            let newAirlines = [...arrivalAirlines];
            newAirlines[who] = value;

            setArrivalAirlines(newAirlines);
        }
    };

    const handleChangeArrivalDate = (value: Dayjs | null, who: number) => {
        if (value) {
            let newDates = [...arrivalDates];
            newDates[who] = value;

            setArrivalDates(newDates);
            setCheckInDate(value)
            setCheckOutDate(value.add(1, "day"))
        }
    };

    const handleChangeArrivalTime = (value: Dayjs | null, who: number) => {
        if (value) {
            let newTimes = [...arrivalTimes];
            newTimes[who] = value;

            setArrivalTimes(newTimes);
            
            if (+value.format("HH") <= 12) {
                setCheckInDate(arrivalDates[0].add(1, "day"))
                setCheckOutDate(arrivalDates[0].add(2, "day"))
            } else {
                setCheckInDate(arrivalDates[0])
                setCheckOutDate(arrivalDates[0].add(1, "day"))
            }
        }
    };

    const handleChangeDepartureAirline = (value: string | null, who: number) => {
        if (value) {
            let newAirlines = [...departureAirlines];
            newAirlines[who] = value;

            setDepartureAirlines(newAirlines);
        }
    };

    const handleChangeDepartureDate = (value: Dayjs | null, who: number) => {
        if (value) {
            let newDates = [...departureDates];
            newDates[who] = value;

            setDepartureDates(newDates);
            setCheckOutDate(value)
        }
    };

    const handleChangeDepartureTime = (value: Dayjs | null, who: number) => {
        if (value) {
            let newTimes = [...departureTimes];
            newTimes[who] = value;

            setDepartureTimes(newTimes);
            
            if (+value.format("HH") >= 18) {
                setCheckOutDate(departureDates[0].add(1, "day"))
            } else {
                setCheckOutDate(departureDates[0])
            }
        }
    };

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Date
                        required
                        label="CheckIn"
                        value={checkInDate}
                        onChange={(value: Dayjs) => onCheckInDateChange(value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Date
                        required
                        label="CheckOut"
                        value={checkOutDate}
                        minDate={dayjs(checkInDate).add(1, "day")}
                        onChange={(value: any) => {
                            setCheckOutDate(value);
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <TextField
                        inputProps={{style: {textTransform: "capitalize"}}}
                        required
                        id="arrivalAirline"
                        name="arrivalAirline"
                        label="Arrival Airline"
                        fullWidth
                        autoComplete="off"
                        variant="standard"
                        value={arrivalAirlines[0]}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeArrivalAirline(event.target.value, 0)
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label="Arrival Date"
                            value={arrivalDates[0]}
                            onChange={(value) => handleChangeArrivalDate(value, 0)}
                            inputFormat="YYYY-MM-DD"
                            renderInput={(parms) => <TextField {...parms} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="Arrival Time"
                            ampm={false}
                            value={arrivalTimes[0]}
                            onChange={(value) => handleChangeArrivalTime(value, 0)}
                            renderInput={(parms) => <TextField {...parms} />}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <TextField
                        inputProps={{style: {textTransform: "capitalize"}}}
                        required
                        id="departureAirline"
                        name="departureAirline"
                        label="Departure Airline"
                        fullWidth
                        autoComplete="off"
                        variant="standard"
                        value={departureAirlines[0]}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeDepartureAirline(event.target.value, 0)
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label="Departure Date"
                            value={departureDates[0]}
                            onChange={(value) => handleChangeDepartureDate(value, 0)}
                            inputFormat="YYYY-MM-DD"
                            renderInput={(parms) => <TextField {...parms} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="Departure Time"
                            ampm={false}
                            value={departureTimes[0]}
                            onChange={(value) => handleChangeDepartureTime(value, 0)}
                            renderInput={(parms) => <TextField {...parms} />}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        id="person"
                        options={people}
                        value={personCount}
                        onChange={(e, v) => (v ? setPersonCount(v) : v)}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                            option.label === value.label
                        }
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    label={option.label}
                                    size="small"
                                    {...getTagProps({index})}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params} label="How many person?" required/>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        id="room"
                        options={availableRoom}
                        value={room}
                        onChange={handleChangeRoom}
                        getOptionLabel={(option) => `${option.roomNumber} ${option.bedType}`}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    label={`${option.roomNumber} ${option.bedType}`}
                                    placeholder="Choose Room"
                                    size="small"
                                    {...getTagProps({index})}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params}
                                       label="Rooms"
                                       error={isEmptyRoom}
                                       helperText={isEmptyRoom && "Please Choose Room!"}
                                       required
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        id="car"
                        options={availableCar}
                        value={car}
                        onChange={handleChangeCar}
                        getOptionLabel={(option) => option.carNumber}
                        isOptionEqualToValue={(option, value) =>
                            option.carNumber === value?.carNumber
                        }
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    label={option.carNumber}
                                    placeholder="Choose Car"
                                    size="small"
                                    {...getTagProps({index})}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params}
                                       label="Golf Cart"
                                       required
                                       error={isEmptyCar}
                                       helperText={isEmptyCar && "Please Choose car!"}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="general"
                                checked={general}
                                onClick={handleClickCheck}
                            />
                        }
                        label="General"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox id="tour" checked={tour} onClick={handleClickCheck}/>
                        }
                        label="Tour"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="membership"
                                checked={membership}
                                onClick={handleClickCheck}
                            />
                        }
                        label="Member"
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

const people = [
    {label: "1 Adult", value: 0},
    {label: "2 Adults", value: 1},
];
