import {
    Avatar,
    Box,
    capitalize,
    Card,
    CardContent,
    Grid,
    IconButton,
    Link,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import {grey} from "@mui/material/colors";

interface Props {
    data: any;
    page: number;
    rowsPerPage: number;
    handleModalOpen: () => void;
    handleClick: any;
}

export default function CustomerCardLayout(props: Props) {
    const theme = useTheme()

    return (
        <Box
            sx={{
                mt: 5,
            }}
        >
            <Grid
                container
                spacing={2}
                sx={{
                    mb: 1,
                }}
            >
                {props.data.length ? (
                    props.data
                        .slice(
                            props.page * props.rowsPerPage,
                            props.page * props.rowsPerPage + props.rowsPerPage
                        )
                        .map((row: any, index: number) => {
                            const borderColor = row.is_member ? '#5FB404' : '#F3E2A9';

                            return (
                                <Grid item xs={3} key={index}>
                                    <Card sx={{
                                        minWidth: 275,
                                        // borderRadius: '8px',
                                        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                                        // borderLeft: `4px solid ${borderColor}`,
                                        bgcolor: theme.palette.mode === 'dark' ? '#0C090A' : "#fff",
                                    }}>
                                        <CardContent sx={{
                                            position: "relative"
                                        }}>
                                            <IconButton
                                                aria-label="settings"
                                                sx={{position: "absolute", top: 2, right: 2}}
                                                onClick={(event) => {
                                                    props.handleClick(event, row.Passport)
                                                    props.handleModalOpen();
                                                }}
                                            >
                                                <EditIcon sx={{fontSize: 16}}/>
                                            </IconButton>
                                            <Stack
                                                // direction="row"
                                                spacing={1}
                                                // sx={{ alignItems: "center" }}
                                            >
                                                <Typography
                                                    fontWeight="bold"
                                                    sx={{textAlign: 'center', color: '#6698FF'}}
                                                >
                                                    {`${capitalize(row.firstName)} ${capitalize(row.lastName)}`}
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{
                                                    fontSize: 11,
                                                    fontWeight: 'bold',
                                                    textAlign: 'center'
                                                }}>
                                                    {row.Country}
                                                </Typography>
                                                <Stack sx={{justifyContent: 'center', alignItems: 'center'}}>
                                                    <Avatar
                                                        sx={{
                                                            width: 45,
                                                            height: 45,
                                                            bgcolor: "#f2f2f2",
                                                            p: 1,
                                                        }}
                                                    >
                                                        <PersonIcon sx={{color: 'appBar'}}/>
                                                    </Avatar>
                                                </Stack>
                                                <Stack direction="row" spacing={1}
                                                       sx={{mt: 1, justifyContent: "center", alignItems: "center"}}>
                                                    <CreditCardIcon color="info"/>
                                                    <Typography variant="subtitle2">
                                                        {row.Passport}
                                                    </Typography>
                                                </Stack>
                                                <Stack sx={{mt: 1, justifyContent: "center", alignItems: "center"}}>
                                                    <Link component="a" sx={{fontSize: 12, color: '#0080FF'}}
                                                          href={`mailto:${row.email}`}>{row.Email}</Link>
                                                    <Link component="a"
                                                          sx={{fontSize: 12, color: '#0174DF', textDecoration: 'none'}}
                                                          href={`tel:${row.phone}`}>{row.Phone}</Link>
                                                </Stack>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })
                ) : (
                    <Box
                        sx={{
                            width: "100%",
                            minHeight: '200px',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{
                            width: "60%",
                            minHeight: '80%',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: grey[50],
                        }}>
                            <Typography sx={{color: theme.palette.mode === 'dark' ? '#000' : '#000'}}> No data
                                found</Typography>
                        </Box>
                    </Box>
                )}
            </Grid>
        </Box>
    );
}