import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from "@mui/icons-material/Search";
import { Box, Stack, Grid, InputAdornment, Paper, TablePagination, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { DataStore, Predicates, SortDirection } from 'aws-amplify';
import * as React from 'react';
import { ReservationsCustomers, ReservationsRooms } from "../../models";
import BookingCardLayout from '../CardLayout/BookingCardLayout';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from "dayjs";


export default function Booking() {

    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [reservations, setreservations] = React.useState<ReservationsCustomers[]>([])
    const [resvRooms, setResvRooms] = React.useState<ReservationsRooms[]>([])
    
    const [searchWord, setSearchWord] = React.useState<string>("")
    const [dateFilter, setDateFilter] = React.useState<Dayjs>(dayjs());
    
    const [reload, setReload] = React.useState<boolean>(false)


    // async function fetchCustomersByPassport() {
    //     const customers = (await DataStore.query(ReservationsCustomers)).filter(c => c.customers.Passport === searchWord);
    //    setreservations(customers)
    // }

    // async function fetchReservationsCustomers() {
    //     const customers = (await DataStore.query(ReservationsCustomers));
    //     console.log(customers)
    //     setreservations(customers)
    // }
    React.useEffect(() => {
      const subscription: any = DataStore.observeQuery(ReservationsRooms, Predicates.ALL).subscribe((snapshot) => {
        const {items} = snapshot
        setResvRooms(items)
      })
        console.log("fetch room")
      return () => subscription.unsubscribe();
    }, [reload])
    

    React.useEffect(() => {
       // !searchWord ?  fetchReservationsCustomers() : fetchCustomersByPassport();
        const ids: string[] = resvRooms.filter(room => room.rooms.roomNumber.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1).map(resv => resv.reservations?.id)
        
        const subscription: any = DataStore.observeQuery(ReservationsCustomers, Predicates.ALL, {
            sort: s => s.createdAt(SortDirection.DESCENDING)
        }).subscribe((snapshot) => {
            const {items} = snapshot
            setreservations(items.filter(c => (
                ids.includes(c.reservations?.id)
            ) && (
                c.reservations?.checkIn === dateFilter.format("YYYY-MM-DD")
            ) 
            )) 
        });
        console.log("fetch data")
        return () => subscription.unsubscribe();
    }, [searchWord, dateFilter, resvRooms, reload]);

    
   
    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const selectedRow = reservations.find(row => row.reservations.id === selected.slice(-1)[0])



    const handleCleanSelected = () => setSelected([])

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchWord(event.target.value)
    }
    
    const handleChangeDateFilter = (newValue: Dayjs | null) => {
        if (newValue) setDateFilter(newValue)
    }


    return (
        <Paper sx={{py: 5, px: 2}}>
            <Box sx={{width: "100%"}}>
                <Grid
                    container
                    spacing={3}
                    sx={{
                        mb: 3,
                    }}
                >
                    <Grid item xs={12}>
                        <Stack spacing={2} direction="row">
                            <Typography variant="h5">Reservations</Typography>
                            <TextField
                                size="small"
                                sx={{width: "100%", mb: 2, ml: 3}}
                                placeholder="Search by Passport, Room Number"
                                value={searchWord}
                                onChange={handleChangeSearch}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        {searchWord ?
                                            <IconButton
                                                edge="end"
                                                onClick={() => setSearchWord("")}
                                            >
                                                <ClearIcon/>
                                            </IconButton>
                                            : <SearchIcon/>}
                                    </InputAdornment>
                                }}
                            />
                            {/* Date Filter */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    inputFormat="YYYY-MM-DD"
                                    value={dateFilter}
                                    onChange={handleChangeDateFilter}
                                    renderInput={(parms) => <TextField
                                        {...parms}
                                        size="small"
                                    />}
                                />
                            </LocalizationProvider>
                        </Stack>
                        <BookingCardLayout
                            data={reservations}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleClick={handleClick}
                            
                            reload={reload}
                            setReload={setReload}
                        />
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={reservations.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Paper>

    );
}