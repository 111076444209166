import {grey} from '@mui/material/colors';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {createContext, useMemo, useState} from 'react';

interface ColorModeContextI {
    toggleColorMode: () => void;
}

export const ColorModeContext = createContext<ColorModeContextI>({
    toggleColorMode: () => {
    }
})


export const ToggleColorMode = ({children}: { children: React.ReactNode }) => {
    const [mode, setMode] = useState<"light" | "dark">('light')

    const colorMode = useMemo(() => ({
        toggleColorMode: () => {
            setMode((preMode) => (preMode === 'light' ? 'dark' : 'light'))
        }
    }), [])

    const theme = useMemo(() => createTheme({
        components: {
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        // backgroundColor: "#57419D"
                        backgroundColor: "rgb(42, 19, 46)"
                    },
                }
            }
        },

        typography: {
            // fontFamily: ['sans-serif', '"Libre Franklin"'].join(',')
            fontFamily: ['sans-serif', '"Montserrat"'].join(',')
        },

        palette: {
            primary: {
                main: "rgb(103, 190, 35)", // "#57419D",
                contrastText: "#fff"
            },
            mode,
            ...(mode === 'light'
                    ? {
                        page: "#fff",
                        appBar: 'rgb(42, 19, 46)'
                    }
                    : {
                        page: grey[900],
                        appBar: 'rgb(42, 19, 46)'
                    }
            )
        }
    }), [mode])

    return <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    </ColorModeContext.Provider>
}
