import * as React from "react";
// import FloatingActionButtons from '../FloatingActionButtons';
import CreateRoom from "./CreateRoom";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";


import {
    AppBar,
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TablePagination,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";

import {DataStore, SortDirection} from "@aws-amplify/datastore";
import {Rooms} from "../../models";
import RoomCardLayout from "../CardLayout/RoomCardLayout";


export default function RoomTable() {

    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rooms, setRooms] = React.useState<Rooms[]>([]);

    const [open, setOpen] = React.useState<boolean>(false);
    const [search, setSearch] = React.useState<string>("");
    const [filter, setFilter] = React.useState<string>("");
    const [Loading, setLoading] = React.useState<boolean>(true);

    const handleModalOpen = () => {
        setOpen(true);
    };

    const handleModalClose = () => {
        setOpen(false);
        if (selected) {
            handleCleanSelected();
        }
    };

    React.useEffect(() => {
        // must use clean up effect to avoid memory leak after the component unmount.
        const subscription: any = DataStore.observeQuery(Rooms, c => c.roomNumber("contains", search), {
            sort: c => c.createdAt(SortDirection.DESCENDING)
        }).subscribe((snapshot) => setRooms(snapshot.items) );
        return () => subscription.unsubscribe();
    }, [search, filter]);


    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = selected.length > 0 ? true : false;
    const selectedRow = rooms.find((room) => room.roomNumber === selected[0]);

    const handleCleanSelected = () => setSelected([]);
    const checkSelected = (label: string) => filter === label ? true : false;
    const handleDeleteFilter = () => setFilter('');

    return (
        <Paper sx={{py: 5, px: 2}}>
            <Box sx={{width: "100%"}}>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        mb: 3,
                    }}
                >
                    <Grid item xs={9}>
                        <Box sx={{display: "flex"}}>
                            <Typography variant="h5">Rooms</Typography>
                            <TextField
                                type={'text'}
                                autoComplete={'off'}
                                id="outlined-end-adornment"
                                placeholder="Room search by ID ..."
                                sx={{
                                    width: "55%",
                                    "& .MuiInputBase-root": {
                                        height: 40,
                                    },
                                    marginX: 4,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e: any) => setSearch(e.target.value)}
                            />
                            <Button
                                variant="outlined"
                                startIcon={<AddIcon/>}
                                onClick={handleModalOpen}
                            >
                                Add Room
                            </Button>
                        </Box>
                        <RoomCardLayout
                            data={rooms}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleModalOpen={handleModalOpen}
                            handleClick={handleClick}
                        />
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rooms.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1">
                            Use tag to filter your search
                        </Typography>
                        <Box sx={{width: "100%"}}>
                            <Chip
                                label="STANDARD SUPERIOR"
                                sx={{m: 1, cursor: "pointer"}}
                                clickable
                                color={checkSelected('STANDARD_SUPERIOR') ? 'primary' : 'default'}
                                onDelete={handleDeleteFilter}
                                onClick={() => setFilter("STANDARD_SUPERIOR")}
                            />
                            {/* <Chip
                label="Twin"
                sx={{ m: 1, cursor: "pointer" }}
                clickable
                color={checkSelected('Twin') ? 'primary' : 'default'}
                onDelete={handleDeleteFilter}
                onClick={() => setFilter("Twin")}
              /> */}
                            <Chip label="SUITE"
                                  sx={{m: 1, cursor: "pointer"}}
                                  clickable
                                  color={checkSelected('SUITE') ? 'primary' : 'default'}
                                  onDelete={handleDeleteFilter}
                                  onClick={() => setFilter("SUITE")}
                            />
                            <Chip label="MODERN DELUXE"
                                  sx={{m: 1, cursor: "pointer"}}
                                  clickable
                                  color={checkSelected('MODERN_DELUXE') ? 'primary' : 'default'}
                                  onDelete={handleDeleteFilter}
                                  onClick={() => setFilter("MODERN_DELUXE")}
                            />
                            <Chip label="KOREAN GRAND GARDEN"
                                  sx={{m: 1, cursor: "pointer"}}
                                  clickable
                                  color={checkSelected('KOREAN_GRAND_GARDEN') ? 'primary' : 'default'}
                                  onDelete={handleDeleteFilter}
                                  onClick={() => setFilter("KOREAN_GRAND_GARDEN")}
                            />
                            {/* <Chip label="DOUBLE"
                sx={{ m: 1, cursor: "pointer" }}
                clickable
                color={checkSelected('DOUBLE') ? 'primary' : 'default'}
                onDelete={handleDeleteFilter}
                onClick={() => setFilter("DOUBLE")}
              /> */}
                        </Box>
                    </Grid>
                </Grid>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleModalClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <AppBar sx={{position: "relative", background: "#1A5276"}}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleModalClose}
                                aria-label="close"
                            >
                                <CloseIcon/>
                            </IconButton>
                            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                                {isSelected ? 'Room Update' : 'Room Create'}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent sx={{display: "flex", justifyContent: "center"}}>
                        <CreateRoom
                            selectedRow={selectedRow}
                            rows={rooms}
                            cleanSelected={handleCleanSelected}
                            onClose={handleModalClose}
                        />
                    </DialogContent>
                </Dialog>
            </Box>
        </Paper>
    );
}