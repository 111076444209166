import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
// import { Dayjs } from "dayjs";
// import * as React from "react";

export default function Date({label, onChange, value, minDate}: any) {
    // const [date, setDate] = React.useState<Dayjs | null>(value);

    // const handleChange = (newValue: Dayjs | null) => {
    //   setDate(newValue);
    // };

    // React.useEffect(() => {
    //   onChange(date);
    // }, [date, onChange]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <DesktopDatePicker
                    label={label}
                    disablePast
                    minDate={minDate}
                    inputFormat="YYYY-MM-DD"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Stack>
        </LocalizationProvider>
    );
}
