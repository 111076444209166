import Typography from '@mui/material/Typography';

function MobileViewText(props: any) {
    return (
        <Typography variant="h6" color="text.primary" align="center" {...props}>
            This application is support to be used on larger screen.
        </Typography>
    );
}

export default MobileViewText;