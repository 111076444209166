// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const MonthType = {
  "JANUARY": "JANUARY",
  "FEBRUARY": "FEBRUARY",
  "MARCH": "MARCH",
  "APRIL": "APRIL",
  "MAY": "MAY",
  "JUNE": "JUNE",
  "JULY": "JULY",
  "AUGUST": "AUGUST",
  "SEPTEMBER": "SEPTEMBER",
  "OCTOBER": "OCTOBER",
  "NOVEMBER": "NOVEMBER",
  "DECEMBER": "DECEMBER"
};

const CountryType = {
  "KOREAN": "KOREAN",
  "JAPAN": "JAPAN",
  "USA": "USA",
  "MYANMAR": "MYANMAR"
};

const GenderType = {
  "MALE": "MALE",
  "FEMALE": "FEMALE"
};

const RoomType = {
  "STANDARD_SUPERIOR": "STANDARD_SUPERIOR",
  "MODERN_DELUXE": "MODERN_DELUXE",
  "KOREAN_GRAND_GARDEN": "KOREAN_GRAND_GARDEN",
  "SUITE": "SUITE"
};

const BedType = {
  "TWIN": "TWIN",
  "DOUBLE": "DOUBLE"
};

const ReservationsCondition = {
  "PENDING": "PENDING",
  "BOOKED": "BOOKED",
  "CONFIRM": "CONFIRM",
  "CANCELLED": "CANCELLED"
};

const { TransportationCheckOut, TransportationCheckIn, EtcCharge, SingleCharge, Theme, SeasonalPrice, RoomHistory, Reservations, Customers, Membership, Rooms, Cars, CashFlow, FlightTime, ReservationsCustomers, ReservationsRooms, ReservationsCars, CustomersMembership } = initSchema(schema);

export {
  TransportationCheckOut,
  TransportationCheckIn,
  EtcCharge,
  SingleCharge,
  Theme,
  SeasonalPrice,
  RoomHistory,
  Reservations,
  Customers,
  Membership,
  Rooms,
  Cars,
  CashFlow,
  FlightTime,
  ReservationsCustomers,
  ReservationsRooms,
  ReservationsCars,
  CustomersMembership,
  MonthType,
  CountryType,
  GenderType,
  RoomType,
  BedType,
  ReservationsCondition
};