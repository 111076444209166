import React from 'react'
import dayjs, {Dayjs} from 'dayjs'
import {DataStore, Predicates} from "@aws-amplify/datastore";
import {ReservationsRooms, ReservationsCondition} from "../../models";
import {
  Paper,
  Typography,
  Toolbar,
  TableContainer,
  Table,
  TableRow,
  TableHead, TableBody,
  TableCell,
} from '@mui/material'



interface Props {
  checkIn: Dayjs
  checkOut: Dayjs
}

const OccTable = (props: Props) => {
  const {checkIn, checkOut} = props
  const [availableRooms, setAvailableRoom] = React.useState<ReservationsRooms[]>([])
  
  
  React.useEffect(() => {
    let subscription = DataStore
      .observeQuery(ReservationsRooms, Predicates.ALL)
      .subscribe((snapshot) => {
          let {items} = snapshot
          const had = items
            .filter((resv: ReservationsRooms) => (
              resv.reservations.Condition !== ReservationsCondition.CANCELLED
            ))
            .filter((resv: ReservationsRooms) => (
              props.checkIn.isBefore(dayjs(resv.reservations.checkOut))
            ))
            .map(resv => resv.rooms.id)
          setAvailableRoom(items.filter(room => !had.includes(room.rooms.id)))
      })
    return () => subscription.unsubscribe()
  }, [props.checkIn, props.checkOut])
  
  
  return <Paper>
    <Toolbar>
      <Typography variant="h6">Room Occupancy</Typography>
    </Toolbar>
    
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Room Number</TableCell>
            <TableCell>Bed Type</TableCell>
            <TableCell>Max Person</TableCell>
            <TableCell>Room Type</TableCell>
            <TableCell>Remark</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {availableRooms.map((room: ReservationsRooms) => (
            <TableRow>
              <TableCell>{room.rooms.roomNumber}</TableCell>
              <TableCell>{room.rooms.bedType}</TableCell>
              <TableCell>{room.rooms.maxPerson}</TableCell>
              <TableCell>{room.rooms.roomType}</TableCell>
              <TableCell>{room.rooms.Remarks}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
}



export default OccTable