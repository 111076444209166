import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {drawerWidth} from '../../constant';


type Props = {
    title: string,
    view: JSX.Element,
    hideBooks?: boolean
    hideChooseMarker?: boolean
};

export default function Main(props: Props) {
    const {view, hideBooks, hideChooseMarker} = props

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                flexDirection: 'column-reverse',
                width: {sm: `calc(100% - ${drawerWidth}px)`}
            }}
        >
            <Toolbar/>
            {view}

        </Box>
    )
}
