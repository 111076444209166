import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { alpha } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
// import FloatingActionButtons from '../FloatingActionButtons';
// import CreateRoom from './createCustomer';
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from "dayjs";

import {DataStore, Predicates} from "@aws-amplify/datastore";
import {Reservations, ReservationsCustomers, ReservationsCars, ReservationsRooms, Customers, FlightTime} from "../../models";

import CheckInPickup from './CheckInPickup';
import CheckOutPickup from './CheckOutPickup';


// import useToggle from '../ReadOnlyHook';

interface Data {
    id: string;
    check_in: string; // Datejs
    check_out: string; // Datejs
    room_id: string; // ref_id
    car_id: string; // ref_id
    customer_id: string; // ref_id

    arrival_airline: string;
    arrival_date: string;
    arrival_time: string;

    departure_airline: string;
    departure_date: string;
    departure_time: string;

    // from cashflow
    depo: number
    during: number
    max_person: number

    customer_email: string;
    customer_passport: string;
    customer_phone: string;

    status: string;
}

// function createData(
// room: string,
// room_type: string,
// max_person: number,
// // is_checkout: boolean,
// // check_in: string,
// // check_out: string,
// remarks: number,
// // prices: number
// ): Data {
// return {
// room,
// room_type,
// max_person,
// // is_checkout, check_in, check_out,
// remarks,
// // prices
// };
// }

// const rows: Data[] = [
// createData("A9456", "Korean Grand Garden", 2, true, "2022-04-14", "2022-04-15", 82, 100),
// ];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string | boolean },
    b: { [key in Key]: number | string | boolean }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: "customer_id",
        numeric: false,
        disablePadding: true,
        label: "Customer",
    },
    {
        id: "max_person",
        numeric: false,
        disablePadding: true,
        label: "Max Person",
    },
    {
        id: "arrival_time",
        numeric: false,
        disablePadding: true,
        label: "Arrival Time",
    },
    {
        id: "arrival_date",
        numeric: false,
        disablePadding: true,
        label: "Arrival Date",
    },
    {
        id: "arrival_airline",
        numeric: false,
        disablePadding: true,
        label: "Arrival Airline",
    },
    {
        id: "check_in",
        numeric: false,
        disablePadding: true,
        label: "Check In",
    },
    {
        id: "check_out",
        numeric: false,
        disablePadding: true,
        label: "Check Out",
    },
    {
        id: "room_id",
        numeric: false,
        disablePadding: true,
        label: "Room",
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
    selected: readonly string[];
    cleanSelected: () => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const {numSelected, selected, cleanSelected} = props;

    // const [isReadOnly, setIsReadOnly] = useToggle();

    /*
    let thinCustomer = useQuery(query("reservations").orderByDesc("createdAt"));
    const ids = thinCustomer
        ?.filter((room) => selected.includes(room.id))
        .map((id) => id.id);
    */

    const handleDelete = async () => {
        cleanSelected();
    };

    return (
        <Toolbar
            sx={{
                pl: {sm: 2},
                pr: {xs: 1, sm: 1},
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{flex: "1 1 100%"}}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{flex: "1 1 100%"}}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Pick Up
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton onClick={handleDelete}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon/>
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

export default function Booking() {
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<keyof Data>("customer_id");
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, setRows] = React.useState<Data[]>([]);
    const [open, setOpen] = React.useState(false);

    const [searchWord, setSearchWord] = React.useState<string>("");
    const [dateFilter, setDateFilter] = React.useState<Dayjs>(dayjs());

    const [checkPick, setCheckPick] = React.useState<Data[]>([])
    
    const [resv, setResv] = React.useState<Reservations[]>([])
    const [resvCust, setResvCust] = React.useState<ReservationsCustomers[]>([])
    const [resvRooms, setResvRooms] = React.useState<ReservationsRooms[]>([])
    const [resvCars, setResvCars] = React.useState<ReservationsCars[]>([])
    
    const [flightTime, setFlightTime] = React.useState<FlightTime[]>([])  // 1 customer 1 flight
    
    
    React.useEffect(() => {
        const fetchReservations:any = DataStore.observeQuery(Reservations, Predicates.ALL).subscribe((snapshot) => {
            const {items} = snapshot
            setResv(items)
        })
        const fetchResvCustomer:any = DataStore.observeQuery(ReservationsCustomers, Predicates.ALL).subscribe((snapshot) => {
            const {items} = snapshot
            setResvCust(items)
        })
        const fetchResvRoom:any = DataStore.observeQuery(ReservationsRooms, Predicates.ALL).subscribe((snapshot) => {
            const {items} = snapshot
            setResvRooms(items)
        })
        const fetchResvCar:any = DataStore.observeQuery(ReservationsCars, Predicates.ALL).subscribe((snapshot) => {
            const {items} = snapshot
            setResvCars(items)
        })
        const fetchFlightTime:any = DataStore.observeQuery(FlightTime, Predicates.ALL).subscribe((snapshot) => {
            const {items} = snapshot
            setFlightTime(items)
        })
        
        return () => fetchReservations.unsubscribe() 
            && fetchResvCustomer.unsubscribe()
            && fetchResvRoom.unsubscribe()
            && fetchResvCar.unsubscribe()
    }, [])
    
    
    React.useEffect(() => {
        let newRows: Data[] = []
        
        const getCarName = (id: string) => {
            return resvCars.find(r => r.reservations.id === id)?.cars.carNumber || "-"
        }
        
        const getRoomName = (id: string) => {
            return resvRooms.find(r => r.reservations.id === id)?.rooms.roomNumber || "-"
        }
        
        const getMaxPer = (id: string) => {
            return resvRooms.find(r => r.reservations.id === id)?.rooms.maxPerson || 0
        }
        
        const getCust = (id: string): Customers => {
            return resvCust.find(r => r.reservations.id === id)?.customers || {} as Customers
        }
        
        const getFlight = (id: string, custID?: string): FlightTime => {
            return flightTime.find(r => r.reservationsID === id && r.Customers?.id === custID) || {} as FlightTime
        }
        
        resv?.forEach(cust => {
            newRows.push({
                id: cust.id,
                check_in: cust.checkIn || "-",
                check_out: cust.checkOut || "-",
                room_id: getRoomName(cust.id),
                car_id: getCarName(cust.id),

                customer_id: getCust(cust.id).firstName + " " + getCust(cust.id).lastName,

                // from cashflow
                during: cust.CashFlow?.totalDuration || 0,
                depo: cust.CashFlow?.depositAmount || 0,
                max_person: getMaxPer(cust.id),

                arrival_airline: getFlight(cust.id, getCust(cust.id).id).arrivalAirline || "-",
                arrival_date: getFlight(cust.id, getCust(cust.id).id).arrivalDate || "-",
                arrival_time: getFlight(cust.id, getCust(cust.id).id).arrivalTime || "-",

                departure_airline: getFlight(cust.id, getCust(cust.id).id).departureAirline || "-",
                departure_date: getFlight(cust.id, getCust(cust.id).id).departureDate|| "-",
                departure_time: getFlight(cust.id, getCust(cust.id).id).departureTime || "-",

                customer_email: getCust(cust.id).Email || "-",
                customer_passport: getCust(cust.id).Passport || "-",
                customer_phone: getCust(cust.id).Phone || "-",

                status: cust.Condition || "-",
            })
        })
        setCheckPick(newRows)
        
        newRows = newRows.filter(
            (r) =>
                // (r.email.toLowerCase().startsWith(searchWord.toLowerCase())
                // || (r.passport.toLowerCase().startsWith(searchWord.toLowerCase()))
                (
                    r.customer_id.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1 ||
                    r.customer_email.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1 ||
                    r.customer_passport.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1 ||
                    r.customer_phone.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1
                    // r.arrival_time.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1 ||
                    // r.arrival_date.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1
                )

                // for date filter
                &&
                (r.arrival_date === dateFilter.format("YYYY-MM-DD"))
                || (dayjs(r.arrival_time).isBefore( dayjs(`${dateFilter.format("YYYY-MM-")}${+dateFilter.format("DD")+1} 02:00:00`)))
        );

        setRows(newRows);
        // console.log(dayjs("2022-10-26 01:30:21").isBefore(dayjs("2022-10-26 02:00:00")), dayjs(`2022-10-${1+1}`).format("YYYY-MM-DD"))
    }, [resv, resvCust, resvRooms, resvCars, searchWord, dateFilter])


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const selectedRow = rows.find((row) => row.id === selected.slice(-1)[0]);

    const handleCleanSelected = () => setSelected([]);

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchWord(event.target.value);
    };

    const handleClickCheckOut = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (selectedRow !== undefined) {
            /*
            updateRecord("reservations", selectedRow?.id, {
                isCheckOut: true,
            });
            */
        }
    };

    const handleChangeDateFilter = (newValue: Dayjs | null) => {
        if (newValue) setDateFilter(newValue)
    }

    return (
        <Box sx={{width: "100%"}}>
            <Grid container spacing={2}>
                {/*
      <Grid item xs={4}>
      <CreateRoom selectedRow={selectedRow} rows={rows} cleanSelected={handleCleanSelected} />
      </Grid>
      */}
                <Grid item xs={12}>
                    {/* Search Bar */}
                    <Stack direction="row" spacing={2}>
                        <TextField
                            size="small"
                            sx={{width: "100%", mb: 2}}
                            placeholder="Search by name, passport, email & phone or Arrival time/date"
                            value={searchWord}
                            onChange={handleChangeSearch}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchWord && (
                                            <IconButton edge="end" onClick={() => setSearchWord("")}>
                                                <ClearIcon/>
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {/* Date Filter */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                inputFormat="YYYY-MM-DD"
                                value={dateFilter}
                                onChange={handleChangeDateFilter}
                                renderInput={(parms) => <TextField
                                    {...parms}
                                    size="small"
                                />}
                            />
                        </LocalizationProvider>
                    </Stack>
                    <Paper sx={{width: "100%", mb: 2}}>
                        <EnhancedTableToolbar
                            numSelected={selected.length}
                            selected={selected}
                            cleanSelected={handleCleanSelected}
                        />
                        <TableContainer>
                            <Table
                                sx={{minWidth: 750}}
                                aria-labelledby="tableTitle"
                                size={dense ? "small" : "medium"}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                />
                                <TableBody>
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                                    {rows.length
                                        ? stableSort(rows, getComparator(order, orderBy))
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <React.Fragment>
                                                        <TableRow
                                                            hover
                                                            onClick={(event) => handleClick(event, row.id)}
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            selected={isItemSelected}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={isItemSelected}
                                                                    inputProps={{
                                                                        "aria-labelledby": labelId,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="left">{row.customer_id}</TableCell>
                                                            <TableCell align="left">{row.max_person}</TableCell>
                                                            <TableCell align="left">{dayjs(row.arrival_time).format("HH:mm:ss")}</TableCell>
                                                            <TableCell align="left">{row.arrival_date}</TableCell>
                                                            <TableCell align="left">{row.arrival_airline}</TableCell>
                                                            <TableCell align="left">{row.check_in}</TableCell>
                                                            <TableCell align="left">{row.check_out}</TableCell>
                                                            <TableCell align="left">{row.room_id}</TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                );
                                            })
                                        : null}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (dense ? 33 : 53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6}/>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <FormControlLabel
                        control={<Switch checked={dense} onChange={handleChangeDense}/>}
                        label="Dense padding"
                    />
                </Grid>

                <Grid item xs={12}>
                    <CheckInPickup rows={checkPick} dateFilter={dateFilter}/>
                    <CheckOutPickup rows={checkPick} dateFilter={dateFilter}/>
                </Grid>
            </Grid>
        </Box>
    );
}