import { Avatar, Link, Stack, Typography } from "@mui/material";
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import { DataStore } from "@aws-amplify/datastore";
import PersonIcon from '@mui/icons-material/Person';
import React from "react";
import { ReservationsCars, ReservationsCustomers, ReservationsRooms } from "../../models";

interface Props {
    reservationsID: any;
}

export default function BookingCarText(props: Props) {
    
    const [reservationsCustomers, setreservationsCustomers] = React.useState<any>([])

    
    React.useEffect(
        () => {
        async function FetchCustomers() {
            const rooms = (await DataStore.query(ReservationsCars)).filter(c => c.reservations.id === props.reservationsID);
            setreservationsCustomers(rooms[0])
            // console.log(rooms[0])
        }
        FetchCustomers()
        },
        []
      )

      if(!reservationsCustomers) return <>Sorry! unable to get this information</>

      return (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <AirportShuttleIcon sx={{ fontSize: 18 }} />
        <Typography sx={{
            fontSize: 14,
            fontWeight: 'bold'
        }}> : {reservationsCustomers?.cars?.carNumber}</Typography>
    </Stack> 
        
    )
}