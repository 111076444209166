import BookOnlineIcon from '@mui/icons-material/BookOnline';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import DoorBackIcon from '@mui/icons-material/DoorBack';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import {Box, Divider, List, ListItem, ListItemButton, ListItemIcon, Tooltip, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useContext} from 'react';
import {Link} from 'react-router-dom';

import {ColorModeContext} from '../../component/theme';
import ThemeSwitch from '../CustomSwitch';

import AnalyticsIcon from '@mui/icons-material/Analytics';
import CalculateIcon from '@mui/icons-material/Calculate';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import TableChartIcon from '@mui/icons-material/TableChart'

import {Auth} from 'aws-amplify';

const MyDrawer = () => {
    const colorMode = useContext(ColorModeContext)
    const theme = useTheme()


    async function signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    const handleChangeTheme = () => {
        let pre = theme.palette.mode
        colorMode.toggleColorMode()
       

    }

    const Item = ({route, icon, label}: { label: string, route: string, icon: JSX.Element }) => (
        <Link to={route}>
            <Tooltip title={label} placement="right">
                <ListItem key={1} disablePadding>
                    <ListItemButton>
                        {/* keep the icon color constant white in dark or light mode */}
                        <ListItemIcon sx={{color: 'white'}}>
                            {icon}
                        </ListItemIcon>
                        {/* <ListItemText primary={text} /> */}
                    </ListItemButton>
                </ListItem>
            </Tooltip>
        </Link>
    )

    const links: { label: string, link: string, icon: JSX.Element }[] = [
        {label: "Reservation", link: "/reservation", icon: <BookOnlineIcon/>},
        {label: "Booking", link: "/booking", icon: <CollectionsBookmarkIcon/>},
        {label: "Room Occupancy", link: "/occupancy", icon: <TableChartIcon />},
        {label: "Lead", link: "/lead", icon: <ConnectWithoutContactIcon/>},
        // {label: "Checkin", link: "/checkin", icon: <GroupAddIcon/>},
        // {label: "Checkout", link: "/checkout", icon: <GroupRemoveIcon/>},
        {label: "Pickup", link: "/pickup", icon: <TransferWithinAStationIcon/>},

        {label: "Fee Calculator", link: "/calc", icon: <CalculateIcon/>},

        {label: "Customer", link: "/customer", icon: <PeopleIcon/>},
        {label: "Membership", link: "/membership", icon: <CardMembershipIcon/>},
        {label: "Rooms", link: "/room", icon: <DoorBackIcon/>},
        {label: "Golf Cars", link: "/car", icon: <GolfCourseIcon/>},
        {label: "Seasonal Prices", link: "/price", icon: <PriceChangeIcon/>},

        // {label: "Room Move", link: "/move", icon: <MoveDownIcon/>},
        {label: "Dashboard", link: "/dashboard", icon: <DashboardIcon/>},
        {label: "Revenue", link: "/revenue", icon: <AnalyticsIcon/>},
        // {label: "Cashflow", link: "/cashflow", icon: <AddchartIcon/>},

        {label: "Setting", link: "/setting", icon: <SettingsApplicationsIcon/>},
    ]

    return (
        <Box component="div" sx={{bgcolor: "appBar"}}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "63px",
                    color: "#fff",
                }}
            >
                <Typography variant="h6">
                    Mida
                </Typography>
            </Box>
            <Divider/>
            <List>
                {links.map(({label, link, icon}: { label: string, link: string, icon: JSX.Element }, key: number) => (
                    <Item
                        key={key}
                        label={label}
                        route={`${link}/`}
                        icon={icon}
                    />
                ))}
                <Tooltip title="Logout" placement="right">
                    <ListItem key={1} disablePadding>
                        <ListItemButton
                            component="button"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => signOut()}
                        >
                            {/* keep the icon color constant white in dark or light mode */}
                            <ListItemIcon sx={{color: 'white'}}>
                                <LogoutIcon/>
                            </ListItemIcon>
                            {/* <ListItemText primary={text} /> */}
                        </ListItemButton>
                    </ListItem>
                </Tooltip>
            </List>

            <Box
                sx={{
                    backgroundColor: "rgba(0,0,0,0.5)",
                    py: 0.5,
                    mt: 2,
                    borderRadius: 2,
                }}
            >
                <ThemeSwitch checked={theme.palette.mode === "dark"} onClick={handleChangeTheme} sx={{
                    ".MuiSwitch-track": {
                        backgroundColor: "rgba(0, 0, 0, 0.87)"
                    },
                    ".MuiSwitch-thumb": {
                        color: "#fff",
                    }
                }}/>
            </Box>
        </Box>
    )
};

export default MyDrawer;
