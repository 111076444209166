import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Stack,
    TablePagination,
    TextField,
    Toolbar,
    Typography
} from '@mui/material';
import * as React from 'react';

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";


import CreateMembership from './createMembership';

import {DataStore, SortDirection} from 'aws-amplify';
import {CustomersMembership} from '../../models';
import MembershipCardLayout from '../CardLayout/MembershipCardLayout';


export default function MembershipTable() {
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [membership, setMembership] = React.useState<CustomersMembership[]>([])
    const [searchWord, setSearchWord] = React.useState<string>("")
    const [open, setOpen] = React.useState<boolean>(false);

    const handleModalOpen = () => {
        setOpen(true);
    };

    const handleModalClose = () => {
        setOpen(false);
        handleCleanSelected();
    };


    /*
    async function fetchMembership() {
        const Memberships = await customersDataStore.query(Membership);
    }

    // https://docs.amplify.aws/lib/datastore/relational/q/platform/js/#many-to-many || See this

    async function fetchAllCustomersMembership() {
        const models = (await DataStore.query(CustomersMembership))
        setMembership(models)
    }
    */


    React.useEffect(() => {
        const subscription: any = DataStore.observeQuery(CustomersMembership, r => r.id('contains', searchWord), {
            sort: s => s.createdAt(SortDirection.DESCENDING)
        }).subscribe((snapshot) => setMembership(snapshot.items));
        return () => subscription.unsubscribe();
    }, [])


    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    // const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const isSelected = selected.length > 0 ? true : false;
    const selectedRow = membership.find(row => row.id === selected.slice(-1)[0])

    const handleCleanSelected = () => setSelected([])

    return (

        <Box sx={{width: '100%'}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={{py: 2, px: 2}}>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h5">Memberships</Typography>
                            <TextField
                                autoComplete="off"
                                id="outlined-end-adornment"
                                placeholder="Membership search by name ..."
                                sx={{
                                    width: "40%",
                                    "& .MuiInputBase-root": {
                                        height: 40,
                                    },
                                    marginX: 4,
                                }}
                                type="text"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e: any) => setSearchWord(e.target.value)}
                            />
                            <Button
                                variant="outlined"
                                startIcon={<AddIcon/>}
                                onClick={handleModalOpen}
                            >
                                Add Membership
                            </Button>
                        </Stack>
                        <MembershipCardLayout
                            data={membership}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleModalOpen={handleModalOpen}
                            handleClick={handleClick}
                        />
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={membership.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense}/>}
                    label="Dense padding"
                /> */}
                </Grid>
            </Grid>
            <Dialog
                fullScreen
                open={open}
                onClose={handleModalClose}
                aria-labelledby="responsive-dialog-title"
            >
                <AppBar sx={{position: "relative", background: "rgb(42, 19, 46)"}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleModalClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            {isSelected ? 'Membership Update' : 'Membership Create'}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{display: "flex", justifyContent: "center"}}>
                    <CreateMembership selectedRow={selectedRow} rows={membership} cleanSelected={handleCleanSelected}
                                      onClose={handleModalClose}/>
                </DialogContent>
            </Dialog>
        </Box>
    );
}