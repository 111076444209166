import {Autocomplete, Button, Chip, Paper, Stack, TextField} from '@mui/material';
import * as React from 'react';
import {DataStore} from '@aws-amplify/datastore';
import {MonthType, SeasonalPrice} from '../../models';

interface Props {
    selectedRow: any
    rows: any
    cleanSelected: () => void
}

export default function CreateSeasonalPrice(props: Props) {
    // const [seasonalId, setSeasonalId] = React.useState<string>("") // private
    const [month, setMonth] = React.useState<MonthType>(monthsOptions[0])
    const [generalPrice, setGeneralPrice] = React.useState<number>(0);
    const [tourPrice, setTourPrice] = React.useState<number>(0);
    const [memberPrice, setMemberPrice] = React.useState<number>(0);
    // const [remarks, setRemarks] = React.useState<string>("")


    React.useEffect(() => {
        if (props.selectedRow) {
            setMonth(props.selectedRow.Months)
            // setRemarks(props.selectedRow.remarks)
            setGeneralPrice(props.selectedRow.General)
            setTourPrice(props.selectedRow.Tour)
            setMemberPrice(props.selectedRow.Membership)
        } else {
            setMonth(monthsOptions[0])
            // setRemarks("")
            setGeneralPrice(0)
            setTourPrice(0)
            setMemberPrice(0)
        }
    }, [props.selectedRow])


    const handleChangeMonth = (event: React.SyntheticEvent, value: string | null): void => {
        if (value) setMonth(value as MonthType)
        console.log(value)
    }

    /*
    const handleChangeRemarks = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRemarks(event.target.value)
    }
    */

    const handleChangeGeneralPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGeneralPrice(+event.target.value)
    }

    const handleChangeTourPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTourPrice(+event.target.value)
    }

    const handleChangeMemberPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMemberPrice(+event.target.value)
    }

    const handleSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
        let id = props.rows.find((row: { Months: string }) => row.Months === month)
        if (month && id) {
            console.log("To Update", id.id)
            if (props.rows.find((row: { Months: string }) => (row.Months === month))) {
                const original = await DataStore.query(SeasonalPrice, id.id)

                try {
                    if (original === undefined) new Error("Something went wrong! maybe no ID or other.")
                    if (original !== undefined) {
                        await DataStore.save(
                            SeasonalPrice.copyOf(original, updated => {
                                updated.Months = month
                                updated.General = generalPrice
                                updated.Tour = tourPrice
                                updated.Membership = memberPrice
                            })
                        )
                        console.log("Updated Done!")
                    }
                } catch (err) {
                    console.log("UPDATED_ERR:", err)
                }
            }

            setMonth(monthsOptions[0])
            setGeneralPrice(0)
            setTourPrice(0)
            setMemberPrice(0)
        }


        /*
      if (month && !seasonalId) {  // create
        try {
          await DataStore.save(
            new SeasonalPrice({
              Months: month,
              General: generalPrice,
              Tour: tourPrice,
              Membership: memberPrice,
            })
          )
          props.setReload()
          console.log("Create Done!")
        } catch (err) {
          console.log("CREATE_ERR:", err)
        }
      
        setMonth(monthsOptions[0])
        setGeneralPrice(0)
        setTourPrice(0)
        setMemberPrice(0)
      }
        */
    }

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMonth(monthsOptions[0])
        setGeneralPrice(0)
        setTourPrice(0)
        setMemberPrice(0)
    }

    return (
        <Stack spacing={2} component={Paper} p={2}>
            <Autocomplete
                value={month}
                onChange={handleChangeMonth}
                id="size-small-filled"
                size="small"
                options={monthsOptions}
                getOptionLabel={(option) => option}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option}
                            size="small"
                            {...getTagProps({index})}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Months"
                        placeholder="Months"
                        size="small"
                        InputLabelProps={{shrink: true}}
                        required
                    />
                )}
            />

            <TextField
                type="number"
                id="number"
                label="General Prices"
                variant="standard"
                size="small"
                InputLabelProps={{shrink: true}}
                value={generalPrice}
                onChange={handleChangeGeneralPrice}
            />

            <TextField
                type="number"
                id="number"
                label="Tour Prices"
                variant="standard"
                size="small"
                InputLabelProps={{shrink: true}}
                value={tourPrice}
                onChange={handleChangeTourPrice}
            />

            <TextField
                type="number"
                id="number"
                label="Member Prices"
                variant="standard"
                size="small"
                InputLabelProps={{shrink: true}}
                value={memberPrice}
                onChange={handleChangeMemberPrice}
            />

            {/*
            <TextField
                id="standard-basic"
                label="Remarks"
                variant="standard"
                size="small"
                InputLabelProps={{shrink: true}}
                value={remarks}
                onChange={handleChangeRemarks}
            />
            */}

            <Button variant="contained" onClick={handleSave}>Save</Button>
            <Button onClick={handleCancel}>Cancel</Button>
        </Stack>
    );
}

const monthsOptions = [
    MonthType.JANUARY,
    MonthType.FEBRUARY,
    MonthType.MARCH,
    MonthType.APRIL,
    MonthType.MAY,
    MonthType.JUNE,
    MonthType.JULY,
    MonthType.AUGUST,
    MonthType.SEPTEMBER,
    MonthType.OCTOBER,
    MonthType.NOVEMBER,
    MonthType.DECEMBER
]


