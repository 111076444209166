import Typography from '@mui/material/Typography';

function TempPlaceHolder(props: any) {
    return (
        <Typography variant="h4" color="text.primary" align="center" {...props}>
            Under construction
        </Typography>
    );
}

export default TempPlaceHolder;