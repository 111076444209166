import {Button, Stack, Typography} from '@mui/material'


const Page404 = () => {
    const handleGoHome = () => {
        window.location.href = "/"
    }

    return <Stack
        spacing={2}
        sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        }}
    >
        <Typography variant="h5">404 Not Found!</Typography>
        <Button variant="contained" onClick={handleGoHome}>Home</Button>
    </Stack>
}


export default Page404
