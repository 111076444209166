import dayjs, {Dayjs} from 'dayjs'
import {Autocomplete, Button, Checkbox, Chip, FormControlLabel, Paper, Stack, TextField} from '@mui/material';
import * as React from 'react';
import {DataStore} from 'aws-amplify';
import {Customers, CustomersMembership, Membership} from '../../models';

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';


interface Props {
    selectedRow: any
    rows: any
    cleanSelected: () => void
    onClose: () => void
}

export default function CreateMembership(props: Props) {
    const [ids, setIds] = React.useState<string[]>([])
    const [membershipID, setMembershipID] = React.useState<string>("")
    const [customers, setCustomers] = React.useState<Customers[]>([]) //  2 customers for selected 
    const [totalDays, setTotalDays] = React.useState<number>(0);
    const [startDate, setStartDate] = React.useState<Dayjs | null>()
    const [expiredDate, setExpiredDate] = React.useState<Dayjs | null>()
    const [daysOfUsed, setDaysOfUsed] = React.useState<number>(0)
    const [isUsed, setIsUsed] = React.useState<boolean>(false)
    const [custs, setCusts] = React.useState<Customers[]>([])  // many customers for option


    React.useEffect(() => {
        let fetchCustomerOptions = async () => {
            let data = (await DataStore.query(Customers))
            setCusts(data)
            console.log(data, "Ok")
        }

        fetchCustomerOptions()
    }, [])


    let customerOptions: Customers[] = []
    custs.forEach(c => {
        customerOptions.push(c)
    })


    React.useEffect(() => {
        const get2Cust = async (memid: string) => {
            let newCusts: Customers[] = []
            const membership = (await DataStore.query(CustomersMembership))
            const custs = membership.filter(mem => mem.membership.id === memid).map(mem => mem.customers)
            // const ids = membership.filter(mem => mem.membership.id === memid).map(mem => mem.id)
            custs.forEach(c => {
                newCusts.push(c)
            })
            return newCusts
        }

        const get2Id = async (memid: string) => {
            // let newIds: string[] = []
            const membership = (await DataStore.query(CustomersMembership))
            const newIds = membership.filter(mem => mem.membership.id === memid).map(mem => mem.id)
            /*
            ids.forEach(c => {
                newIds.push(c)
            })
            */
            return newIds
        }


        if (props.selectedRow) {
            get2Id(props.selectedRow.membership.id).then(ids => setIds(ids)).catch(e => console.log("Err"))
            setMembershipID(props.selectedRow.membership.id)
            get2Cust(props.selectedRow.membership.id).then(custs => setCustomers(custs)).catch(e => console.log("Err"))
            // setSerial(props.selectedRow.serial_no)
            setTotalDays(props.selectedRow.membership.totalDays)
            setStartDate(dayjs(props.selectedRow.membership.startDate))
            setExpiredDate(dayjs(props.selectedRow.membership.expiredDate))
            setIsUsed(props.selectedRow.membership.isUsed)
            setDaysOfUsed(props.selectedRow.membership.totalUsedDays)
        } else {   // when unselected
            setCustomers([])
            // setSerial(getSerial())
            setTotalDays(0)
            setStartDate(null)
            setExpiredDate(null)
            setIsUsed(false)
            setDaysOfUsed(0)
            // setRemarks("")
        }
    }, [props.selectedRow])


    const handleChangeCustomer = (event: React.SyntheticEvent, value: Customers[] | null): void => {
        if (value) setCustomers(value)
        console.log(value)
    }

    const handleChangeRemarks = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setRemarks(event.target.value)
    }

    // const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setPrices(+event.target.value)
    // }

    const handleChangeTotalDays = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTotalDays(+event.target.value)
    }

    // const customersArray = 

    const handleSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (membershipID) {
            if (props.rows.find((row: { membership: { id: string } }) => (row.membership.id === membershipID))) {
                const original = await DataStore.query(Membership, membershipID)

                try {
                    if (original !== undefined) {
                        await DataStore.save(
                            Membership.copyOf(original, updated => {
                                updated.totalDays = totalDays
                                updated.totalUsedDays = daysOfUsed
                                updated.remainingDays = totalDays
                                updated.isUsed = isUsed
                                // updated.customerss = customers.map(cust => cust.id)

                                if (startDate) updated.startDate = startDate.format("YYYY-MM-DD")
                                if (expiredDate) updated.expiredDate = expiredDate.format("YYYY-MM-DD")
                            })
                        )

                        ids.forEach(async (id, index) => {
                            const original = await DataStore.query(CustomersMembership, id)  // selected 2 id
                            const membership = await DataStore.query(Membership, membershipID)

                            if (original !== undefined && membership !== undefined) {
                                await DataStore.save(
                                    CustomersMembership.copyOf(original, updated => {
                                        updated.customers = customers[index]
                                        updated.membership = membership
                                    })
                                )
                                console.log("Success Updated", id)
                            }
                        })
                    }
                } catch (err) {
                    console.log("Err")
                }
            }
            setCustomers([])
            // setSerial(getSerial())
            setTotalDays(0)
            setStartDate(null)
            setExpiredDate(null)
            setIsUsed(false)
            setDaysOfUsed(0)
            props.onClose()
        }

        if (!membershipID) {  // create mode
            try {
                const membership = await DataStore.save(
                    new Membership({
                        totalDays: totalDays,
                        totalUsedDays: 0,
                        startDate: startDate ? startDate.format("YYYY-MM-DD") : "0000-00-00",
                        expiredDate: expiredDate ? expiredDate.format("YYYY-MM-DD") : "0000-00-00",
                        remainingDays: totalDays,
                        isUsed: isUsed,
                        // customerss: customers.map(cust => cust.id)
                    })
                )

                customers.forEach(async cust => {
                    const currentCustMem = await DataStore.save(
                        new CustomersMembership({
                            customers: cust,
                            membership: membership,
                        })
                    )
                    
                    // update customer
                    await DataStore.save(
                        Customers.copyOf(cust, updated => {
                            updated.Memberships = [currentCustMem]
                        })
                    )
                })
            } catch (err) {
                console.log("CREATE_ERR:", err)
            }
            setCustomers([])
            // setSerial(getSerial())
            setTotalDays(0)
            setStartDate(null)
            setExpiredDate(null)
            setIsUsed(false)
            setDaysOfUsed(0)
            props.onClose()
        }
        /*
        if (props.rows.find((row: { serial_no: string }) => row.serial_no === serial)) {
            const to_update = thinMembership?.filter(season => season.serialNo === serial)
            if (to_update && startDate && expiredDate) {
                let ids = to_update.map(i => i.id)
                updateRecords("membership", ids, {
                    remarks,
                    totalDays,
                    startingDate: startDate.format("YYYY-MM-DD"),
                    expiredDate: expiredDate.format("YYYY-MM-DD"),
                    daysOfUsed,
                    isUsed,
                    userId: getCurrentUserId(),
                })
                console.log("updated")
                props.onClose();
                props.cleanSelected() // clean selected rows
                return
            }
        }

        // if (startDate && expiredDate) {
        const mem = {
            customerId: customers[0].id,
            serialNo: getSerial(),
            totalDays,
            fullName: customers[0].name,
            startingDate: startDate ? startDate.format("YYYY-MM-DD") : null,
            expiredDate: expiredDate ? expiredDate.format("YYYY-MM-DD") : null,
            remainingDays: totalDays,
            remarks,
            userId: getCurrentUserId(),
            passport: customers[0].passport,
            daysOfUsed,
            isUsed,
        }
        const mem1 = {
            customerId: customers[1].id,
            serialNo: getSerial(),
            totalDays,
            fullName: customers[1].name,
            startingDate: startDate ? startDate.format("YYYY-MM-DD") : null,
            expiredDate: expiredDate ? expiredDate.format("YYYY-MM-DD") : null,
            remainingDays: totalDays,
            remarks,
            userId: getCurrentUserId(),
            passport: customers[1].passport,
            daysOfUsed,
            isUsed
        }
        await createRecords("membership", [mem, mem1])
        // }

        setCustomers([])
        setSerial(getSerial(1))
        setTotalDays(0)
        setDaysOfUsed(0)
        setStartDate(null)
        setExpiredDate(null)
        setRemarks("")
        props.onClose();
        props.cleanSelected() // clean selected rows
        */
    }

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        setCustomers([])
        // setSerial(getSerial())
        setStartDate(null)
        setExpiredDate(null)
        setTotalDays(0)
        setIsUsed(false)
        setDaysOfUsed(0)
        // setRemarks("")
    }

    const handleChangeStartDate = (newValue: Dayjs | null) => {
        setStartDate(newValue)
    }

    const handleChangeExpiredDate = (newValue: Dayjs | null) => {
        setExpiredDate(newValue)
    }

    const handleChangeDaysOfUsed = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = +event.target.value
        if (!isNaN(value)) setDaysOfUsed(value)
    }

    const handleCheckIsUsed = () => {
        setIsUsed((c: boolean) => !c)
    }


    return (
        <Stack spacing={2} component={Paper} p={2}>
            <Autocomplete
                value={customers}
                multiple
                onChange={handleChangeCustomer}
                id="size-small-filled"
                size="small"
                options={customerOptions}
                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                sx={{width: "100%"}}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={`${option.firstName} ${option.lastName}`}
                            size="small"
                            {...getTagProps({index})}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Customers"
                        placeholder="Choose customers"
                        size="small"
                        InputLabelProps={{shrink: true}}
                        required
                    />
                )}
            />

            <TextField
                value={totalDays}
                type="string"
                label="Total Days"
                variant="standard"
                size="small"
                InputLabelProps={{shrink: true}}
                onChange={handleChangeTotalDays}
                sx={{width: "100%"}}
            />

            <TextField
                value={daysOfUsed}
                type="string"
                label="Days Of Used"
                variant="standard"
                size="small"
                InputLabelProps={{shrink: true}}
                onChange={handleChangeDaysOfUsed}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" spacing={2}>
                    <DesktopDatePicker
                        label="Start Date"
                        inputFormat="YYYY-MM-DD"
                        value={startDate}
                        onChange={handleChangeStartDate}
                        renderInput={(parms) => <TextField
                            {...parms}
                        />}
                    />
                    <DesktopDatePicker
                        label="Expired Date"
                        inputFormat="YYYY-MM-DD"
                        value={expiredDate}
                        onChange={handleChangeExpiredDate}
                        renderInput={(parms) => <TextField
                            {...parms}
                        />}
                    />
                </Stack>
            </LocalizationProvider>

            <FormControlLabel
                control={<Checkbox checked={isUsed} onClick={handleCheckIsUsed}/>}
                label="Is Used"
            />

            {/*
            <TextField
                id="standard-basic"
                label="Remarks"
                variant="standard"
                size="small"
                InputLabelProps={{shrink: true}}
                value={remarks}
                onChange={handleChangeRemarks}
            />
            */}

            <Button variant="contained" onClick={handleSave}>Save</Button>
            <Button onClick={handleCancel}>Cancel</Button>
        </Stack>
    );
}
