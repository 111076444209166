import {Avatar, Box, Card, CardContent, Grid, IconButton, Stack, Typography, useTheme,} from "@mui/material";

import BedroomChildIcon from "@mui/icons-material/BedroomChild";
import EditIcon from '@mui/icons-material/Edit';
import {deepPurple, grey} from "@mui/material/colors";

interface Props {
    data: any;
    page: number;
    rowsPerPage: number;
    handleModalOpen: () => void;
    handleClick: any;
}

export default function RoomCardLayout(props: Props) {

    const theme = useTheme()

    return (
        <Box
            sx={{
                mt: 5,
            }}
        >
            <Grid
                container
                spacing={3}
                sx={{
                    mb: 1,
                }}
            >
                {props.data.length ? (
                    props.data
                        .slice(
                            props.page * props.rowsPerPage,
                            props.page * props.rowsPerPage + props.rowsPerPage
                        )
                        .map((row: any, index: number) => {
                            return (
                                <Grid item xs={4} key={index}>
                                    <Card sx={{
                                        minWidth: 275, borderRadius: 4, borderLeft: "3px solid #5FB404",
                                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px '
                                    }}>
                                        <CardContent sx={{position: "relative"}}>
                                            <IconButton
                                                aria-label="settings"
                                                sx={{position: "absolute", top: 2, right: 2}}
                                                onClick={(event) => {
                                                    props.handleClick(event, row.roomNumber)
                                                    props.handleModalOpen();
                                                }}
                                            >
                                                <EditIcon sx={{fontSize: 16}}/>
                                            </IconButton>
                                            <Stack
                                                direction="row"
                                                spacing={3}
                                                sx={{alignItems: "center"}}
                                            >
                                                <Avatar
                                                    sx={{
                                                        width: 40,
                                                        height: 40,
                                                        bgcolor: deepPurple[300],
                                                        p: 1,
                                                    }}
                                                >
                                                    {`${row.roomNumber.charAt(0)}${row.roomNumber.charAt(1)}`}
                                                </Avatar>
                                                <Box>
                                                    <Typography
                                                        fontWeight="bold"
                                                        sx={{letterSpacing: 4}}
                                                    >
                                                        {row.roomNumber}
                                                    </Typography>
                                                    <Stack direction="row" spacing={1} sx={{my: 1}}>
                                                        <BedroomChildIcon color="info"/>
                                                        <Stack>
                                                            <Typography variant="subtitle2">
                                                                {row.roomType}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                    <Typography variant="subtitle2" color="#6E6E6E">
                                                        Max : {row.maxPerson} person
                                                    </Typography>
                                                    <Typography variant="subtitle2" sx={{fontWeight: 'bold'}}>
                                                        {`${row.bedType}`}
                                                    </Typography>

                                                </Box>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })
                ) : (
                    <Box
                        sx={{
                            width: "100%",
                            minHeight: '200px',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{
                            width: "60%",
                            minHeight: '80%',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: grey[50],
                        }}>
                            <Typography sx={{color: theme.palette.mode === 'dark' ? '#000' : '#000'}}> No data
                                found</Typography>
                        </Box>
                    </Box>
                )}
            </Grid>
        </Box>
    );
}
