import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Grid,
    Link,
    Paper,
    Stack,
    TextField,
    Typography,
    Divider,
    Autocomplete,
    Chip
} from "@mui/material";
import React from 'react';

import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';

import { DataStore, Predicates } from "@aws-amplify/datastore";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import dayjs from "dayjs";
import { Cars, Customers, FlightTime, Reservations, ReservationsCars, RoomHistory, Rooms, CashFlow, ReservationsCondition } from "../../models";

import CreateTransfer from './CreateTransfer'




const ResvTimeLine = ({end = true, label}: { end?: boolean, label?: string }) => (
    <TimelineItem>
        <TimelineSeparator>
            <TimelineDot/>
            {!end && <TimelineConnector/>}
        </TimelineSeparator>
        <TimelineContent sx={{
            color: "#000"
        }}>{label}</TimelineContent>
    </TimelineItem>
)

 enum ReservationsStatus {
    NOCHECK="nocheck",
    CHECKIN = "checkin",
    CHECKOUT = "checkout",
    REMARKS = "remarks",
  }

export default function BookingDetail({data}: any) {
    const [transfers, setTransfers] = React.useState<RoomHistory[]>([])
    const [flightTimes, setFlightTimes] = React.useState<FlightTime[]>([])
    const [cashFlow, setCashFlow] = React.useState<CashFlow[]>([])
    const [car, setCar] = React.useState<Cars>()
    const [remark, setRemark] = React.useState<string>(data.reservations?.Remarks)
    const [checkStatus, setCheckStatus] = React.useState<ReservationsStatus>();
    const [fromRoom, setFromRoom] = React.useState<Rooms|null>(null)
    const [toRoom, setToRoom] = React.useState<Rooms | null>(null);
    const [resvState, setResvState] = React.useState<ReservationsCondition>(data.reservations?.Condition)
    
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [btnLabel, setBtnLabel] = React.useState<string>("Submit")

    
    const getCheckStatus = () => {
        if(!data.reservations.isCheckIn && data?.reservations.isCheckOut){
            setCheckStatus(ReservationsStatus.CHECKIN)
        } else if (data.reservations.isCheckIn && !data.reservations.isCheckOut) {
            setCheckStatus(ReservationsStatus.CHECKIN)
        } else if (!data.reservations.isCheckIn && !data.reservations.isCheckOut) {
            setCheckStatus(ReservationsStatus.CHECKOUT)
        } else if (data.reservations.isCheckIn && data.reservations.isCheckOut) {
            setCheckStatus(ReservationsStatus.REMARKS)
        }
    }
    
    let resvStateOptions = [
        ReservationsCondition.PENDING,
        ReservationsCondition.BOOKED,
        ReservationsCondition.CONFIRM,
        ReservationsCondition.CANCELLED,
    ]
    

    React.useEffect(() => {
      const subscription: any = DataStore.observeQuery(CashFlow, (c) => c.id("eq", data.reservations?.reservationsCashFlowId)).subscribe((snapshot) => {
            setCashFlow(snapshot.items)
      })
      return () => subscription.unsubscribe();
    }, [])
    
    
    React.useEffect(() => {
      const subscription: any = DataStore.observeQuery(FlightTime, (c) => c.reservationsID("eq", data.reservationsID)).subscribe((snapshot) => {
        setFlightTimes(snapshot.items)
      })
      getCheckStatus();
      return () => subscription.unsubscribe();
    }, [])
    
    
    React.useEffect(() => {
      const subscription: any = DataStore.observeQuery(ReservationsCars, Predicates.ALL).subscribe((snapshot) => {
        let currentCar = snapshot.items.filter(c => c.reservations.id === data.reservationsID)
        setCar(currentCar[0].cars)
      })
      return () => subscription.unsubscribe();
    }, [])
    
    /*
    const [customers, setCustomers] = React.useState<Customers[]>([])

    React.useEffect(() => {
      const subscription: any = DataStore.observeQuery(Customers, (c) => c.reservationsID("eq", data.id)).subscribe((snapshot) => {
        setCustomers(snapshot.items)
      })
      return () => subscription.unsubscribe();
    }, [])

    */

    React.useEffect(() => {
        // must use clean up function to unsubscribe from the subscription when the component unmounts.
        const subscription: any = DataStore.observeQuery(RoomHistory, Predicates.ALL).subscribe((snapshot) => {
            setTransfers(snapshot.items)
            console.log(snapshot.items, "His")
        })
        return () => subscription.unsubscribe()
    }, [toRoom?.roomNumber])

    const getPriceType = () => {
        if (data.reservations?.isGeneral) return "General"
        if (data.reservations?.isTour) return "Tour"
        if (data.reservations?.isMembership) return "Membership"
        return "-"
    }

   

    const getChange = (id: string) => {
        let to_room = transfers.filter((tran: RoomHistory) => tran.Reservations.id === id).map((resv) => resv.to)
        if (to_room) return to_room
        return null
    }

    const customers = [data.customers, data.customers2].filter(c => c)
    
    const handleChangeResvState = (event: React.SyntheticEvent, value: string | null): void => {
        if (value) setResvState(value as ReservationsCondition)
    }
    

    async function handleCheckIn(){
        const original = await DataStore.query(Reservations, data.reservations?.id)  // this is because of re-fetching, to check our data is created.
            if (original !== undefined) {
                await DataStore.save(
                    Reservations.copyOf(original, updated => {
                        updated.isCheckIn = true
                        updated.checkIn = dayjs().format("YYYY-MM-DD")
                    })
                ).then(
                    (value) => { setCheckStatus(ReservationsStatus.CHECKIN) },
                    (reason) => { console.warn(reason) },
                )
            }
    }

    async function handleCheckOut(){
        const original = await DataStore.query(Reservations, data.reservations?.id)  // this is because of re-fetching, to check our data is created.
            if (original !== undefined) {
                await DataStore.save(
                    Reservations.copyOf(original, updated => {
                        updated.isCheckOut = true
                        updated.checkOut = dayjs().format("YYYY-MM-DD")
                    })
                ).then(
                    (value) => { setCheckStatus(ReservationsStatus.REMARKS) },
                    (reason) => { console.warn(reason) },
                )
            }
    }
    
    const updateRemark = async () => {
        const original = await DataStore.query(Reservations, data.reservations.id)  // this is because of re-fetching, to check our data is created.
        if (original !== undefined) {
            await DataStore.save(
                Reservations.copyOf(original, updated => {
                    updated.Remarks = remark
                })
            )
            console.log("Done! remarks")
        }
    }
    
    const handleChangeRemark = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value
        setRemark(value)
    }
    
    const handleClikcResvState = async (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsLoading(true)
        setBtnLabel("Updating")
        const original = await DataStore.query(Reservations, data.reservations.id)
        try {
            if (original) {
                const resv = await DataStore.save(
                    Reservations.copyOf(original, updated => {
                        updated.Condition = resvState
                    })
                )
                if (resv) {
                    setIsLoading(false)
                    setBtnLabel("Success")
                }
            }
        } catch (err) {
            console.log("ERR updated Condition", err)
            setIsLoading(false)
            setBtnLabel("Failed")
        }
    }

    return (
        <Box sx={{width: '100%'}}>
            <Stack>
                <Typography sx={{fontWeight: 'bold', textAlign: 'center'}}>ReservationID : {data.reservationsID?.slice(-4)}</Typography>
                {data.reservations?.Condition === 'BOOKED' && (
                    <Stack direction="row" spacing={1} sx={{justifyContent: 'center'}}>
                        <CheckIcon sx={{color: '#3ADF00', fontSize: 24, fontWeight: 'bold'}}/>
                        <Typography sx={{color: '#3ADF00', fontSize: 16, fontWeight: 'bold'}}>Booked</Typography>
                    </Stack>
                )}
            </Stack>
            <Grid container spacing={2} sx={{p: 2}}>
                <Grid item xs={2}>
                    <Stack direction="column" spacing={3}>
                        <Stack direction="column" spacing={1}>
                            <Autocomplete
                                value={resvState}
                                onChange={handleChangeResvState}
                                id="size-small-filled"
                                size="small"
                                options={resvStateOptions}
                                getOptionLabel={(option) => option}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            variant="outlined"
                                            label={option}
                                            size="small"
                                            {...getTagProps({index})}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Condition"
                                        placeholder="Condition"
                                        size="small"
                                        InputLabelProps={{shrink: true}}
                                        required
                                    />
                                )}
                            />
                            <Button 
                                disabled={isLoading}
                                variant="contained" 
                                fullWidth 
                                onClick={handleClikcResvState}
                            >{btnLabel}</Button>
                        </Stack>
                        
                        <Divider />
                        
                        <Stack spacing={1}>
                            <Typography sx={{fontSize: 14, fontWeight: 'bold'}}> CheckIn : {data.reservations?.checkIn}</Typography>
                            <Typography sx={{fontSize: 14, fontWeight: 'bold'}}> CheckOut : {data.reservations?.checkOut}</Typography>
                        </Stack>
                        <Stack spacing={1} sx={{my: 2}}>
                            <Typography sx={{fontSize: 14, fontWeight: 'bold'}}>Car
                                Number: {car?.carNumber}</Typography>
                        </Stack>
                        <Stack spacing={1} sx={{mb: 2}}>
                            <Typography sx={{fontSize: 14, fontWeight: 'bold'}}>Price Type
                                : {getPriceType()}</Typography>
                        </Stack>
                        <Stack spacing={1} sx={{mb: 2}}>
                            <Typography sx={{fontSize: 14, fontWeight: 'bold'}}>Deposite Amount
                                : {cashFlow[0] ? cashFlow[0].depositAmount : "loading..."}</Typography>
                        </Stack>
                        <Stack spacing={1} sx={{mb: 2}}>
                            <Typography sx={{fontSize: 14, fontWeight: 'bold'}}>Room Number
                                : {fromRoom?.roomNumber}</Typography>
                        </Stack>
                        
                        <Divider />
                         
                        <Stack direction='row' spacing={1} sx={{alignItems: 'center'}}>
                            <Typography variant="subtitle1" sx={{fontSize: 13, fontWeight: 'bold'}}>
                                IsCheckin :
                            </Typography>
                            {data.reservations?.isCheckIn ? <CheckIcon sx={{color: 'green'}}/> : <CloseIcon sx={{color: 'red'}}/>}
                        </Stack>
                        <Stack direction='row' spacing={1} sx={{alignItems: 'center'}}>
                            <Typography variant="subtitle1" sx={{fontSize: 13, fontWeight: 'bold'}}>
                                IsCheckOut :
                            </Typography>
                            {data.reservations?.isCheckOut ? <CheckIcon sx={{color: 'green'}}/> : <CloseIcon sx={{color: 'red'}}/>}
                        </Stack>
                        
                        <Divider />
                        
                        <CreateTransfer 
                            reservation={data.reservations} 
                            toRoom={toRoom} setToRoom={setToRoom}
                            fromRoom={fromRoom} setFromRoom={setFromRoom}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={7}>
                    <Box>
                        <Paper sx={{p: 2, m: 2, minHeight: '70vh'}}>
                            <Typography sx={{fontWeight: 'bold', mb: 1}}>Guest Lists</Typography>
                            <Grid container spacing={2}>

                                {/* Customer Data */}
                                {customers?.map((cust: Customers, index: number) => (
                                    <Grid item xs={6} key={index}>
                                        <Card
                                            sx={{
                                                minWidth: 275,
                                                borderRadius: '8px',
                                                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                                                // bgcolor: theme.palette.mode === 'dark' ? '#0C090A' : "#fff",
                                            }}>
                                            <CardContent>
                                                <Stack direction="row" sx={{alignItems: 'center'}} spacing={2}>
                                                    <Avatar
                                                        sx={{
                                                            width: 30,
                                                            height: 30,
                                                            bgcolor: "#f2f2f2",
                                                            p: 1,
                                                        }}
                                                    >
                                                        <PersonIcon sx={{color: 'appBar'}}/>
                                                    </Avatar>
                                                    <Stack>
                                                        <Stack direction='row' spacing={1}>
                                                            <Typography>{cust.firstName} {cust.lastName} ,</Typography>
                                                            <Typography sx={{
                                                                bgcolor: 'skyblue',
                                                                borderRadius: 1,
                                                                letterSpacing: 1,
                                                                px: 1,
                                                                fontSize: 14,
                                                                fontWeight: 'bold'
                                                            }}>
                                                                {cust.Passport}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction="row" spacing={1}>
                                                            <Link component="a" sx={{fontSize: 12, color: '#0080FF'}}
                                                                  href={`mailto:${data.customer_email}`}>{cust.Email}</Link>
                                                            <Link component="a" sx={{
                                                                fontSize: 12,
                                                                color: '#0174DF',
                                                                textDecoration: 'none'
                                                            }} href={`tel:${cust.Phone}`}> , {cust.Phone}</Link>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                                {flightTimes.length ? (
                                                    <Stack divider={<Divider orientation="vertical" flexItem/>}
                                                           direction="row" sx={{justifyContent: 'space-around', my: 2}}>
                                                        <Stack>
                                                            <Typography
                                                                fontWeight="bold"
                                                                sx={{textAlign: 'center', color: '#6698FF'}}
                                                            >
                                                                Arrival
                                                            </Typography>
                                                            <Typography variant="subtitle2"
                                                                        sx={{fontSize: 11, fontWeight: 'bold'}}>
                                                                Flight : {flightTimes[index].arrivalAirline}
                                                            </Typography>
                                                            <Typography variant="subtitle2"
                                                                        sx={{fontSize: 11, fontWeight: 'bold'}}>
                                                                Date : {flightTimes[index].arrivalDate}
                                                            </Typography>
                                                            <Typography variant="subtitle2"
                                                                        sx={{fontSize: 11, fontWeight: 'bold'}}>
                                                                Time
                                                                : {dayjs(flightTimes[index].arrivalTime).format('HH : mm A')}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack>
                                                            <Typography
                                                                fontWeight="bold"
                                                                sx={{textAlign: 'center', color: '#6698FF'}}
                                                            >
                                                                Departure
                                                            </Typography>
                                                            <Typography variant="subtitle2"
                                                                        sx={{fontSize: 11, fontWeight: 'bold'}}>
                                                                Flight : {flightTimes[index].departureAirline}
                                                            </Typography>
                                                            <Typography variant="subtitle2"
                                                                        sx={{fontSize: 11, fontWeight: 'bold'}}>
                                                                Date : {flightTimes[index].departureDate}
                                                            </Typography>
                                                            <Typography variant="subtitle2"
                                                                        sx={{fontSize: 11, fontWeight: 'bold'}}>
                                                                Time
                                                                : {dayjs(flightTimes[index].departureTime).format('HH : mm A')}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                ) : (
                                                    <Stack>
                                                        <Stack>
                                                            <Typography
                                                                fontWeight="bold"
                                                                sx={{textAlign: 'center', color: '#6698FF'}}
                                                            >
                                                                Arrival
                                                            </Typography>
                                                            <Typography
                                                                fontWeight="bold"
                                                                sx={{textAlign: 'center', color: '#6698FF'}}
                                                            >
                                                                Empty!
                                                            </Typography>
                                                        </Stack>
                                                        <Stack>
                                                            <Typography
                                                                fontWeight="bold"
                                                                sx={{textAlign: 'center', color: '#6698FF'}}
                                                            >
                                                                Departure
                                                            </Typography>
                                                            <Typography
                                                                fontWeight="bold"
                                                                sx={{textAlign: 'center', color: '#6698FF'}}
                                                            >
                                                                Empty!
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                )}
                                            </CardContent>
                                            </Card>
                                    </Grid>
                                ))}


                               
                            </Grid>
                               {checkStatus === ReservationsStatus.CHECKIN ?  <Button variant="contained" fullWidth sx={{my:2}} onClick={handleCheckOut}>Check Out</Button> :  null} 
                               {checkStatus === ReservationsStatus.CHECKOUT ?  <Button variant="contained" fullWidth sx={{my:2}} onClick={handleCheckIn}>Check In</Button> :  null} 
                               {checkStatus === ReservationsStatus.REMARKS ?  (
                                    <Stack spacing={2} mt={5}>
                                        <TextField 
                                            multiline
                                            disabled={data.reservations?.Remarks}
                                            rows={4}
                                            label="Customer Feedback"
                                            value={remark}
                                            onChange={handleChangeRemark}
                                        />
                                        <Button disabled={data.reservations?.Remarks} variant="contained" fullWidth onClick={updateRemark}>Submit</Button>
                                    </Stack>
                                ) : (
                                    null
                                )} 
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{py: 3, backgroundColor: '#FAFAFA', borderRadius: '8px'}}>
                        <Typography sx={{textAlign: 'center', fontWeight: 'bold'}}> Reservation Timeline </Typography>
                        <Timeline>
                            {data.reservations?.isCheckIn ? <ResvTimeLine end={false} label="Check In"/> : null}
                            <ResvTimeLine end={false} label={fromRoom?.roomNumber}/>

                            {/* is Move */}
                            {getChange(data.reservations?.id)?.map((room, index, row) => (
                                <ResvTimeLine label={room} end={checkIsEnd(row, index, data.reservations?.isCheckOut)} key={index}/>
                            ))}

                            {data.reservations?.isCheckOut ? <ResvTimeLine label="Check Out"/> : null}
                        </Timeline>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}


const checkIsEnd = (row: any, i: number, isDone?: boolean) => {
    if (row.length === (i + 1) && !isDone) return true
    return false
}
