import {AppBar, Stack, Toolbar, Typography} from '@mui/material';
import {Auth} from 'aws-amplify';
import React from 'react';
import {drawerWidth} from '../../constant';
import ImageAvatars from '../Avatar';


interface HeaderProps {
    shown: boolean;
}


export default function Header(props: HeaderProps) {


    const [userName, setUserName] = React.useState<String>('');


    async function getUserInfo() {
        const user = await Auth.currentAuthenticatedUser();
        // console.log('attributes:', user.attributes);
        setUserName(user.attributes.email.substring(0, user.attributes.email.indexOf('@')));
    }

    React.useEffect(() => {
        getUserInfo();
    }, [])


    return (
        <AppBar
            position="fixed"
            enableColorOnDark={true}
            sx={{
                width: {sm: `calc(100% - ${drawerWidth}px)`},
                ml: {sm: `${drawerWidth}px`},
                bgcolor: "appBar",
            }}
        >
            <Toolbar
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}
            >

                {/* <Stack direction="row" spacing={1}>
					<DatePicker />
					</Stack> */}
                <Stack
                    direction='row'
                    alignItems="center"
                    spacing={2}
                >
                    <ImageAvatars num={1}/>
                    <Stack
                        direction="column"
                        spacing={-1}
                        sx={{
                            "& .MuiInputBase-root": {
                                color: "#fff"
                            },

                            "& .MuiSvgIcon-root": {
                                color: "#fff"
                            }
                        }}
                    >
                        <Typography variant="h6">{userName}</Typography>
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    )
}
	



