import { Typography } from "@mui/material";

import { DataStore } from "@aws-amplify/datastore";
import React from "react";
import { ReservationsRooms } from "../../models";

interface Props {
    reservationsID: any;
    reload: boolean
}

export default function BookingRoomText(props: Props) {
    
    const [reservationsCustomers, setreservationsCustomers] = React.useState<any>([])

    
    React.useEffect(
        () => {
        async function FetchRooms() {
            const rooms = (await DataStore.query(ReservationsRooms)).filter(c => c.reservations.id === props.reservationsID);
            setreservationsCustomers(rooms[0])
        }
        FetchRooms()
        },
        [props.reload]
      )

      if(!reservationsCustomers) return <>Sorry! unable to get this room</>

      return (
        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
        {reservationsCustomers?.reservations?.checkIn}, {` ROOM NO. ${reservationsCustomers?.rooms?.roomNumber}`}
    </Typography>
        
    )
}