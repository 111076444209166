import {Box, Card, CardContent, Chip, Divider, Grid, IconButton, Stack, Typography, useTheme,} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CheckIcon from '@mui/icons-material/Check';

import dayjs from "dayjs";
import React from "react";

interface Props {
    data: any;
    page: number;
    rowsPerPage: number;
    handleModalOpen: () => void;
    handleClick: any;
}

export default function MembershipCardLayout(props: Props) {
    const theme = useTheme()
    let rows: any = []

    props.data.forEach(async (m: any) => {
        if (rows.find((row: any) => row.membershipId === m.membership.id)) {
            let toAdd = rows.find((row: any) => row.membershipId === m.membership.id)
            if (toAdd) toAdd.customers2 = m.customers
        } else {
            rows.push({
                ...m,
                customers2: "",
                membershipId: m.membership.id
            })
        }
    })


    return (
        <Box sx={{mt: 2}}>
            <Grid
                container
                spacing={3}
                sx={{mb: 1}}
            >
                {rows ? (
                    rows.slice(
                        props.page * props.rowsPerPage,
                        props.page * props.rowsPerPage + props.rowsPerPage
                    ).map((row: any, index: number) => {

                        const bgColor = theme.palette.mode === "dark" ? "#A9E2F3" : '#f2f2f2';
                        const color = theme.palette.mode === "dark" ? "#000" : 'red';
                        console.log('row', row);

                        return (
                            <Grid item xs={3} key={index}>
                                <Card sx={{
                                    borderRadius: '8px',
                                    minWidth: '100px',
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                                    backgroundColor: theme.palette.mode === 'dark' ? '#0C090A' : "#fff",
                                }}>
                                    <CardContent sx={{position: "relative"}}>
                                        <IconButton
                                            aria-label="settings"
                                            sx={{position: "absolute", top: 2, right: 2}}
                                            onClick={(event) => {
                                                props.handleClick(event, row?.id)
                                                props.handleModalOpen();
                                            }}
                                        >
                                            <EditIcon sx={{fontSize: 16}}/>
                                        </IconButton>
                                        {/* <Button
                                            aria-label="settings"
                                            sx={{ position: "absolute", bottom: 2, right: 2, fontSize: 10, color: 'green' }}
                                            onClick={() => {
                                                toggle();
                                                setSelected(row);
                                            }}
                                        >
                                            {open && selected.id === row.id ? 'Collapse' : 'Expand'}
                                        </Button> */}
                                        <Stack direction="row" spacing={3} sx={{alignItems: 'center'}}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor: bgColor,
                                                    borderRadius: 100,
                                                    height: '50px',
                                                    width: '50px'
                                                }}>
                                                <CardMembershipIcon sx={{color: color}}/>
                                            </Box>
                                            <Stack>
                                                <Typography
                                                    sx={{fontWeight: "bold", fontSize: 14}}>Membership ID: {row.membershipId.slice(-4)}</Typography>
                                                <Stack direction="row" spacing={1} sx={{mb: 1, alignItems: 'center'}}>
                                                    <Typography
                                                        sx={{fontWeight: "bold", fontSize: 14,}}>Valid</Typography>
                                                    <Typography sx={{
                                                        fontWeight: "500",
                                                        fontSize: 14
                                                    }}> : {dayjs().format('YY-MM-DD')}</Typography>
                                                    <Typography sx={{
                                                        fontWeight: "500",
                                                        fontSize: 14
                                                    }}>- {dayjs().format('YY-MM-DD')}</Typography>
                                                </Stack>
                                                <Typography sx={{fontWeight: "500", fontSize: 14}}>Total
                                                    : {row.membership.totalDays} days</Typography>
                                                <Typography sx={{
                                                    fontWeight: "500",
                                                    fontSize: 14
                                                }}>{row.membership.remainingDays} days remaining</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack divider={<Divider orientation="vertical" flexItem/>} direction="row"
                                               sx={{justifyContent: 'space-around', my: 2}}>
                                            <Stack>
                                                <Typography
                                                    fontWeight="bold"
                                                    sx={{textAlign: 'center', color: '#6698FF', fontSize: 14}}
                                                >
                                                    Member 1
                                                </Typography>
                                                <Typography sx={{
                                                    fontWeight: "500",
                                                    fontSize: 14
                                                }}>{row.customers.firstName} {row.customers.lastName}</Typography>
                                            </Stack>
                                            {row.customers2 && (
                                                <Stack>
                                                    <Typography
                                                        fontWeight="bold"
                                                        sx={{textAlign: 'center', color: '#6698FF', fontSize: 14}}
                                                    >
                                                        Member 2
                                                    </Typography>
                                                    <Typography sx={{fontSize: 14, fontWeight: '500'}}>
                                                        {row.customers2.firstName} {row.customers2.lastName}
                                                    </Typography>
                                                </Stack>
                                            )}
                                        </Stack>
                                        <Stack direction="row" sx={{alignItems: 'center'}} spacing={1}>
                                            <Typography sx={{fontSize: 14}}>Day of use :</Typography>
                                            <Chip
                                                label={`${row.membership.totalUsedDays} day`}
                                                sx={{m: 1, cursor: "pointer"}}
                                                color='primary'
                                            />
                                            <Stack direction='row' spacing={1} sx={{alignItems: 'center'}}>
                                                <Typography variant="subtitle1" sx={{fontSize: 13, fontWeight: 'bold'}}>
                                                    Is Used :
                                                </Typography>
                                                {row.membership.isUsed ? <CheckIcon sx={{color: 'green'}}/> :
                                                    <CloseIcon sx={{color: 'red'}}/>}
                                            </Stack>
                                        </Stack>


                                        {/* {open && selected.id === row.id &&  (
                                            <Stack spacing={1} sx={{justifyContent : 'center', alignItems:'center'}}>
                                                <Divider />
                                                <Typography>Members</Typography>
                                                {selected.person1 && <Typography sx={{ fontWeight: "500", fontSize: 14 }}>1. {selected.person1}</Typography>}
                                                {selected.person1 && <Typography sx={{ fontWeight: "500", fontSize: 14 }}>2. {selected.person1}</Typography>}
                                            </Stack>
                                                )} */}
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })
                ) : (
                    <Box
                        sx={{
                            width: "100%",
                            minHeight: '200px',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 5
                        }}
                    >
                        <Box sx={{
                            width: "60%",
                            minHeight: '80%',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: theme.palette.mode === 'dark' ? '#0C090A' : "#f2f2f2",
                        }}>
                            <Typography> No data found</Typography>
                        </Box>
                    </Box>
                )}
            </Grid>
        </Box>
    );
}