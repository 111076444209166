import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {CircularProgress, Grid} from '@mui/material'
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import dayjs, {Dayjs} from "dayjs";

import {DataStore} from 'aws-amplify';
import {
    Cars,
    ReservationsCars,
    CashFlow,
    CountryType,
    Customers,
    CustomersMembership,
    Membership,
    ReservationsCustomers,
    FlightTime,
    GenderType,
    Reservations,
    ReservationsCondition,
    Rooms,
    ReservationsRooms,
    SeasonalPrice
} from '../../models';

import {getDatesInRange} from "../../constant";
import CustomTimeline from "../Timeline";

const steps = ["Step 1", "Step 2", "Step 3"];

export default function ReservationForm() {


    const [activeStep, setActiveStep] = React.useState(0);
    const [checkInDate, setCheckInDate] = React.useState<Dayjs>(dayjs());
    const [checkOutDate, setCheckOutDate] = React.useState<Dayjs>(dayjs().add(1, 'day'));
    const [personCount, setPersonCount] = React.useState<{ label: string, value: number }>({
        label: "1 Adult",
        value: 0
    });
    const [room, setRoom] = React.useState<Rooms | null>(null);
    const [car, setCar] = React.useState<Cars | null>(null);
    // Customer Creation
    const [firstNames, setFirstNames] = React.useState<string[]>([])
    const [lastNames, setLastNames] = React.useState<string[]>([])
    const [emails, setEmails] = React.useState<string[]>([])
    const [passports, setPassports] = React.useState<string[]>([])
    const [phones, setPhones] = React.useState<string[]>([])
    const [birthdays, setBirthdays] = React.useState<Dayjs[]>([])
    const [countrys, setCountrys] = React.useState<CountryType[]>([])

    // if membership get ID directly.
    // const [customerIDs, setCustomerIDs] = React.useState<Customers[]>([])
    const [custMems, setCustMems] = React.useState<CustomersMembership[]>([])

    // for step three
    const [isPaied, setIsPaied] = React.useState<boolean>(false)
    const [depositAmount, setDepositAmount] = React.useState<number>(0)

    // arrival
    const [arrivalAirlines, setArrivalAirlines] = React.useState<string[]>([]);
    const [arrivalDates, setArrivalDates] = React.useState<Dayjs[]>([dayjs(), dayjs()]);
    const [arrivalTimes, setArrivalTimes] = React.useState<Dayjs[]>([dayjs(), dayjs()]);

    // departure
    const [departureAirlines, setDepartureAirlines] = React.useState<string[]>([]);
    const [departureDates, setDepartureDates] = React.useState<Dayjs[]>(
        [dayjs(), dayjs()]
    );
    const [departureTimes, setDepartureTimes] = React.useState<Dayjs[]>(
        [dayjs(), dayjs()]
    );

    // for check, data are there
    const [isEmptyRoom, setIsEmptyRoom] = React.useState<boolean>(false)
    const [isEmptyCar, setIsEmptyCar] = React.useState<boolean>(false)


    // for seasonal price
    const [priceType, setPriceType] = React.useState<string>("general")
    // const [passportUniqueErr, setPassportUniqueErr] = React.useState<boolean>(false)

    // const [iUUID, setiUUID] = React.useState<string>("")
    const [resvID, setResvID] = React.useState<string>("")

    const [loading, setLoading] = React.useState(false);
    // const [success, setSuccess] = React.useState(false);

    // const timer = React.useRef<number>();


    const [seasonalPrices, setSeasonalPrices] = React.useState<SeasonalPrice[]>([])


    React.useEffect(() => {
        const subscription: any = DataStore.observeQuery(SeasonalPrice).subscribe((snapshot) => setSeasonalPrices(snapshot.items));
        return () => subscription.unsubscribe();
    }, [])


    const getDuringDays = () => {
        let total_days = checkOutDate.diff(checkInDate, "day", false) + 1
        console.log("total_day", total_days)
        return total_days
    }
    
    const getCurrentPrice = () => {
        if (checkInDate) {
            const daysBetween = getDatesInRange(checkInDate, checkOutDate);

            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            // Write type for here
            const currentPrice: (any)[] = [];

            daysBetween.forEach(function (date) {
                currentPrice.push(seasonalPrices?.find(p => p.Months.toLowerCase() === monthNames[date.getMonth()].toLowerCase()))
            });

            if (currentPrice && checkInDate) {
                if (priceType === "general") {
                    let sum = 0;
                    currentPrice.forEach(value => sum += value?.General);
                    // const finalPrice = sum - currentPrice[0].General;
                    // return finalPrice * (personCount.value + 1)
                    return sum * (personCount.value + 1)
                }
                if (priceType === "tour") {
                    let sum = 0;
                    currentPrice.forEach(value => sum += value?.Tour);
                    // const finalPrice = sum - currentPrice[0]?.Tour;
                    // return finalPrice * (personCount.value + 1)
                    return sum * (personCount.value + 1)
                }
                if (priceType === "membership") {
                    let sum = 0;
                    currentPrice.forEach(value => sum += value?.Membership);
                    // const finalPrice = sum - currentPrice[0]?.Membership;
                    // return finalPrice * (personCount.value + 1)
                    return sum * (personCount.value + 1)
                }
            }
        }
        return 0
    }


    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <StepOne
                        personCount={personCount}
                        checkInDate={checkInDate}
                        checkOutDate={checkOutDate}
                        room={room}
                        car={car}
                        setPersonCount={setPersonCount}
                        setCheckInDate={setCheckInDate}
                        setCheckOutDate={setCheckOutDate}
                        setRoom={setRoom}
                        setCar={setCar}
                        priceType={priceType} setPriceType={setPriceType}
                        // to check data is there
                        isEmptyRoom={isEmptyRoom} setIsEmptyRoom={setIsEmptyRoom}
                        isEmptyCar={isEmptyCar} setIsEmptyCar={setIsEmptyCar}
                        
                        // Flight Time
                        arrivalAirlines={arrivalAirlines}
                        setArrivalAirlines={setArrivalAirlines}
                        arrivalDates={arrivalDates}
                        setArrivalDates={setArrivalDates}
                        arrivalTimes={arrivalTimes}
                        setArrivalTimes={setArrivalTimes}
                        departureAirlines={departureAirlines}
                        setDepartureAirlines={setDepartureAirlines}
                        departureDates={departureDates}
                        setDepartureDates={setDepartureDates}
                        departureTimes={departureTimes}
                        setDepartureTimes={setDepartureTimes}
                    />
                );
            case 1:
                return (
                    <StepTwo
                        personCount={personCount.value}
                        firstNames={firstNames} setFirstNames={setFirstNames}
                        lastNames={lastNames} setLastNames={setLastNames}
                        emails={emails} setEmails={setEmails}
                        phones={phones} setPhones={setPhones}
                        birthdays={birthdays} setBirthdays={setBirthdays}
                        passports={passports} setPassports={setPassports}
                        countrys={countrys} setCountrys={setCountrys}
                        /*
                        arrivalAirlines={arrivalAirlines}
                        setArrivalAirlines={setArrivalAirlines}
                        arrivalDates={arrivalDates}
                        setArrivalDates={setArrivalDates}
                        arrivalTimes={arrivalTimes}
                        setArrivalTimes={setArrivalTimes}
                        departureAirlines={departureAirlines}
                        setDepartureAirlines={setDepartureAirlines}
                        departureDates={departureDates}
                        setDepartureDates={setDepartureDates}
                        departureTimes={departureTimes}
                        setDepartureTimes={setDepartureTimes}
                        */
                        // customerIds={customerIDs} setCustomerIds={setCustomerIDs}
                        custMems={custMems} setCustMems={setCustMems}
                        priceType={priceType}
                    />
                );
            case 2:
                return <StepThree
                    total={getCurrentPrice()}
                    roomData={room}
                    eachDay={getCurrentPrice()}
                    during={getDuringDays()}
                    requestIsPaied={() => setIsPaied(!isPaied)}
                    isPaied={isPaied}
                    depositAmount={depositAmount} setDepositAmount={setDepositAmount}
                />;
            default:
                throw new Error("Unknown step");
        }
    }

    const handleNext = async () => {
        // const UUID = getUUID()
        // setiUUID(UUID)

        // step 1
        if (activeStep === 0) {
            if (room !== null && car !== null) {
                if (room.id && car.id) {
                    setActiveStep((current) => current+1)
                } else {
                    if (!room.id) setIsEmptyRoom(true)
                    if (!car.id) setIsEmptyCar(true)
                }
            }
        }

        // step 2
        if (activeStep === 1) {
            if (priceType !== "membership") {
                setActiveStep((active) => active + 1)
            } else {
                /*
                if (customerIDs.length === 2 && arrivalAirlines.length === 2 && departureAirlines.length === 2) {
                    setActiveStep((active) => active + 1)
                }
                */
                setActiveStep((active) => active + 1)
            }
        }

        // step 3
        if (activeStep === 2) {
            const newCustomers: Customers[] = (priceType !== "membership") ? await createCustomers() : custMems.map((custs: {customers: Customers}) => custs.customers)
            const newFlightTimes = await createFlightTimes(newCustomers)
            const newCashFlow = await createCashFlow()

            // setSuccess(false)
            setLoading(true)

            if (newCustomers.length && newFlightTimes.length && newCashFlow && checkInDate && checkOutDate) {
                try {
                    const {newReservation, reservationCustomers} = await createReservation(newCustomers, newCashFlow, newFlightTimes, checkInDate, checkOutDate)

                    newCustomers.forEach(async cust => {
                        const original = await DataStore.query(Customers, cust.id)
                        if (original && reservationCustomers !== undefined) await DataStore.save(
                            Customers.copyOf(original, updated => {
                                updated.reservationss = reservationCustomers
                            })
                        )
                    })

                    newFlightTimes.forEach(async flight => {
                        const original = await DataStore.query(FlightTime, flight.id)
                        if (original && newReservation !== undefined) await DataStore.save(
                            FlightTime.copyOf(original, updated => {
                                updated.reservationsID = newReservation.id
                            })
                        )
                    })
                    
                    
                    // if membership 
                    if (priceType === "membership") {
                        if (custMems.length) {
                            custMems.forEach(async (mem: {membership: Membership}) => {
                                const original = await DataStore.query(Membership, mem.membership.id)
                                if (original !== undefined) await DataStore.save(
                                    Membership.copyOf(original, updated => {
                                        updated.remainingDays -= getDuringDays()
                                        updated.isUsed = true
                                        // updated.startDate = dayjs().format("YYYY-MM-DD")
                                        updated.totalUsedDays = getDuringDays()
                                    })
                                )
                                console.log("Success Update Membership Remaining Day!")
                            })
                        }
                    }
                    
                    console.log("created New Reservation", newReservation)
                    // setSuccess(true)
                    setResvID(newReservation.id)
                    setLoading(false)
                    setActiveStep((active) => active + 1)
                } catch (err) {
                    console.log("[ ERROR ] failed creating reservation by", err)
                }
            }
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };


    const createCustomers = async (): Promise<Customers[]> => {
        let newCustomers: Customers[] = []

        for (let i = 0; i <= personCount.value; i++) {
            const newCustomer = await DataStore.save(
                new Customers({
                    firstName: firstNames[i],
                    lastName: lastNames[i],
                    Passport: passports[i],
                    Phone: phones[i],
                    Birthday: dayjs(birthdays[i]).format("YYYY-MM-DD"),
                    Country: countrys[i],
                    Email: emails[i],
                    Gender: "MALE" as GenderType,
                })
            )
            newCustomers.push(newCustomer)
        }

        return newCustomers
    }


    const createFlightTimes = async (customers: Customers[]): Promise<FlightTime[]> => {
        let newFlightTimes: FlightTime[] = []

        for (let i = 0; i <= personCount.value; i++) {
            const newFlight = await DataStore.save(
                new FlightTime({
                    arrivalAirline: arrivalAirlines[i],
                    // arrivalDate: arrivalDates[i].format("YYYY-MM-DD"),
                    arrivalDate: arrivalDates[0].format("YYYY-MM-DD"),
                    // arrivalTime: arrivalTimes[i].format("YYYY-MM-DD HH:mm:ssZ"),
                    arrivalTime: arrivalTimes[0].format("YYYY-MM-DD HH:mm:ssZ"),
                    // departureAirline: departureAirlines[i],
                    departureAirline: departureAirlines[0],
                    // departureDate: departureDates[i].format("YYYY-MM-DD"),
                    departureDate: departureDates[0].format("YYYY-MM-DD"),
                    // departureTime: departureTimes[i].format("YYYY-MM-DD HH:mm:ssZ"),
                    departureTime: departureTimes[0].format("YYYY-MM-DD HH:mm:ssZ"),
                    // Customers: customers[i],
                    Customers: customers[0],
                    // flightTimeCustomersId: customers[i].id,
                    flightTimeCustomersId: customers[0].id,
                })
            )
            newFlightTimes.push(newFlight)
        }

        return newFlightTimes
    }


    const createCashFlow = async (): Promise<CashFlow> => {
        const newCashFlow = await DataStore.save(
            new CashFlow({
                totalCost: getDuringDays() * getCurrentPrice(),
                depositAmount: depositAmount,
                totalDuration: getDuringDays(),
                isPaid: isPaied,
                isPartialPaid: false,
            })
        )

        return newCashFlow
    }


    const createReservation = async (customers: Customers[], cashFlow: CashFlow, flightTimes: FlightTime[], checkIn: Dayjs, checkOut: Dayjs) => {
        const newReservation = await DataStore.save(
            new Reservations({
                checkIn: checkIn.format("YYYY-MM-DD"),
                checkOut: checkOut.format("YYYY-MM-DD"),
                isGeneral: priceType === "general" ? true : false,
                // Customers: customers,
                // Rooms: room,
                // Cars: car,
                isTour: priceType === "tour" ? true : false,
                isMembership: priceType === "membership" ? true : false,
                isMove: false,
                isExtend: false,
                Condition: ReservationsCondition.BOOKED,
                isCheckIn: false,
                isCheckOut: false,
                CashFlow: cashFlow,
                checkInTime: checkIn.format("HH:mm:ssZ"),
                checkOutTime: checkOut.format("HH:mm:ssZ"),
                // Remarks?: string | null,
                FlightTimes: flightTimes,
                // reservationsRoomsId?: string | null,
                // reservationsCarsId?: string | null,
                // reservationsCashFlowId?: string | null,
            })
        )
        
        let reservationCustomers: ReservationsCustomers[] = []
        if (room && car) {
            // create ReservationCustomer
            customers.forEach(async (cust: Customers) => {
                const resvCust = await DataStore.save(
                    new ReservationsCustomers({
                        reservations: newReservation,
                        customers: cust
                    })
                )
                reservationCustomers.push(resvCust)
            })
        
            // create ReservationCar
            const resvCar = await DataStore.save(
                new ReservationsCars({
                    reservations: newReservation,
                    cars: car,
                })
            )
        
            // create ReservationRoom
            const resvRoom = await DataStore.save(
                new ReservationsRooms({
                    reservations: newReservation,
                    rooms: room,
                })
            )
        
            // update for add Customers to Reservation
            const original = await DataStore.query(Reservations, newReservation.id)  // this is because of re-fetching, to check our data is created.
            if (original !== undefined && reservationCustomers.length) {
                await DataStore.save(
                    Reservations.copyOf(original, updated => {
                        updated.Customers = reservationCustomers
                        updated.Rooms = [resvRoom]
                        updated.Cars = [resvCar]
                    })
                )
                console.log("Successfull, Creating Reservation", {reservationCustomers, resvCar, resvRoom})
            }
        }
        return {newReservation, reservationCustomers}
    }

    return (
        <Grid container style={{display: "felx", justifyContent: "center"}}>
            <Grid item xs={6}>
                <Container component="main" maxWidth="md" sx={{mb: 4}}>
                    <Paper
                        variant="outlined"
                        sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}
                    >
                        <Typography component="h1" variant="h4" align="center">
                            Reservation
                        </Typography>
                        <Stepper activeStep={activeStep} sx={{pt: 3, pb: 5}}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <React.Fragment>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    <Typography variant="h5" gutterBottom>
                                        Thank you for your reservation.
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Your reservation number is #{resvID.slice(-4)}. We have emailed your
                                        order confirmation.
                                    </Typography>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {getStepContent(activeStep)}
                                    <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                                        {activeStep !== 0 && (
                                            <Button onClick={handleBack} sx={{mt: 3, ml: 1}}>
                                                Back
                                            </Button>
                                        )}
                                        <Box
                                            sx={{m: 1, position: 'relative'}}
                                        >
                                            <Button
                                                variant="contained"
                                                onClick={handleNext}
                                                sx={{mt: 3, ml: 1}}
                                                disabled={
                                                    (activeStep === steps.length - 1 &&
                                                        !(getDuringDays() * getCurrentPrice())) || loading
                                                }
                                            >
                                                {activeStep === steps.length - 1 ? "Confirm" : "Next"}
                                            </Button>
                                            {loading && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </Paper>
                </Container>
            </Grid>
            <Grid item xs={4}>
                <Paper
                    variant="outlined"
                    sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}
                >
                    <Typography component="h1" variant="h4" align="center">
                        Timeline
                    </Typography>
                    <CustomTimeline/>
                </Paper>
            </Grid>
        </Grid>
    );
}


// const getUUID = () => "MIDA-" + Date.now() // +"."+crypto.randomUUID()
