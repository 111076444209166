import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

interface ImageAvatarsProps {
    num: number;
}

export default function ImageAvatars(props: ImageAvatarsProps) {

    const marker = Array(props.num).fill(null);

    return (
        <Stack direction="row" spacing={2}>
            {marker.map((i, index) => (
                <Avatar key={index} alt="Remy Sharp"/>
            ))}
        </Stack>
    );
}
