import {Autocomplete, Button, Chip, Paper, Stack, TextField} from '@mui/material';
import * as React from 'react';

import {DataStore} from '@aws-amplify/datastore';
import {Cars} from '../../models';


interface Props {
    selectedRow: any
    rows: any
    cleanSelected: () => void
    onClose: () => void

}

export default function CreateRoom(props: Props) {

    const [carID, setCarID] = React.useState<string>('') // private
    const [carNumber, setCarNumber] = React.useState<string>('')
    const [carMax, setCarMax] = React.useState<string>("1")  // must be int
    const [carCondition, setCarCondition] = React.useState<boolean>(false)
    const [carRemarks, setCarRemarks] = React.useState<string>("")

    const [isNeedCarNo, setIsNeedCarNo] = React.useState<boolean>(false)
    const [isDisableNum, setIsDisableNum] = React.useState<boolean>(false) // for only update 


    React.useEffect(() => {
        if (props.selectedRow) {
            setIsDisableNum(true)
            setCarID(props.selectedRow.id)
            setCarNumber(props.selectedRow.carNumber)
            setCarMax(props.selectedRow.maxPerson)
            setCarCondition(props.selectedRow.Condition)
            setCarRemarks(props.selectedRow.Remarks)
        } else {
            setIsDisableNum(false)
            setCarID("")
            setCarNumber("")
            setCarMax("2")
            setCarCondition(false)
            setCarRemarks("")
        }
    }, [props.selectedRow])


    const handleSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log("clicke save", props.rows)
        if (carNumber && carID) {
            if (props.rows.find((row: { carNumber: string, id: string }) => (row.carNumber === carNumber) && (row.id === carID))) {
                const original = await DataStore.query(Cars, carID)

                try {
                    if (original === undefined) new Error("Something went wrong! maybe no ID or other.")
                    if (original !== undefined) {
                        await DataStore.save(
                            Cars.copyOf(original, updated => {
                                updated.carNumber = carNumber
                                updated.maxPerson = carMax
                                updated.Condition = carCondition
                                updated.Remarks = carRemarks
                            })
                        )
                        console.log("Updated Done!")
                    }
                } catch (err) {
                    console.log("UPDATED_ERR:", err)
                }
            }

            setCarID("")
            setCarNumber("")
            setCarMax("1")
            setCarCondition(false)
            setCarRemarks("")
            props.onClose();
        } else {
            setIsNeedCarNo(true);
        }


        if (carNumber && !carID) {  // create
            console.log("Created Car", carNumber)

            try {
                await DataStore.save(
                    new Cars({
                        carNumber: carNumber,
                        maxPerson: carMax,
                        Condition: true,
                        Remarks: carRemarks
                    })
                )
                console.log("Create Done!")
            } catch (err) {
                console.log("CREATE_ERR:", err)
            }

            setCarID("")
            setCarNumber("")
            setCarMax("1")
            setCarCondition(false)
            setCarRemarks("")
            props.onClose();
        } else {
            setIsNeedCarNo(true);
        }
    }

    const handleCancel = (event?: React.MouseEvent<HTMLButtonElement>) => {
        setCarID("")
        setCarNumber("")
        setCarMax("1")
        setCarCondition(false)
        setCarRemarks("")
        props.onClose();
    }

    const handleChangeCarNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsNeedCarNo(false)
        setCarNumber(event.target.value)
    }


    const handleChangeMax = (event: React.SyntheticEvent, value: string | null) => {
        if (value) {
            setCarMax(value.toString())
        }
        console.log(value)
    }


    const handleChangeRemarks = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCarRemarks(event.target.value)
    }

    return (
        <Stack spacing={2} component={Paper} p={2} sx={{width: '50%'}}>
            <TextField
                inputProps={{style: {textTransform: 'uppercase'}}}
                disabled={isDisableNum}
                id="filled-basic"
                label="Golf Cart No"
                variant="standard"
                size="small"
                value={carNumber}
                InputLabelProps={{shrink: true}}
                onChange={handleChangeCarNo}
                error={isNeedCarNo}
                autoComplete="off"
                helperText={isNeedCarNo ? "Need Car No." : ""}
                required
            />

            <Autocomplete
                value={carMax.toString()}
                id="size-small-filled"
                size="small"
                onChange={handleChangeMax}
                options={carMaxOptions}
                getOptionLabel={(option) => option}
                defaultValue={carMaxOptions[13]}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option}
                            size="small"
                            {...getTagProps({index})}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        size="small"
                        label="Max Person"
                        placeholder="Max Person"
                        InputLabelProps={{shrink: true}}
                        required
                    />
                )}
            />

            <TextField
                id="standard-basic"
                label="Remarks"
                variant="standard"
                size="small"
                InputLabelProps={{shrink: true}}
                value={carRemarks}
                onChange={handleChangeRemarks}
            />

            <Button variant="contained" disabled={isNeedCarNo} onClick={handleSave}>Save</Button>
            <Button onClick={handleCancel}>Cancel</Button>
        </Stack>
    );
}


const bedTypeOptions = [
    'Enable', 'Disable'
]

const carMaxOptions = [
    "1", "2", "3", "4", "5"
]
  
