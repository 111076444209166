import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import {FormControl, Input, InputAdornment, InputLabel} from '@mui/material';
import {pluralize} from '../../constant';

interface Props {
    total: number
    roomData: any
    eachDay: number
    during: number
    requestIsPaied: () => void
    isPaied: boolean
    depositAmount: number
    setDepositAmount: (value: number) => void
}

export default function StepThree(props: Props) {
    const duration = pluralize(" Day", " Days", props.during);
    const totalDuration = props.during + duration;

    const handleChangeDepo = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value
        if (!isNaN(+value)) props.setDepositAmount(+value)
    }

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Reservation summary
            </Typography>
            <List disablePadding>
                <ListItem sx={{py: 1, px: 0}}>
                    <ListItemText primary={props.roomData?.roomNumber} secondary={""}/>
                </ListItem>

                <ListItem sx={{py: 1, px: 0}}>
                    <ListItemText primary={"Total Duration"} secondary={""}/>
                    <Typography variant="body2">{totalDuration}</Typography>
                </ListItem>

                <ListItem sx={{py: 1, px: 0}}>
                    <ListItemText primary="Total Amount"/>
                    <Typography variant="subtitle1" sx={{fontWeight: 700}}>
                        {props.total}
                    </Typography>
                </ListItem>
            </List>

            <Grid container spacing={2}>
                <Grid item container direction="column" xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom sx={{mt: 2}}>
                        Payment Status
                    </Typography>
                    {/* <Grid container>
            {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid> */}
                </Grid>
            </Grid>

            <FormControl fullWidth sx={{m: 1}} variant="standard">
                <InputLabel htmlFor="deposit-amount">Deposit Amount</InputLabel>
                <Input
                    id="deposit-amount"
                    value={props.depositAmount}
                    onChange={handleChangeDepo}
                    startAdornment={<InputAdornment position="start">฿</InputAdornment>}
                />
            </FormControl>
        </React.Fragment>
    );
}
